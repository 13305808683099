import { JobStatus } from '@monorepo/graphql';
import { Option } from '../components/atoms/Dropdown';
import { AllEnum } from './calendarContext';
import { jobsUtility } from './jobs';

const customerStatusOptions: Option<JobStatus | AllEnum>[] = [
  {
    value: AllEnum.all,
    name: 'All Customer Statuses',
  },
  ...Object.keys(jobsUtility.jobStatusNiceMap).map((k) => ({
    name: jobsUtility.jobStatusNiceMap[k as JobStatus],
    value: k as JobStatus,
  })),
];

const defaultFilters = {
  customerStatus: [customerStatusOptions[0]]
};

export const customerUtiltiy = {
  defaultFilters,
  customerStatusOptions
};
