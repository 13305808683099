import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Scheme as SchemeModel, User as UserModel, Partner as PartnerModel, Skill as SkillModel, UserProfile as UserProfileModel, ContractorProfile as ContractorProfileModel, CustomerProfile as CustomerProfileModel, ContractorProfileHoliday as ContractorProfileHolidayModel, AuditEntry as AuditEntryModel, Job as JobModel, JobAudit as JobAuditModel, JobProduct as JobProductModel, Task as TaskModel, Ticket as TicketModel, Address as AddressModel, Notification as NotificationModel, Slot as SlotModel, File as FileModel, Survey as SurveyModel, Comment as CommentModel, Roof as RoofModel, Invoice as InvoiceModel, Sla as SlaModel, Organisation as OrganisationModel, Setting as SettingModel } from '@prisma/client';
import { InvocationContext } from 'apps/api/src/functions/graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: Date; output: string; }
};

export type Address = {
  __typename?: 'Address';
  city: Maybe<Scalars['String']['output']>;
  county: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  line1: Scalars['String']['output'];
  line2: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  postcode: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type AssignedJob = {
  __typename?: 'AssignedJob';
  address: Maybe<JobAddress>;
  contractors: Maybe<Array<AssignedJobContractor>>;
  customer: JobCustomer;
  dateRequired: Maybe<Scalars['String']['output']>;
  displayDate: Scalars['String']['output'];
  extras: Maybe<Array<JobProduct>>;
  handovers: Maybe<Array<AssignedJobHandover>>;
  hasBirdproofing: Scalars['Boolean']['output'];
  hasEmergencyPs: Scalars['Boolean']['output'];
  hasPowerDiverter: Scalars['Boolean']['output'];
  hasRemedial: Maybe<Scalars['Boolean']['output']>;
  panelQuantity: Scalars['Int']['output'];
  partner: JobPartner;
  status: JobStatus;
  survey: JobSurvey;
  type: JobType;
  uuid: Scalars['String']['output'];
};

export type AssignedJobContractor = {
  __typename?: 'AssignedJobContractor';
  avatarSrc: Maybe<Scalars['String']['output']>;
  dateRequired: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isElectrician: Scalars['Boolean']['output'];
  isRoofer: Scalars['Boolean']['output'];
  isScaffolder: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
};

export type AssignedJobHandover = {
  __typename?: 'AssignedJobHandover';
  jobUuid: Scalars['String']['output'];
  submittedAt: Maybe<Scalars['Date']['output']>;
  type: JobHandoverType;
};

export type AssignedJobsPage = {
  __typename?: 'AssignedJobsPage';
  items: Array<AssignedJob>;
  pagination: PaginationResponse;
};

export type AttachJobContractorsInput = {
  contractors: Array<JobContractorInput>;
  jobUuid: Scalars['String']['input'];
};

export type AuditEntry = {
  __typename?: 'AuditEntry';
  auditEntryUser: Maybe<AuditEntryUser>;
  createdAt: Scalars['Date']['output'];
  notes: Maybe<Scalars['String']['output']>;
  targetType: TargetType;
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type AuditEntryPage = {
  __typename?: 'AuditEntryPage';
  items: Array<AuditEntry>;
  pagination: PaginationResponse;
};

export enum AuditEntryType {
  auditFailed = 'auditFailed',
  auditPassed = 'auditPassed',
  auditResubmitted = 'auditResubmitted',
  contractSigned = 'contractSigned',
  emailSent = 'emailSent',
  fileDeleted = 'fileDeleted',
  handoverSubmitted = 'handoverSubmitted',
  handoversCompleted = 'handoversCompleted',
  jobCreated = 'jobCreated',
  slotUpdated = 'slotUpdated',
  surveyUpdated = 'surveyUpdated',
  userCreated = 'userCreated',
  userCustomerProfileCall = 'userCustomerProfileCall',
  userLogin = 'userLogin',
  userUpdated = 'userUpdated'
}

export type AuditEntryUser = {
  __typename?: 'AuditEntryUser';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userType: UserProfile;
};

export type AuditJobSkill = {
  levelRequired: SkillLevel;
  trade: ContractorTrade;
  uuid: Scalars['String']['input'];
};

export enum AuditType {
  postElectrical = 'postElectrical',
  postGeneral = 'postGeneral',
  postRoofing = 'postRoofing',
  pre = 'pre'
}

export type AuditorInput = {
  postElectrical: Scalars['String']['input'];
  postGeneral: Scalars['String']['input'];
  postRoofing: Scalars['String']['input'];
  pre: Scalars['String']['input'];
};

export type AvailableDate = {
  __typename?: 'AvailableDate';
  displayDate: Scalars['String']['output'];
  slotDate: Scalars['Date']['output'];
};

export enum CacheControlScope {
  private = 'PRIVATE',
  public = 'PUBLIC'
}

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  createdAt: Scalars['Date']['output'];
  files: Maybe<Array<CommentFile>>;
  job: Maybe<CommentJob>;
  targetType: CommentTargetType;
  targetUuid: Scalars['String']['output'];
  text: Scalars['String']['output'];
  user: CommentUser;
  uuid: Scalars['String']['output'];
};

export type CommentFile = {
  __typename?: 'CommentFile';
  name: Scalars['String']['output'];
  src: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CommentJob = {
  __typename?: 'CommentJob';
  displayLink: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CommentPage = {
  __typename?: 'CommentPage';
  items: Array<Comment>;
  pagination: PaginationResponse;
};

export enum CommentTargetType {
  contractorProfile = 'contractorProfile',
  customerProfile = 'customerProfile',
  job = 'job',
  survey = 'survey',
  task = 'task'
}

export type CommentUser = {
  __typename?: 'CommentUser';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CommitFilesInput = {
  auditTargetType?: InputMaybe<TargetType>;
  auditTargetUuid?: InputMaybe<Scalars['String']['input']>;
  uuids: Array<Scalars['String']['input']>;
};

export type CompleteResetPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export enum ContractorCompletionRate {
  aboveEighty = 'aboveEighty',
  belowTwenty = 'belowTwenty',
  twentyToEighty = 'twentyToEighty'
}

export type ContractorHoliday = {
  __typename?: 'ContractorHoliday';
  endDate: Scalars['Date']['output'];
  startDate: Scalars['Date']['output'];
  status: ContractorHolidayStatus;
  uuid: Scalars['String']['output'];
};

export enum ContractorHolidayStatus {
  approved = 'approved',
  declined = 'declined',
  pending = 'pending'
}

export type ContractorHolidaysPage = {
  __typename?: 'ContractorHolidaysPage';
  items: Array<ContractorHoliday>;
  pagination: PaginationResponse;
};

export type ContractorNotificationSettings = {
  __typename?: 'ContractorNotificationSettings';
  inAppNotifications: Scalars['Boolean']['output'];
  newJobAlerts: Scalars['Boolean']['output'];
  pushNotifications: Scalars['Boolean']['output'];
  updatedHolidayAlerts: Scalars['Boolean']['output'];
  updatedJobAlerts: Scalars['Boolean']['output'];
};

export type ContractorProduct = {
  __typename?: 'ContractorProduct';
  customerAvatarSrc: Maybe<Scalars['String']['output']>;
  customerFirstName: Scalars['String']['output'];
  customerLastName: Scalars['String']['output'];
  customerUserUuid: Scalars['String']['output'];
  image: Maybe<ContractorProductImage>;
  installationDate: Scalars['Date']['output'];
  jobUuid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  totalCost: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
};

export type ContractorProductImage = {
  __typename?: 'ContractorProductImage';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

export type ContractorProductPage = {
  __typename?: 'ContractorProductPage';
  items: Array<ContractorProduct>;
  meta: ContractorProductPageMeta;
  pagination: PaginationResponse;
};

export type ContractorProductPageMeta = {
  __typename?: 'ContractorProductPageMeta';
  allTimeTotal: Scalars['Float']['output'];
};

export type ContractorSkill = {
  __typename?: 'ContractorSkill';
  levelRequired: SkillLevel;
  name: Scalars['String']['output'];
  trade: ContractorTrade;
  uuid: Scalars['String']['output'];
};

export enum ContractorTrade {
  electrician = 'electrician',
  roofer = 'roofer'
}

export type CreateAvatarFileInput = {
  key: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type CreateFileInput = {
  customerProfileUuid?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  targetType: FileTargetType;
  targetUuid: Scalars['String']['input'];
};

export type CreateJobInput = {
  customerProfileUserUuid: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  isElectricianRequired: Scalars['Boolean']['input'];
  isLabourerRequired: Scalars['Boolean']['input'];
  isManualAllocation: Scalars['Boolean']['input'];
  isRooferRequired: Scalars['Boolean']['input'];
  numberOfRequiredDays: Scalars['Int']['input'];
  partnerUuid: Scalars['String']['input'];
  searchDate: Scalars['Date']['input'];
  surveyUuid?: InputMaybe<Scalars['String']['input']>;
  type: JobType;
};

export type CreatePartnerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slaUuid: Scalars['String']['input'];
  telephone: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRemedialJobInput = {
  description: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  isElectricianRequired: Scalars['Boolean']['input'];
  isRooferRequired: Scalars['Boolean']['input'];
  itemsLeft: Scalars['String']['input'];
  itemsRequired: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  specialRequests: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type CreateRoofInput = {
  arrays: Array<RoofArrayInput>;
  scaffolding?: InputMaybe<CreateRoofScaffoldingInput>;
  surveyUuid: Scalars['String']['input'];
  tile: RoofTile;
};

export type CreateRoofScaffoldingInput = {
  height: Scalars['Int']['input'];
  isBridgeRequired: Scalars['Boolean']['input'];
  isTowerRequired: Scalars['Boolean']['input'];
  location: ScaffoldingLocation;
  locationArea: ScaffoldingLocationArea;
  locationOtherDescription?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Int']['input'];
};

export type CreateSlotInput = {
  date: Scalars['Date']['input'];
  isBatterySelected: Scalars['Boolean']['input'];
  isInstallationSelected: Scalars['Boolean']['input'];
  isRemedialSelected: Scalars['Boolean']['input'];
  partnerUuid: Scalars['String']['input'];
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserAddressInput = {
  line1: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type CreateUserContractorProfileInput = {
  addressLineOne: Scalars['String']['input'];
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  availability: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isElectrician: Scalars['Boolean']['input'];
  isRoofer: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  organisationUuid: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  radius: Scalars['Int']['input'];
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
};

export type CreateUserCustomerProfileInput = {
  addressLineOne: Scalars['String']['input'];
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  partnerUuid: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  schemeUuid: Scalars['String']['input'];
};

export type CreateUserInput = {
  address: CreateUserAddressInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserUserProfileInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organisationUuid: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  role: UserRole;
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserProfileStatus>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteFileInput = {
  auditTargetType?: InputMaybe<TargetType>;
  auditTargetUuid?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  downloadSrc: Scalars['String']['output'];
  key: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: Maybe<FileOwner>;
  size: Scalars['Int']['output'];
  src: Scalars['String']['output'];
  targetType: Maybe<FileTargetType>;
  targetUuid: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type FileOwner = {
  __typename?: 'FileOwner';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type FilePage = {
  __typename?: 'FilePage';
  items: Array<File>;
  pagination: Maybe<PaginationResponse>;
};

export enum FileTargetType {
  comment = 'comment',
  contract = 'contract',
  customer = 'customer',
  handover = 'handover',
  job = 'job',
  jobProduct = 'jobProduct',
  survey = 'survey',
  task = 'task',
  ticket = 'ticket'
}

export type IndexAssignedJobsFilterInput = {
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type IndexAuditEntriesInput = {
  entryType?: InputMaybe<AuditEntryType>;
  targetType?: InputMaybe<TargetType>;
  targetUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type IndexCommentsFilter = {
  targetType: CommentTargetType;
  targetUuid: Scalars['String']['input'];
};

export type IndexContractorHolidaysFilterInput = {
  past?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IndexFilesFilter = {
  customerProfileUuid?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  targetType?: InputMaybe<FileTargetType>;
  targetUuid?: InputMaybe<Scalars['String']['input']>;
};

export type IndexJobsFilterInput = {
  contractorProfileUuid?: InputMaybe<Scalars['String']['input']>;
  customerProfileUuid?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<JobDifficulty>;
  operatorUuid?: InputMaybe<Scalars['String']['input']>;
  partnerUuid?: InputMaybe<Scalars['String']['input']>;
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexNotificationsFilterInput = {
  isDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  isViewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IndexOrganisationsFilterInput = {
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexPartnersFilterInput = {
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexPotentialContractorsForJobFilter = {
  isElectrician: Scalars['Boolean']['input'];
  isRoofer: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexSchemesFilterInput = {
  partnerUuid?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexSettingsFilter = {
  kind: Array<SettingKind>;
};

export type IndexSkillsFilterInput = {
  term?: InputMaybe<Scalars['String']['input']>;
};

export type IndexSkillsForJobsFilterInput = {
  jobUuid: Scalars['String']['input'];
};

export type IndexSlotsFilter = {
  contractors?: InputMaybe<Array<Scalars['String']['input']>>;
  date: Scalars['Date']['input'];
  difficulty?: InputMaybe<Array<JobDifficulty>>;
  jobType?: InputMaybe<Array<JobType>>;
  partnerUuid: Scalars['String']['input'];
  schemes?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<JobStatus>>;
};

export type IndexSlotsInput = {
  filter: IndexSlotsFilter;
  pagination?: InputMaybe<PaginationInput>;
};

export type IndexTasksFilterInput = {
  assignedOperators?: InputMaybe<Array<Scalars['String']['input']>>;
  creatorUuid?: InputMaybe<Scalars['String']['input']>;
  customerProfileUuid?: InputMaybe<Scalars['String']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<TaskStatus>>;
};

export type IndexTicketsFilterInput = {
  creatorUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TicketStatus>;
};

export type IndexUsersFilterInput = {
  organisationUuid?: InputMaybe<Scalars['String']['input']>;
  partnerUuid?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  userContractorProfileCompletionRateGte?: InputMaybe<Scalars['Int']['input']>;
  userContractorProfileCompletionRateLte?: InputMaybe<Scalars['Int']['input']>;
  userContractorProfileIsElectrician?: InputMaybe<Scalars['Boolean']['input']>;
  userContractorProfileIsRoofer?: InputMaybe<Scalars['Boolean']['input']>;
  userCustomerJobStatus?: InputMaybe<JobStatus>;
  userProfile?: InputMaybe<UserProfile>;
  userUserProfileRole?: InputMaybe<UserRole>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['Date']['output'];
  dueDate: Scalars['Date']['output'];
  notes: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  uuid: Scalars['String']['output'];
  xeroLink: Scalars['String']['output'];
  xeroReference: Scalars['String']['output'];
};

export type InvoiceCreateInput = {
  amount: Scalars['Float']['input'];
  customerProfileUuid: Scalars['String']['input'];
  dueDate: Scalars['Date']['input'];
  jobUuid: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  status: InvoiceStatus;
  xeroLink: Scalars['String']['input'];
  xeroReference: Scalars['String']['input'];
};

export enum InvoiceStatus {
  created = 'created',
  overdue = 'overdue',
  paid = 'paid',
  sent = 'sent',
  voided = 'voided'
}

export type InvoiceUpdateInput = {
  amount: Scalars['Float']['input'];
  dueDate: Scalars['Date']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status: InvoiceStatus;
  uuid: Scalars['String']['input'];
  xeroLink: Scalars['String']['input'];
  xeroReference: Scalars['String']['input'];
};

export type InvoicesPage = {
  __typename?: 'InvoicesPage';
  items: Array<Invoice>;
  pagination: PaginationResponse;
};

export type Job = {
  __typename?: 'Job';
  address: Maybe<JobAddress>;
  contractors: Maybe<Array<AssignedJobContractor>>;
  customer: JobCustomer;
  dates: Array<Scalars['Date']['output']>;
  difficulty: JobDifficulty;
  displayDate: Scalars['String']['output'];
  displayLink: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  extras: Maybe<Array<JobProduct>>;
  operatorUuid: Maybe<Scalars['String']['output']>;
  partner: JobPartner;
  revisedSolarPanelCount: Maybe<Scalars['Int']['output']>;
  schemeName: Scalars['String']['output'];
  solarPanelCount: Scalars['Int']['output'];
  startDate: Maybe<Scalars['Date']['output']>;
  status: JobStatus;
  survey: JobSurvey;
  type: JobType;
  uuid: Scalars['String']['output'];
};

export type JobAddress = {
  __typename?: 'JobAddress';
  city: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  line1: Scalars['String']['output'];
  line2: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  postcode: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type JobAudit = {
  __typename?: 'JobAudit';
  auditType: AuditType;
  auditor: Maybe<JobAuditor>;
  hasPassed: Scalars['Boolean']['output'];
  notes: Maybe<Scalars['String']['output']>;
  submittedAt: Maybe<Scalars['Date']['output']>;
  uuid: Scalars['String']['output'];
};

export type JobAuditor = {
  __typename?: 'JobAuditor';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type JobContractor = {
  __typename?: 'JobContractor';
  canBeElectrician: Scalars['Boolean']['output'];
  canBeRoofer: Scalars['Boolean']['output'];
  contractorProfileUuid: Scalars['String']['output'];
  datesRequired: Array<Scalars['Date']['output']>;
  displayDateRequired: Scalars['String']['output'];
  distance: Scalars['Float']['output'];
  isElectrician: Scalars['Boolean']['output'];
  isRoofer: Scalars['Boolean']['output'];
  jobUuid: Scalars['String']['output'];
  user: JobContractorUser;
  uuid: Scalars['String']['output'];
};

export type JobContractorInput = {
  canBeElectrician: Scalars['Boolean']['input'];
  canBeRoofer: Scalars['Boolean']['input'];
  contractorProfileUuid: Scalars['String']['input'];
  datesRequired: Array<Scalars['Date']['input']>;
  isElectrician: Scalars['Boolean']['input'];
  isPrimary: Scalars['Boolean']['input'];
  isRoofer: Scalars['Boolean']['input'];
};

export type JobContractorPotential = {
  __typename?: 'JobContractorPotential';
  avatarSrc: Maybe<Scalars['String']['output']>;
  canBeElectrician: Scalars['Boolean']['output'];
  canBeRoofer: Scalars['Boolean']['output'];
  contractorProfileUuid: Scalars['String']['output'];
  distance: Scalars['Float']['output'];
  firstName: Scalars['String']['output'];
  isElectrician: Scalars['Boolean']['output'];
  isRoofer: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  userUuid: Scalars['String']['output'];
};

export type JobContractorUser = {
  __typename?: 'JobContractorUser';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isElectrician: Scalars['Boolean']['output'];
  isRoofer: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type JobCustomer = {
  __typename?: 'JobCustomer';
  avatarSrc: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export enum JobDifficulty {
  difficult = 'difficult',
  easy = 'easy',
  normal = 'normal'
}

export enum JobFormFileType {
  electricalHandover = 'electricalHandover',
  remedial = 'remedial',
  roofingHandover = 'roofingHandover'
}

export type JobHandover = {
  __typename?: 'JobHandover';
  displayDate: Scalars['String']['output'];
  src: Scalars['String']['output'];
  type: JobHandoverType;
  uuid: Scalars['String']['output'];
};

export enum JobHandoverType {
  electrical = 'electrical',
  roofing = 'roofing'
}

export type JobPartner = {
  __typename?: 'JobPartner';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type JobProduct = {
  __typename?: 'JobProduct';
  contractorProfileUuid: Maybe<Scalars['String']['output']>;
  image: Maybe<JobProductFile>;
  isBillable: Scalars['Boolean']['output'];
  isInstalled: Scalars['Boolean']['output'];
  price: Scalars['String']['output'];
  product: JobProductProduct;
  productUuid: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export type JobProductFile = {
  __typename?: 'JobProductFile';
  key: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  src: Scalars['String']['output'];
  uuid: Maybe<Scalars['String']['output']>;
};

export type JobProductInput = {
  imageUuid?: InputMaybe<Scalars['String']['input']>;
  isInstalled: Scalars['Boolean']['input'];
  price: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type JobProductProduct = {
  __typename?: 'JobProductProduct';
  name: Scalars['String']['output'];
  responsibleContractorType: Maybe<ResponsibleContractorType>;
};

export enum JobProductType {
  electrical = 'electrical',
  roofing = 'roofing',
  survey = 'survey'
}

export type JobSkill = {
  __typename?: 'JobSkill';
  levelRequired: SkillLevel;
  name: Scalars['String']['output'];
  trade: ContractorTrade;
  uuid: Scalars['String']['output'];
};

export enum JobStatus {
  cancelled = 'cancelled',
  completed = 'completed',
  dnoG98 = 'dnoG98',
  dnoG99 = 'dnoG99',
  feedback = 'feedback',
  incomplete = 'incomplete',
  notSigned = 'notSigned',
  postAuditElectricalAndRoofing = 'postAuditElectricalAndRoofing',
  postAuditGeneral = 'postAuditGeneral',
  preAudit = 'preAudit',
  ready = 'ready',
  rescheduled = 'rescheduled',
  scaffoldingUp = 'scaffoldingUp'
}

export type JobSurvey = {
  __typename?: 'JobSurvey';
  battery: Scalars['String']['output'];
  files: Maybe<Array<JobSurveyFile>>;
  inverter: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type JobSurveyFile = {
  __typename?: 'JobSurveyFile';
  key: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  src: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  uuid: Scalars['String']['output'];
};

export enum JobType {
  battery = 'battery',
  installation = 'installation',
  remedial = 'remedial'
}

export type JobsPage = {
  __typename?: 'JobsPage';
  items: Array<Job>;
  pagination: PaginationResponse;
};

export type Mutation = {
  __typename?: 'Mutation';
  attachJobContractors: Scalars['Boolean']['output'];
  changePassword: Scalars['Boolean']['output'];
  commitFiles: Scalars['Boolean']['output'];
  createAvatarFile: File;
  createFile: File;
  createInvoice: Invoice;
  createJob: Job;
  createJobFormFile: File;
  createPartner: Partner;
  createRemedialJob: Scalars['Boolean']['output'];
  createRoof: Roof;
  createSlot: Slot;
  createUser: User;
  createUserContractorProfile: UserContractorProfile;
  createUserCustomerProfile: UserCustomerProfile;
  createUserUserProfile: UserUserProfile;
  deleteComment: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  deleteOrganisation: Scalars['Boolean']['output'];
  deleteRoof: Scalars['Boolean']['output'];
  deleteSLA: Scalars['Boolean']['output'];
  deleteSkill: Scalars['Boolean']['output'];
  deleteSlot: Scalars['Boolean']['output'];
  dismissAllNotifications: Scalars['Boolean']['output'];
  dismissNotification: Notification;
  getSignedFileUrl: Scalars['String']['output'];
  putSignedAvatarPhotoUrl: PutSignedFileUrl;
  putSignedFileUrl: PutSignedFileUrl;
  putSignedJobFormFileUrl: PutSignedFileUrl;
  rebookJob: Job;
  saveFeedback: Scalars['Boolean']['output'];
  saveJobHandover: AssignedJobHandover;
  updateAddress: Address;
  updateInvoice: Invoice;
  updateJob: Job;
  updateJobAudit: JobAudit;
  updateNotificationSettings: ContractorNotificationSettings;
  updatePartner: Partner;
  updateRoof: Roof;
  updateScheme: Scheme;
  updateSelf: User;
  updateSetting: Setting;
  updateSlot: Slot;
  updateSurvey: Survey;
  updateUserContractorProfile: UserContractorProfile;
  updateUserCustomerProfile: UserCustomerProfile;
  updateUserUserProfile: UserUserProfile;
  upsertAuditEntry: AuditEntry;
  upsertComment: Comment;
  upsertOrganisation: Maybe<Organisation>;
  upsertSLA: Sla;
  upsertScheme: Scheme;
  upsertSkill: Skill;
  upsertTask: Task;
  viewNotification: Notification;
};


export type MutationAttachJobContractorsArgs = {
  input: AttachJobContractorsInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCommitFilesArgs = {
  input: CommitFilesInput;
};


export type MutationCreateAvatarFileArgs = {
  input: CreateAvatarFileInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceCreateInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobFormFileArgs = {
  input: CreateFileInput;
};


export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};


export type MutationCreateRemedialJobArgs = {
  input: CreateRemedialJobInput;
};


export type MutationCreateRoofArgs = {
  input: CreateRoofInput;
};


export type MutationCreateSlotArgs = {
  input: CreateSlotInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserContractorProfileArgs = {
  input: CreateUserContractorProfileInput;
};


export type MutationCreateUserCustomerProfileArgs = {
  input: CreateUserCustomerProfileInput;
};


export type MutationCreateUserUserProfileArgs = {
  input: CreateUserUserProfileInput;
};


export type MutationDeleteCommentArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteOrganisationArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteRoofArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteSlaArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteSkillArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteSlotArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDismissNotificationArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationGetSignedFileUrlArgs = {
  input: SignedUrlInput;
};


export type MutationPutSignedAvatarPhotoUrlArgs = {
  input: UploadAvatarUrlInput;
};


export type MutationPutSignedFileUrlArgs = {
  input: UploadUrlInput;
};


export type MutationPutSignedJobFormFileUrlArgs = {
  input: UploadJobFormFileInput;
};


export type MutationRebookJobArgs = {
  input: RebookJobInput;
};


export type MutationSaveFeedbackArgs = {
  input: SaveJobFeedback;
};


export type MutationSaveJobHandoverArgs = {
  input: SaveJobHandoverInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateInvoiceArgs = {
  input: InvoiceUpdateInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateJobAuditArgs = {
  input: UpdateJobAuditInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};


export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};


export type MutationUpdateRoofArgs = {
  input: UpdateRoofInput;
};


export type MutationUpdateSchemeArgs = {
  input: UpdateSchemeInput;
};


export type MutationUpdateSelfArgs = {
  input: UpdateSelfInput;
};


export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};


export type MutationUpdateSlotArgs = {
  input: UpdateSlotInput;
};


export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};


export type MutationUpdateUserContractorProfileArgs = {
  input: UpdateUserContractorProfileInput;
};


export type MutationUpdateUserCustomerProfileArgs = {
  input: UpdateUserCustomerProfileInput;
};


export type MutationUpdateUserUserProfileArgs = {
  input: UpdateUserUserProfileInput;
};


export type MutationUpsertAuditEntryArgs = {
  input: UpsertAuditEntryInput;
};


export type MutationUpsertCommentArgs = {
  input: UpsertCommentInput;
};


export type MutationUpsertOrganisationArgs = {
  input: UpsertOrganisationInput;
};


export type MutationUpsertSlaArgs = {
  input: UpsertSlaInput;
};


export type MutationUpsertSchemeArgs = {
  input: UpsertSchemeInput;
};


export type MutationUpsertSkillArgs = {
  input: UpsertSkillInput;
};


export type MutationUpsertTaskArgs = {
  input: TaskUpsertInput;
};


export type MutationViewNotificationArgs = {
  uuid: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  actionUrl: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  description: Maybe<Scalars['String']['output']>;
  isDismissed: Scalars['Boolean']['output'];
  isViewed: Scalars['Boolean']['output'];
  targetType: NotificationTargetType;
  targetUserUuid: Scalars['String']['output'];
  targetUuid: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export enum NotificationTargetType {
  holiday = 'holiday',
  job = 'job'
}

export type NotificationsPage = {
  __typename?: 'NotificationsPage';
  items: Array<Notification>;
  pagination: PaginationResponse;
};

export type Organisation = {
  __typename?: 'Organisation';
  address: OrganisationAddress;
  canDelete: Scalars['Boolean']['output'];
  displayAddress: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  telephone: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  website: Maybe<Scalars['String']['output']>;
};

export type OrganisationAddress = {
  __typename?: 'OrganisationAddress';
  city: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  line1: Scalars['String']['output'];
  line2: Maybe<Scalars['String']['output']>;
  longitude: Scalars['Float']['output'];
  postcode: Scalars['String']['output'];
};

export type OrganisationPage = {
  __typename?: 'OrganisationPage';
  items: Array<Organisation>;
  pagination: PaginationResponse;
};

export type PaginationInput = {
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};

export type PaginationResponse = {
  __typename?: 'PaginationResponse';
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  customerCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  jobCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  schemeCount: Scalars['Int']['output'];
  sla: PartnerSla;
  slotAllocationAmount: Scalars['Int']['output'];
  slotAllocationType: SlotAllocationType;
  telephone: Maybe<Scalars['String']['output']>;
  ticketCount: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  website: Maybe<Scalars['String']['output']>;
};

export type PartnerSkill = {
  __typename?: 'PartnerSkill';
  levelRequired: SkillLevel;
  name: Scalars['String']['output'];
  trade: ContractorTrade;
  uuid: Scalars['String']['output'];
};

export type PartnerSkillInput = {
  levelRequired: SkillLevel;
  skillUuid: Scalars['String']['input'];
  trade: ContractorTrade;
};

export type PartnerSkillPage = {
  __typename?: 'PartnerSkillPage';
  items: Array<PartnerSkill>;
  pagination: PaginationResponse;
};

export type PartnerSla = {
  __typename?: 'PartnerSla';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type PartnersPage = {
  __typename?: 'PartnersPage';
  items: Array<Partner>;
  pagination: PaginationResponse;
};

export type Profile = UserContractorProfile | UserCustomerProfile | UserUserProfile;

export type PutSignedFileUrl = {
  __typename?: 'PutSignedFileUrl';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  allTaskCount: Scalars['Int']['output'];
  allTasks: TasksPage;
  auditPendingJobCount: Scalars['Int']['output'];
  auditPendingJobs: JobsPage;
  blockedJobCount: Scalars['Int']['output'];
  blockedJobs: JobsPage;
  completeResetPassword: Scalars['Boolean']['output'];
  estimateSlotAllocations: Array<SlotAllocationMap>;
  findUniqueUser: User;
  indexAssignedJobs: AssignedJobsPage;
  indexAuditEntries: AuditEntryPage;
  indexAuditsForJob: Array<JobAudit>;
  indexComments: CommentPage;
  indexContractorHolidays: Maybe<ContractorHolidaysPage>;
  indexContractorsForJob: Array<JobContractor>;
  indexFiles: FilePage;
  indexHandoversForJob: Array<JobHandover>;
  indexInstalledProductsForContractor: ContractorProductPage;
  indexInvoicesForCustomer: InvoicesPage;
  indexJobs: JobsPage;
  indexNotifications: NotificationsPage;
  indexOrganisations: OrganisationPage;
  indexPartners: PartnersPage;
  indexPotentialContractorsForJob: Array<JobContractorPotential>;
  indexProductsForJob: Array<JobProduct>;
  indexRoofsForJob: Array<Roof>;
  indexSLAs: SlaPage;
  indexSchemes: SchemesPage;
  indexSettings: SettingPage;
  indexSkills: SkillsPage;
  indexSkillsForContractors: Array<ContractorSkill>;
  indexSkillsForJobs: Array<JobSkill>;
  indexSkillsForPartners: PartnerSkillPage;
  indexSlots: SlotPage;
  indexTasks: TasksPage;
  indexTickets: TicketsPage;
  indexUsers: UsersPage;
  logout: Scalars['Boolean']['output'];
  readAssignedJob: AssignedJob;
  readContractorHoliday: ContractorHoliday;
  readJob: Job;
  readNotificationSettings: ContractorNotificationSettings;
  readPartner: Partner;
  readScheme: Scheme;
  readSkill: Skill;
  readTask: Task;
  readUser: User;
  refresh: Token;
  resetPassword: Scalars['Boolean']['output'];
  searchDatesForRebook: Array<AvailableDate>;
  signIn: Token;
  slotCount: Scalars['String']['output'];
  todaysJobCount: Scalars['Int']['output'];
  todaysJobs: JobsPage;
  unassignedJobCount: Scalars['Int']['output'];
  unassignedJobs: JobsPage;
  unassignedTaskCount: Scalars['Int']['output'];
  unassignedTasks: TasksPage;
};


export type QueryAllTaskCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryAllTasksArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryAuditPendingJobCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryAuditPendingJobsArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryBlockedJobCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryBlockedJobsArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryCompleteResetPasswordArgs = {
  input: CompleteResetPasswordInput;
};


export type QueryIndexAssignedJobsArgs = {
  filters: InputMaybe<IndexAssignedJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexAuditEntriesArgs = {
  filters: IndexAuditEntriesInput;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexAuditsForJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryIndexCommentsArgs = {
  filter: IndexCommentsFilter;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexContractorHolidaysArgs = {
  filters: InputMaybe<IndexContractorHolidaysFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexContractorsForJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryIndexFilesArgs = {
  filters: IndexFilesFilter;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexHandoversForJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryIndexInstalledProductsForContractorArgs = {
  contractorProfileUuid: Scalars['String']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexInvoicesForCustomerArgs = {
  customerProfileUuid: Scalars['String']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexJobsArgs = {
  filters: InputMaybe<IndexJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexNotificationsArgs = {
  filters: InputMaybe<IndexNotificationsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexOrganisationsArgs = {
  filters: InputMaybe<IndexOrganisationsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexPartnersArgs = {
  filters: InputMaybe<IndexPartnersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexPotentialContractorsForJobArgs = {
  filters: IndexPotentialContractorsForJobFilter;
};


export type QueryIndexProductsForJobArgs = {
  contractorUuid: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};


export type QueryIndexRoofsForJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryIndexSlAsArgs = {
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexSchemesArgs = {
  filters: InputMaybe<IndexSchemesFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexSettingsArgs = {
  filter: IndexSettingsFilter;
};


export type QueryIndexSkillsArgs = {
  filters: InputMaybe<IndexSkillsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexSkillsForContractorsArgs = {
  contractorProfileUuid: Scalars['String']['input'];
};


export type QueryIndexSkillsForJobsArgs = {
  filters: IndexSkillsForJobsFilterInput;
};


export type QueryIndexSkillsForPartnersArgs = {
  pagination: InputMaybe<PaginationInput>;
  partnerUuid: Scalars['String']['input'];
};


export type QueryIndexSlotsArgs = {
  input: IndexSlotsInput;
};


export type QueryIndexTasksArgs = {
  filters: InputMaybe<IndexTasksFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexTicketsArgs = {
  filters: InputMaybe<IndexTicketsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryIndexUsersArgs = {
  filters: InputMaybe<IndexUsersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
};


export type QueryReadAssignedJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadContractorHolidayArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadJobArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadPartnerArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadSchemeArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadSkillArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadTaskArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryReadUserArgs = {
  input: ReadUserInput;
};


export type QueryRefreshArgs = {
  input: InputMaybe<RefreshTokenInput>;
};


export type QueryResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type QuerySearchDatesForRebookArgs = {
  input: SearchDatesForBooking;
};


export type QuerySignInArgs = {
  input: SignInInput;
};


export type QuerySlotCountArgs = {
  input: SlotCountInput;
};


export type QueryTodaysJobCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryTodaysJobsArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryUnassignedJobCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryUnassignedJobsArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};


export type QueryUnassignedTaskCountArgs = {
  myAssignment: Scalars['Boolean']['input'];
};


export type QueryUnassignedTasksArgs = {
  myAssignment: Scalars['Boolean']['input'];
  pagination: InputMaybe<PaginationInput>;
};

export type ReadUserInput = {
  userProfile?: InputMaybe<UserProfile>;
  uuid: Scalars['String']['input'];
};

export type RebookJobInput = {
  dateToBeginSearch: Scalars['Date']['input'];
  duration: Scalars['Int']['input'];
  isElectricianRequired: Scalars['Boolean']['input'];
  isRooferRequired: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfRequiredDays: Scalars['Int']['input'];
  partnerUuid: Scalars['String']['input'];
  reasonForChangingJob: Scalars['String']['input'];
  type: JobType;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  email: Scalars['String']['input'];
};

export enum ResponsibleContractorType {
  electrician = 'electrician',
  roofer = 'roofer'
}

export type Roof = {
  __typename?: 'Roof';
  arrays: Array<RoofArray>;
  scaffolding: Maybe<RoofScaffolding>;
  tile: RoofTile;
  uuid: Scalars['String']['output'];
};

export type RoofArray = {
  __typename?: 'RoofArray';
  orientation: RoofArrayOrientation;
  panels: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export type RoofArrayInput = {
  orientation: RoofArrayOrientation;
  panels: Scalars['Int']['input'];
};

export enum RoofArrayOrientation {
  landscape = 'landscape',
  portrait = 'portrait'
}

export type RoofScaffolding = {
  __typename?: 'RoofScaffolding';
  height: Scalars['Int']['output'];
  isBridgeRequired: Scalars['Boolean']['output'];
  isTowerRequired: Scalars['Boolean']['output'];
  location: ScaffoldingLocation;
  locationArea: ScaffoldingLocationArea;
  locationOtherDescription: Maybe<Scalars['String']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export enum RoofTile {
  concrete = 'concrete',
  concreteFlat = 'concreteFlat',
  flat = 'flat',
  flatPlain = 'flatPlain',
  metal = 'metal',
  slate = 'slate',
  unspecified = 'unspecified'
}

export type Sla = {
  __typename?: 'SLA';
  name: Scalars['String']['output'];
  partnerCount: Scalars['Int']['output'];
  scaffoldingSetUpThreshold: Scalars['Int']['output'];
  scaffoldingStrikeThreshold: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export type SlaPage = {
  __typename?: 'SLAPage';
  items: Array<Sla>;
  pagination: PaginationResponse;
};

export type SaveJobFeedback = {
  jobFeedbackTokenUuid: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};

export type SaveJobHandoverInput = {
  data: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  type: JobHandoverType;
  uuid: Scalars['String']['input'];
};

export enum ScaffoldingLocation {
  garage = 'garage',
  mainProperty = 'mainProperty',
  other = 'other',
  outbuilding = 'outbuilding'
}

export enum ScaffoldingLocationArea {
  back = 'back',
  front = 'front',
  leftSide = 'leftSide',
  other = 'other',
  rightSide = 'rightSide'
}

export type Scheme = {
  __typename?: 'Scheme';
  electricalAuditorUuid: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  formattedEndDate: Scalars['String']['output'];
  formattedStartDate: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operatorUuid: Maybe<Scalars['String']['output']>;
  partnerName: Scalars['String']['output'];
  partnerUuid: Scalars['String']['output'];
  postAuditorUuid: Maybe<Scalars['String']['output']>;
  preAuditorUuid: Maybe<Scalars['String']['output']>;
  roofingAuditorUuid: Maybe<Scalars['String']['output']>;
  schemeType: SchemeType;
  startDate: Scalars['Date']['output'];
  status: SchemeStatus;
  uuid: Scalars['String']['output'];
};

export enum SchemeStatus {
  current = 'current',
  ended = 'ended',
  upcoming = 'upcoming'
}

export enum SchemeType {
  fullService = 'fullService',
  installation = 'installation'
}

export type SchemesPage = {
  __typename?: 'SchemesPage';
  items: Array<Scheme>;
  pagination: PaginationResponse;
};

export type SearchDatesForBooking = {
  dateToBeginSearch: Scalars['Date']['input'];
  duration: Scalars['Int']['input'];
  isElectricianRequired: Scalars['Boolean']['input'];
  isRooferRequired: Scalars['Boolean']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  numberOfRequiredDays: Scalars['Int']['input'];
  partnerUuid: Scalars['String']['input'];
  type: JobType;
};

export enum ServiceType {
  full = 'full',
  installOnly = 'installOnly'
}

export type Setting = {
  __typename?: 'Setting';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  kind: SettingKind;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum SettingKind {
  notificationBehaviour = 'notificationBehaviour',
  notificationSubject = 'notificationSubject'
}

export type SettingPage = {
  __typename?: 'SettingPage';
  items: Array<Setting>;
};

export type SignInInput = {
  email: Scalars['String']['input'];
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
};

export type SignedUrlInput = {
  key: Scalars['String']['input'];
};

export type Skill = {
  __typename?: 'Skill';
  contractorCount: Scalars['Int']['output'];
  jobCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partnerCount: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export enum SkillLevel {
  fullyQualified = 'fullyQualified',
  inTraining = 'inTraining',
  partQualified = 'partQualified'
}

export type SkillsPage = {
  __typename?: 'SkillsPage';
  items: Array<Skill>;
  pagination: PaginationResponse;
};

export type Slot = {
  __typename?: 'Slot';
  isBatterySelected: Scalars['Boolean']['output'];
  isElectricianAvailable: Scalars['Boolean']['output'];
  isInstallationSelected: Scalars['Boolean']['output'];
  isRemedialSelected: Scalars['Boolean']['output'];
  isRooferAvailable: Scalars['Boolean']['output'];
  job: Maybe<SlotJob>;
  schemeName: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type SlotAllocationMap = {
  __typename?: 'SlotAllocationMap';
  installationAllocated: Scalars['Int']['output'];
  installationRequired: Scalars['Int']['output'];
  partnerUuid: Scalars['String']['output'];
};

export enum SlotAllocationType {
  fixed = 'fixed',
  percentage = 'percentage'
}

export type SlotCountInput = {
  date: Scalars['Date']['input'];
  partnerUuid: Scalars['String']['input'];
};

export type SlotJob = {
  __typename?: 'SlotJob';
  atRisk: Scalars['Boolean']['output'];
  contractors: Array<SlotJobContractor>;
  customer: SlotJobCustomer;
  difficulty: JobDifficulty;
  displayInstallationDate: Scalars['String']['output'];
  status: JobStatus;
  type: JobType;
  uuid: Scalars['String']['output'];
};

export type SlotJobContractor = {
  __typename?: 'SlotJobContractor';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type SlotJobCustomer = {
  __typename?: 'SlotJobCustomer';
  city: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  postcode: Maybe<Scalars['String']['output']>;
  schemeName: Maybe<Scalars['String']['output']>;
};

export type SlotPage = {
  __typename?: 'SlotPage';
  items: Array<Slot>;
  pagination: Maybe<PaginationResponse>;
};

export type Survey = {
  __typename?: 'Survey';
  uuid: Scalars['String']['output'];
};

export enum TargetType {
  job = 'job',
  partner = 'partner',
  task = 'task',
  user = 'user'
}

export type Task = {
  __typename?: 'Task';
  assignee: Maybe<TaskUser>;
  createdAt: Scalars['Date']['output'];
  creator: Maybe<TaskUser>;
  customer: Maybe<TaskUser>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  files: Array<TaskFile>;
  job: Maybe<TaskJob>;
  status: TaskStatus;
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type TaskFile = {
  __typename?: 'TaskFile';
  name: Scalars['String']['output'];
  src: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type TaskJob = {
  __typename?: 'TaskJob';
  displayName: Scalars['String']['output'];
  type: JobType;
  uuid: Scalars['String']['output'];
};

export enum TaskStatus {
  done = 'done',
  inProgress = 'inProgress',
  todo = 'todo',
  unassigned = 'unassigned'
}

export type TaskUpsertInput = {
  customerProfileUuid?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  fileUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  operatorUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type TaskUser = {
  __typename?: 'TaskUser';
  avatarSrc: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  partnerUuid: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type TasksPage = {
  __typename?: 'TasksPage';
  items: Array<Task>;
  pagination: PaginationResponse;
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt: Scalars['Date']['output'];
  customer: TicketCustomer;
  description: Maybe<Scalars['String']['output']>;
  lastInteraction: Maybe<Scalars['Int']['output']>;
  status: TicketStatus;
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type TicketCustomer = {
  __typename?: 'TicketCustomer';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum TicketStatus {
  assigned = 'assigned',
  resolved = 'resolved',
  unableToResolve = 'unableToResolve',
  unassigned = 'unassigned'
}

export type TicketsPage = {
  __typename?: 'TicketsPage';
  items: Array<Ticket>;
  pagination: PaginationResponse;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  refreshToken: Maybe<Scalars['String']['output']>;
};

export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type UpdateJobAuditInput = {
  difficulty?: InputMaybe<JobDifficulty>;
  extras?: InputMaybe<Array<JobProductInput>>;
  hasPassed?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfSolarPanels?: InputMaybe<Scalars['Int']['input']>;
  skills?: InputMaybe<Array<AuditJobSkill>>;
  uuid: Scalars['String']['input'];
};

export type UpdateJobInput = {
  auditorUuids?: InputMaybe<AuditorInput>;
  operatorUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
  uuid: Scalars['String']['input'];
};

export type UpdateNotificationSettingsInput = {
  inAppNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  newJobAlerts?: InputMaybe<Scalars['Boolean']['input']>;
  pushNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  updatedHolidayAlerts?: InputMaybe<Scalars['Boolean']['input']>;
  updatedJobAlerts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePartnerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<PartnerSkillInput>>;
  slaUuid?: InputMaybe<Scalars['String']['input']>;
  slotAllocationAmount?: InputMaybe<Scalars['Int']['input']>;
  slotAllocationType?: InputMaybe<SlotAllocationType>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoofInput = {
  arrays?: InputMaybe<Array<RoofArrayInput>>;
  scaffolding?: InputMaybe<UpdateRoofScaffoldingInput>;
  tile?: InputMaybe<RoofTile>;
  uuid: Scalars['String']['input'];
};

export type UpdateRoofScaffoldingInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  isBridgeRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isTowerRequired?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<ScaffoldingLocation>;
  locationArea?: InputMaybe<ScaffoldingLocationArea>;
  locationOtherDescription?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSchemeInput = {
  electricalAuditorUuid?: InputMaybe<Scalars['String']['input']>;
  operatorUuid?: InputMaybe<Scalars['String']['input']>;
  postAuditorUuid?: InputMaybe<Scalars['String']['input']>;
  preAuditorUuid?: InputMaybe<Scalars['String']['input']>;
  roofingAuditorUuid?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type UpdateSelfInput = {
  avatarUuid?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSettingInput = {
  uuid: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateSlotInput = {
  isBatterySelected?: InputMaybe<Scalars['Boolean']['input']>;
  isInstallationSelected?: InputMaybe<Scalars['Boolean']['input']>;
  isRemedialSelected?: InputMaybe<Scalars['Boolean']['input']>;
  partnerUuid?: InputMaybe<Scalars['String']['input']>;
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type UpdateSurveyInput = {
  fileUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  uuid: Scalars['String']['input'];
};

export type UpdateUserContractorProfileInput = {
  availability?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isElectrician?: InputMaybe<Scalars['Boolean']['input']>;
  isRoofer?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationUuid?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  skills?: InputMaybe<Array<UserContractorSkillInput>>;
  status?: InputMaybe<UserProfileStatus>;
  uuid: Scalars['String']['input'];
};

export type UpdateUserCustomerProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  partnerUuid?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type UpdateUserUserProfileInput = {
  avatarUuid?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationUuid?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  schemeUuid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserProfileStatus>;
  uuid: Scalars['String']['input'];
};

export type UploadAvatarUrlInput = {
  contentDisposition: Scalars['String']['input'];
};

export type UploadJobFormFileInput = {
  contentDisposition: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  type: JobFormFileType;
};

export type UploadUrlInput = {
  contentDisposition: Scalars['String']['input'];
  customerProfileUuid: Scalars['String']['input'];
  targetType: FileTargetType;
  targetUuid: Scalars['String']['input'];
};

export type UpsertAuditEntryInput = {
  entryType: AuditEntryType;
  notes?: InputMaybe<Scalars['String']['input']>;
  targetType: TargetType;
  targetUuid: Scalars['String']['input'];
  title: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCommentInput = {
  fileUuids: Array<Scalars['String']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  targetType: CommentTargetType;
  targetUuid: Scalars['String']['input'];
  text: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type UpsertOrganisationAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postcode: Scalars['String']['input'];
};

export type UpsertOrganisationInput = {
  address: UpsertOrganisationAddressInput;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  telephone: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  website: Scalars['String']['input'];
};

export type UpsertSlaInput = {
  name: Scalars['String']['input'];
  scaffoldingDownDays: Scalars['Int']['input'];
  scaffoldingUpDays: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSchemeInput = {
  electricalAuditorUuid: Scalars['String']['input'];
  endDate: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  operatorUuid: Scalars['String']['input'];
  partnerUuid: Scalars['String']['input'];
  postAuditorUuid: Scalars['String']['input'];
  preAuditorUuid: Scalars['String']['input'];
  roofingAuditorUuid: Scalars['String']['input'];
  schemeType: SchemeType;
  startDate: Scalars['Date']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSkillInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  address: Maybe<Address>;
  avatarSrc: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastLogin: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  profile: Profile;
  schemeName: Maybe<Scalars['String']['output']>;
  unreadNotificationCount: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
};

export type UserContractorProfile = {
  __typename?: 'UserContractorProfile';
  availability: Scalars['String']['output'];
  completionRate: Scalars['Float']['output'];
  holidays: Maybe<Array<ContractorHoliday>>;
  isElectrician: Scalars['Boolean']['output'];
  isRoofer: Scalars['Boolean']['output'];
  isScaffolder: Scalars['Boolean']['output'];
  jobCount: Scalars['Int']['output'];
  organisation: UserOrganisation;
  servicingRadius: Scalars['Int']['output'];
  startDate: Scalars['String']['output'];
  status: UserProfileStatus;
  testInstrumentSerialNumber: Maybe<Scalars['String']['output']>;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type UserContractorSkillInput = {
  level: SkillLevel;
  skillUuid: Scalars['String']['input'];
  trade: ContractorTrade;
};

export type UserCustomerPartner = {
  __typename?: 'UserCustomerPartner';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type UserCustomerProfile = {
  __typename?: 'UserCustomerProfile';
  jobStatus: Maybe<JobStatus>;
  partner: UserCustomerPartner;
  schemeUuid: Scalars['String']['output'];
  source: UserProfileSource;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type UserOrganisation = {
  __typename?: 'UserOrganisation';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export enum UserProfile {
  contractor = 'contractor',
  customer = 'customer',
  user = 'user'
}

export enum UserProfileSource {
  api = 'api',
  csv = 'csv',
  direct = 'direct'
}

export enum UserProfileStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum UserRole {
  administrator = 'administrator',
  operator = 'operator',
  superAdministrator = 'superAdministrator'
}

export type UserUserProfile = {
  __typename?: 'UserUserProfile';
  organisation: UserOrganisation;
  role: UserRole;
  status: UserProfileStatus;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type UsersPage = {
  __typename?: 'UsersPage';
  items: Array<User>;
  pagination: PaginationResponse;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Profile: ( ContractorProfileModel ) | ( CustomerProfileModel ) | ( UserProfileModel );
};


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<AddressModel>;
  AssignedJob: ResolverTypeWrapper<JobModel>;
  AssignedJobContractor: ResolverTypeWrapper<AssignedJobContractor>;
  AssignedJobHandover: ResolverTypeWrapper<AssignedJobHandover>;
  AssignedJobsPage: ResolverTypeWrapper<Omit<AssignedJobsPage, 'items'> & { items: Array<ResolversTypes['AssignedJob']> }>;
  AttachJobContractorsInput: AttachJobContractorsInput;
  AuditEntry: ResolverTypeWrapper<AuditEntryModel>;
  AuditEntryPage: ResolverTypeWrapper<Omit<AuditEntryPage, 'items'> & { items: Array<ResolversTypes['AuditEntry']> }>;
  AuditEntryType: AuditEntryType;
  AuditEntryUser: ResolverTypeWrapper<UserModel>;
  AuditJobSkill: AuditJobSkill;
  AuditType: AuditType;
  AuditorInput: AuditorInput;
  AvailableDate: ResolverTypeWrapper<AvailableDate>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CacheControlScope: CacheControlScope;
  ChangePasswordInput: ChangePasswordInput;
  Comment: ResolverTypeWrapper<CommentModel>;
  CommentFile: ResolverTypeWrapper<CommentFile>;
  CommentJob: ResolverTypeWrapper<CommentJob>;
  CommentPage: ResolverTypeWrapper<Omit<CommentPage, 'items'> & { items: Array<ResolversTypes['Comment']> }>;
  CommentTargetType: CommentTargetType;
  CommentUser: ResolverTypeWrapper<CommentUser>;
  CommitFilesInput: CommitFilesInput;
  CompleteResetPasswordInput: CompleteResetPasswordInput;
  ContractorCompletionRate: ContractorCompletionRate;
  ContractorHoliday: ResolverTypeWrapper<ContractorProfileHolidayModel>;
  ContractorHolidayStatus: ContractorHolidayStatus;
  ContractorHolidaysPage: ResolverTypeWrapper<Omit<ContractorHolidaysPage, 'items'> & { items: Array<ResolversTypes['ContractorHoliday']> }>;
  ContractorNotificationSettings: ResolverTypeWrapper<ContractorNotificationSettings>;
  ContractorProduct: ResolverTypeWrapper<ContractorProduct>;
  ContractorProductImage: ResolverTypeWrapper<ContractorProductImage>;
  ContractorProductPage: ResolverTypeWrapper<ContractorProductPage>;
  ContractorProductPageMeta: ResolverTypeWrapper<ContractorProductPageMeta>;
  ContractorSkill: ResolverTypeWrapper<ContractorSkill>;
  ContractorTrade: ContractorTrade;
  CreateAvatarFileInput: CreateAvatarFileInput;
  CreateFileInput: CreateFileInput;
  CreateJobInput: CreateJobInput;
  CreatePartnerInput: CreatePartnerInput;
  CreateRemedialJobInput: CreateRemedialJobInput;
  CreateRoofInput: CreateRoofInput;
  CreateRoofScaffoldingInput: CreateRoofScaffoldingInput;
  CreateSlotInput: CreateSlotInput;
  CreateUserAddressInput: CreateUserAddressInput;
  CreateUserContractorProfileInput: CreateUserContractorProfileInput;
  CreateUserCustomerProfileInput: CreateUserCustomerProfileInput;
  CreateUserInput: CreateUserInput;
  CreateUserUserProfileInput: CreateUserUserProfileInput;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DeleteFileInput: DeleteFileInput;
  File: ResolverTypeWrapper<FileModel>;
  FileOwner: ResolverTypeWrapper<FileOwner>;
  FilePage: ResolverTypeWrapper<Omit<FilePage, 'items'> & { items: Array<ResolversTypes['File']> }>;
  FileTargetType: FileTargetType;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  IndexAssignedJobsFilterInput: IndexAssignedJobsFilterInput;
  IndexAuditEntriesInput: IndexAuditEntriesInput;
  IndexCommentsFilter: IndexCommentsFilter;
  IndexContractorHolidaysFilterInput: IndexContractorHolidaysFilterInput;
  IndexFilesFilter: IndexFilesFilter;
  IndexJobsFilterInput: IndexJobsFilterInput;
  IndexNotificationsFilterInput: IndexNotificationsFilterInput;
  IndexOrganisationsFilterInput: IndexOrganisationsFilterInput;
  IndexPartnersFilterInput: IndexPartnersFilterInput;
  IndexPotentialContractorsForJobFilter: IndexPotentialContractorsForJobFilter;
  IndexSchemesFilterInput: IndexSchemesFilterInput;
  IndexSettingsFilter: IndexSettingsFilter;
  IndexSkillsFilterInput: IndexSkillsFilterInput;
  IndexSkillsForJobsFilterInput: IndexSkillsForJobsFilterInput;
  IndexSlotsFilter: IndexSlotsFilter;
  IndexSlotsInput: IndexSlotsInput;
  IndexTasksFilterInput: IndexTasksFilterInput;
  IndexTicketsFilterInput: IndexTicketsFilterInput;
  IndexUsersFilterInput: IndexUsersFilterInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Invoice: ResolverTypeWrapper<InvoiceModel>;
  InvoiceCreateInput: InvoiceCreateInput;
  InvoiceStatus: InvoiceStatus;
  InvoiceUpdateInput: InvoiceUpdateInput;
  InvoicesPage: ResolverTypeWrapper<Omit<InvoicesPage, 'items'> & { items: Array<ResolversTypes['Invoice']> }>;
  Job: ResolverTypeWrapper<JobModel>;
  JobAddress: ResolverTypeWrapper<JobAddress>;
  JobAudit: ResolverTypeWrapper<JobAuditModel>;
  JobAuditor: ResolverTypeWrapper<JobAuditor>;
  JobContractor: ResolverTypeWrapper<JobContractor>;
  JobContractorInput: JobContractorInput;
  JobContractorPotential: ResolverTypeWrapper<JobContractorPotential>;
  JobContractorUser: ResolverTypeWrapper<JobContractorUser>;
  JobCustomer: ResolverTypeWrapper<JobCustomer>;
  JobDifficulty: JobDifficulty;
  JobFormFileType: JobFormFileType;
  JobHandover: ResolverTypeWrapper<JobHandover>;
  JobHandoverType: JobHandoverType;
  JobPartner: ResolverTypeWrapper<PartnerModel>;
  JobProduct: ResolverTypeWrapper<JobProductModel>;
  JobProductFile: ResolverTypeWrapper<JobProductFile>;
  JobProductInput: JobProductInput;
  JobProductProduct: ResolverTypeWrapper<JobProductProduct>;
  JobProductType: JobProductType;
  JobSkill: ResolverTypeWrapper<JobSkill>;
  JobStatus: JobStatus;
  JobSurvey: ResolverTypeWrapper<JobSurvey>;
  JobSurveyFile: ResolverTypeWrapper<JobSurveyFile>;
  JobType: JobType;
  JobsPage: ResolverTypeWrapper<Omit<JobsPage, 'items'> & { items: Array<ResolversTypes['Job']> }>;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<NotificationModel>;
  NotificationTargetType: NotificationTargetType;
  NotificationsPage: ResolverTypeWrapper<Omit<NotificationsPage, 'items'> & { items: Array<ResolversTypes['Notification']> }>;
  Organisation: ResolverTypeWrapper<OrganisationModel>;
  OrganisationAddress: ResolverTypeWrapper<AddressModel>;
  OrganisationPage: ResolverTypeWrapper<Omit<OrganisationPage, 'items'> & { items: Array<ResolversTypes['Organisation']> }>;
  PaginationInput: PaginationInput;
  PaginationResponse: ResolverTypeWrapper<PaginationResponse>;
  Partner: ResolverTypeWrapper<PartnerModel>;
  PartnerSkill: ResolverTypeWrapper<PartnerSkill>;
  PartnerSkillInput: PartnerSkillInput;
  PartnerSkillPage: ResolverTypeWrapper<PartnerSkillPage>;
  PartnerSla: ResolverTypeWrapper<PartnerSla>;
  PartnersPage: ResolverTypeWrapper<Omit<PartnersPage, 'items'> & { items: Array<ResolversTypes['Partner']> }>;
  Profile: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Profile']>;
  PutSignedFileUrl: ResolverTypeWrapper<PutSignedFileUrl>;
  Query: ResolverTypeWrapper<{}>;
  ReadUserInput: ReadUserInput;
  RebookJobInput: RebookJobInput;
  RefreshTokenInput: RefreshTokenInput;
  ResetPasswordInput: ResetPasswordInput;
  ResponsibleContractorType: ResponsibleContractorType;
  Roof: ResolverTypeWrapper<RoofModel>;
  RoofArray: ResolverTypeWrapper<RoofArray>;
  RoofArrayInput: RoofArrayInput;
  RoofArrayOrientation: RoofArrayOrientation;
  RoofScaffolding: ResolverTypeWrapper<RoofScaffolding>;
  RoofTile: RoofTile;
  SLA: ResolverTypeWrapper<SlaModel>;
  SLAPage: ResolverTypeWrapper<Omit<SlaPage, 'items'> & { items: Array<ResolversTypes['SLA']> }>;
  SaveJobFeedback: SaveJobFeedback;
  SaveJobHandoverInput: SaveJobHandoverInput;
  ScaffoldingLocation: ScaffoldingLocation;
  ScaffoldingLocationArea: ScaffoldingLocationArea;
  Scheme: ResolverTypeWrapper<SchemeModel>;
  SchemeStatus: SchemeStatus;
  SchemeType: SchemeType;
  SchemesPage: ResolverTypeWrapper<Omit<SchemesPage, 'items'> & { items: Array<ResolversTypes['Scheme']> }>;
  SearchDatesForBooking: SearchDatesForBooking;
  ServiceType: ServiceType;
  Setting: ResolverTypeWrapper<SettingModel>;
  SettingKind: SettingKind;
  SettingPage: ResolverTypeWrapper<Omit<SettingPage, 'items'> & { items: Array<ResolversTypes['Setting']> }>;
  SignInInput: SignInInput;
  SignedUrlInput: SignedUrlInput;
  Skill: ResolverTypeWrapper<SkillModel>;
  SkillLevel: SkillLevel;
  SkillsPage: ResolverTypeWrapper<Omit<SkillsPage, 'items'> & { items: Array<ResolversTypes['Skill']> }>;
  Slot: ResolverTypeWrapper<SlotModel>;
  SlotAllocationMap: ResolverTypeWrapper<SlotAllocationMap>;
  SlotAllocationType: SlotAllocationType;
  SlotCountInput: SlotCountInput;
  SlotJob: ResolverTypeWrapper<SlotJob>;
  SlotJobContractor: ResolverTypeWrapper<SlotJobContractor>;
  SlotJobCustomer: ResolverTypeWrapper<SlotJobCustomer>;
  SlotPage: ResolverTypeWrapper<Omit<SlotPage, 'items'> & { items: Array<ResolversTypes['Slot']> }>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Survey: ResolverTypeWrapper<SurveyModel>;
  TargetType: TargetType;
  Task: ResolverTypeWrapper<TaskModel>;
  TaskFile: ResolverTypeWrapper<TaskFile>;
  TaskJob: ResolverTypeWrapper<TaskJob>;
  TaskStatus: TaskStatus;
  TaskUpsertInput: TaskUpsertInput;
  TaskUser: ResolverTypeWrapper<TaskUser>;
  TasksPage: ResolverTypeWrapper<Omit<TasksPage, 'items'> & { items: Array<ResolversTypes['Task']> }>;
  Ticket: ResolverTypeWrapper<TicketModel>;
  TicketCustomer: ResolverTypeWrapper<TicketCustomer>;
  TicketStatus: TicketStatus;
  TicketsPage: ResolverTypeWrapper<Omit<TicketsPage, 'items'> & { items: Array<ResolversTypes['Ticket']> }>;
  Token: ResolverTypeWrapper<Token>;
  UpdateAddressInput: UpdateAddressInput;
  UpdateJobAuditInput: UpdateJobAuditInput;
  UpdateJobInput: UpdateJobInput;
  UpdateNotificationSettingsInput: UpdateNotificationSettingsInput;
  UpdatePartnerInput: UpdatePartnerInput;
  UpdateRoofInput: UpdateRoofInput;
  UpdateRoofScaffoldingInput: UpdateRoofScaffoldingInput;
  UpdateSchemeInput: UpdateSchemeInput;
  UpdateSelfInput: UpdateSelfInput;
  UpdateSettingInput: UpdateSettingInput;
  UpdateSlotInput: UpdateSlotInput;
  UpdateSurveyInput: UpdateSurveyInput;
  UpdateUserContractorProfileInput: UpdateUserContractorProfileInput;
  UpdateUserCustomerProfileInput: UpdateUserCustomerProfileInput;
  UpdateUserUserProfileInput: UpdateUserUserProfileInput;
  UploadAvatarUrlInput: UploadAvatarUrlInput;
  UploadJobFormFileInput: UploadJobFormFileInput;
  UploadUrlInput: UploadUrlInput;
  UpsertAuditEntryInput: UpsertAuditEntryInput;
  UpsertCommentInput: UpsertCommentInput;
  UpsertOrganisationAddressInput: UpsertOrganisationAddressInput;
  UpsertOrganisationInput: UpsertOrganisationInput;
  UpsertSLAInput: UpsertSlaInput;
  UpsertSchemeInput: UpsertSchemeInput;
  UpsertSkillInput: UpsertSkillInput;
  User: ResolverTypeWrapper<UserModel>;
  UserContractorProfile: ResolverTypeWrapper<ContractorProfileModel>;
  UserContractorSkillInput: UserContractorSkillInput;
  UserCustomerPartner: ResolverTypeWrapper<UserCustomerPartner>;
  UserCustomerProfile: ResolverTypeWrapper<CustomerProfileModel>;
  UserOrganisation: ResolverTypeWrapper<UserOrganisation>;
  UserProfile: UserProfile;
  UserProfileSource: UserProfileSource;
  UserProfileStatus: UserProfileStatus;
  UserRole: UserRole;
  UserUserProfile: ResolverTypeWrapper<UserProfileModel>;
  UsersPage: ResolverTypeWrapper<Omit<UsersPage, 'items'> & { items: Array<ResolversTypes['User']> }>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: AddressModel;
  AssignedJob: JobModel;
  AssignedJobContractor: AssignedJobContractor;
  AssignedJobHandover: AssignedJobHandover;
  AssignedJobsPage: Omit<AssignedJobsPage, 'items'> & { items: Array<ResolversParentTypes['AssignedJob']> };
  AttachJobContractorsInput: AttachJobContractorsInput;
  AuditEntry: AuditEntryModel;
  AuditEntryPage: Omit<AuditEntryPage, 'items'> & { items: Array<ResolversParentTypes['AuditEntry']> };
  AuditEntryUser: UserModel;
  AuditJobSkill: AuditJobSkill;
  AuditorInput: AuditorInput;
  AvailableDate: AvailableDate;
  Boolean: Scalars['Boolean']['output'];
  ChangePasswordInput: ChangePasswordInput;
  Comment: CommentModel;
  CommentFile: CommentFile;
  CommentJob: CommentJob;
  CommentPage: Omit<CommentPage, 'items'> & { items: Array<ResolversParentTypes['Comment']> };
  CommentUser: CommentUser;
  CommitFilesInput: CommitFilesInput;
  CompleteResetPasswordInput: CompleteResetPasswordInput;
  ContractorHoliday: ContractorProfileHolidayModel;
  ContractorHolidaysPage: Omit<ContractorHolidaysPage, 'items'> & { items: Array<ResolversParentTypes['ContractorHoliday']> };
  ContractorNotificationSettings: ContractorNotificationSettings;
  ContractorProduct: ContractorProduct;
  ContractorProductImage: ContractorProductImage;
  ContractorProductPage: ContractorProductPage;
  ContractorProductPageMeta: ContractorProductPageMeta;
  ContractorSkill: ContractorSkill;
  CreateAvatarFileInput: CreateAvatarFileInput;
  CreateFileInput: CreateFileInput;
  CreateJobInput: CreateJobInput;
  CreatePartnerInput: CreatePartnerInput;
  CreateRemedialJobInput: CreateRemedialJobInput;
  CreateRoofInput: CreateRoofInput;
  CreateRoofScaffoldingInput: CreateRoofScaffoldingInput;
  CreateSlotInput: CreateSlotInput;
  CreateUserAddressInput: CreateUserAddressInput;
  CreateUserContractorProfileInput: CreateUserContractorProfileInput;
  CreateUserCustomerProfileInput: CreateUserCustomerProfileInput;
  CreateUserInput: CreateUserInput;
  CreateUserUserProfileInput: CreateUserUserProfileInput;
  Date: Scalars['Date']['output'];
  DeleteFileInput: DeleteFileInput;
  File: FileModel;
  FileOwner: FileOwner;
  FilePage: Omit<FilePage, 'items'> & { items: Array<ResolversParentTypes['File']> };
  Float: Scalars['Float']['output'];
  IndexAssignedJobsFilterInput: IndexAssignedJobsFilterInput;
  IndexAuditEntriesInput: IndexAuditEntriesInput;
  IndexCommentsFilter: IndexCommentsFilter;
  IndexContractorHolidaysFilterInput: IndexContractorHolidaysFilterInput;
  IndexFilesFilter: IndexFilesFilter;
  IndexJobsFilterInput: IndexJobsFilterInput;
  IndexNotificationsFilterInput: IndexNotificationsFilterInput;
  IndexOrganisationsFilterInput: IndexOrganisationsFilterInput;
  IndexPartnersFilterInput: IndexPartnersFilterInput;
  IndexPotentialContractorsForJobFilter: IndexPotentialContractorsForJobFilter;
  IndexSchemesFilterInput: IndexSchemesFilterInput;
  IndexSettingsFilter: IndexSettingsFilter;
  IndexSkillsFilterInput: IndexSkillsFilterInput;
  IndexSkillsForJobsFilterInput: IndexSkillsForJobsFilterInput;
  IndexSlotsFilter: IndexSlotsFilter;
  IndexSlotsInput: IndexSlotsInput;
  IndexTasksFilterInput: IndexTasksFilterInput;
  IndexTicketsFilterInput: IndexTicketsFilterInput;
  IndexUsersFilterInput: IndexUsersFilterInput;
  Int: Scalars['Int']['output'];
  Invoice: InvoiceModel;
  InvoiceCreateInput: InvoiceCreateInput;
  InvoiceUpdateInput: InvoiceUpdateInput;
  InvoicesPage: Omit<InvoicesPage, 'items'> & { items: Array<ResolversParentTypes['Invoice']> };
  Job: JobModel;
  JobAddress: JobAddress;
  JobAudit: JobAuditModel;
  JobAuditor: JobAuditor;
  JobContractor: JobContractor;
  JobContractorInput: JobContractorInput;
  JobContractorPotential: JobContractorPotential;
  JobContractorUser: JobContractorUser;
  JobCustomer: JobCustomer;
  JobHandover: JobHandover;
  JobPartner: PartnerModel;
  JobProduct: JobProductModel;
  JobProductFile: JobProductFile;
  JobProductInput: JobProductInput;
  JobProductProduct: JobProductProduct;
  JobSkill: JobSkill;
  JobSurvey: JobSurvey;
  JobSurveyFile: JobSurveyFile;
  JobsPage: Omit<JobsPage, 'items'> & { items: Array<ResolversParentTypes['Job']> };
  Mutation: {};
  Notification: NotificationModel;
  NotificationsPage: Omit<NotificationsPage, 'items'> & { items: Array<ResolversParentTypes['Notification']> };
  Organisation: OrganisationModel;
  OrganisationAddress: AddressModel;
  OrganisationPage: Omit<OrganisationPage, 'items'> & { items: Array<ResolversParentTypes['Organisation']> };
  PaginationInput: PaginationInput;
  PaginationResponse: PaginationResponse;
  Partner: PartnerModel;
  PartnerSkill: PartnerSkill;
  PartnerSkillInput: PartnerSkillInput;
  PartnerSkillPage: PartnerSkillPage;
  PartnerSla: PartnerSla;
  PartnersPage: Omit<PartnersPage, 'items'> & { items: Array<ResolversParentTypes['Partner']> };
  Profile: ResolversUnionTypes<ResolversParentTypes>['Profile'];
  PutSignedFileUrl: PutSignedFileUrl;
  Query: {};
  ReadUserInput: ReadUserInput;
  RebookJobInput: RebookJobInput;
  RefreshTokenInput: RefreshTokenInput;
  ResetPasswordInput: ResetPasswordInput;
  Roof: RoofModel;
  RoofArray: RoofArray;
  RoofArrayInput: RoofArrayInput;
  RoofScaffolding: RoofScaffolding;
  SLA: SlaModel;
  SLAPage: Omit<SlaPage, 'items'> & { items: Array<ResolversParentTypes['SLA']> };
  SaveJobFeedback: SaveJobFeedback;
  SaveJobHandoverInput: SaveJobHandoverInput;
  Scheme: SchemeModel;
  SchemesPage: Omit<SchemesPage, 'items'> & { items: Array<ResolversParentTypes['Scheme']> };
  SearchDatesForBooking: SearchDatesForBooking;
  Setting: SettingModel;
  SettingPage: Omit<SettingPage, 'items'> & { items: Array<ResolversParentTypes['Setting']> };
  SignInInput: SignInInput;
  SignedUrlInput: SignedUrlInput;
  Skill: SkillModel;
  SkillsPage: Omit<SkillsPage, 'items'> & { items: Array<ResolversParentTypes['Skill']> };
  Slot: SlotModel;
  SlotAllocationMap: SlotAllocationMap;
  SlotCountInput: SlotCountInput;
  SlotJob: SlotJob;
  SlotJobContractor: SlotJobContractor;
  SlotJobCustomer: SlotJobCustomer;
  SlotPage: Omit<SlotPage, 'items'> & { items: Array<ResolversParentTypes['Slot']> };
  String: Scalars['String']['output'];
  Survey: SurveyModel;
  Task: TaskModel;
  TaskFile: TaskFile;
  TaskJob: TaskJob;
  TaskUpsertInput: TaskUpsertInput;
  TaskUser: TaskUser;
  TasksPage: Omit<TasksPage, 'items'> & { items: Array<ResolversParentTypes['Task']> };
  Ticket: TicketModel;
  TicketCustomer: TicketCustomer;
  TicketsPage: Omit<TicketsPage, 'items'> & { items: Array<ResolversParentTypes['Ticket']> };
  Token: Token;
  UpdateAddressInput: UpdateAddressInput;
  UpdateJobAuditInput: UpdateJobAuditInput;
  UpdateJobInput: UpdateJobInput;
  UpdateNotificationSettingsInput: UpdateNotificationSettingsInput;
  UpdatePartnerInput: UpdatePartnerInput;
  UpdateRoofInput: UpdateRoofInput;
  UpdateRoofScaffoldingInput: UpdateRoofScaffoldingInput;
  UpdateSchemeInput: UpdateSchemeInput;
  UpdateSelfInput: UpdateSelfInput;
  UpdateSettingInput: UpdateSettingInput;
  UpdateSlotInput: UpdateSlotInput;
  UpdateSurveyInput: UpdateSurveyInput;
  UpdateUserContractorProfileInput: UpdateUserContractorProfileInput;
  UpdateUserCustomerProfileInput: UpdateUserCustomerProfileInput;
  UpdateUserUserProfileInput: UpdateUserUserProfileInput;
  UploadAvatarUrlInput: UploadAvatarUrlInput;
  UploadJobFormFileInput: UploadJobFormFileInput;
  UploadUrlInput: UploadUrlInput;
  UpsertAuditEntryInput: UpsertAuditEntryInput;
  UpsertCommentInput: UpsertCommentInput;
  UpsertOrganisationAddressInput: UpsertOrganisationAddressInput;
  UpsertOrganisationInput: UpsertOrganisationInput;
  UpsertSLAInput: UpsertSlaInput;
  UpsertSchemeInput: UpsertSchemeInput;
  UpsertSkillInput: UpsertSkillInput;
  User: UserModel;
  UserContractorProfile: ContractorProfileModel;
  UserContractorSkillInput: UserContractorSkillInput;
  UserCustomerPartner: UserCustomerPartner;
  UserCustomerProfile: CustomerProfileModel;
  UserOrganisation: UserOrganisation;
  UserUserProfile: UserProfileModel;
  UsersPage: Omit<UsersPage, 'items'> & { items: Array<ResolversParentTypes['User']> };
};

export type CacheControlDirectiveArgs = {
  inheritMaxAge: Maybe<Scalars['Boolean']['input']>;
  maxAge: Maybe<Scalars['Int']['input']>;
  scope: Maybe<CacheControlScope>;
};

export type CacheControlDirectiveResolver<Result, Parent, ContextType = InvocationContext, Args = CacheControlDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddressResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  line1: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line2: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postcode: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedJobResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AssignedJob'] = ResolversParentTypes['AssignedJob']> = {
  address: Resolver<Maybe<ResolversTypes['JobAddress']>, ParentType, ContextType>;
  contractors: Resolver<Maybe<Array<ResolversTypes['AssignedJobContractor']>>, ParentType, ContextType>;
  customer: Resolver<ResolversTypes['JobCustomer'], ParentType, ContextType>;
  dateRequired: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extras: Resolver<Maybe<Array<ResolversTypes['JobProduct']>>, ParentType, ContextType>;
  handovers: Resolver<Maybe<Array<ResolversTypes['AssignedJobHandover']>>, ParentType, ContextType>;
  hasBirdproofing: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasEmergencyPs: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPowerDiverter: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasRemedial: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  panelQuantity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partner: Resolver<ResolversTypes['JobPartner'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  survey: Resolver<ResolversTypes['JobSurvey'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobType'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedJobContractorResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AssignedJobContractor'] = ResolversParentTypes['AssignedJobContractor']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateRequired: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConfirmed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isScaffolder: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedJobHandoverResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AssignedJobHandover'] = ResolversParentTypes['AssignedJobHandover']> = {
  jobUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submittedAt: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobHandoverType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedJobsPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AssignedJobsPage'] = ResolversParentTypes['AssignedJobsPage']> = {
  items: Resolver<Array<ResolversTypes['AssignedJob']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuditEntryResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AuditEntry'] = ResolversParentTypes['AuditEntry']> = {
  auditEntryUser: Resolver<Maybe<ResolversTypes['AuditEntryUser']>, ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  notes: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetType: Resolver<ResolversTypes['TargetType'], ParentType, ContextType>;
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuditEntryPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AuditEntryPage'] = ResolversParentTypes['AuditEntryPage']> = {
  items: Resolver<Array<ResolversTypes['AuditEntry']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuditEntryUserResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AuditEntryUser'] = ResolversParentTypes['AuditEntryUser']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userType: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableDateResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['AvailableDate'] = ResolversParentTypes['AvailableDate']> = {
  displayDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slotDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  files: Resolver<Maybe<Array<ResolversTypes['CommentFile']>>, ParentType, ContextType>;
  job: Resolver<Maybe<ResolversTypes['CommentJob']>, ParentType, ContextType>;
  targetType: Resolver<ResolversTypes['CommentTargetType'], ParentType, ContextType>;
  targetUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user: Resolver<ResolversTypes['CommentUser'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentFileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['CommentFile'] = ResolversParentTypes['CommentFile']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentJobResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['CommentJob'] = ResolversParentTypes['CommentJob']> = {
  displayLink: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['CommentPage'] = ResolversParentTypes['CommentPage']> = {
  items: Resolver<Array<ResolversTypes['Comment']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentUserResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['CommentUser'] = ResolversParentTypes['CommentUser']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorHolidayResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorHoliday'] = ResolversParentTypes['ContractorHoliday']> = {
  endDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  startDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['ContractorHolidayStatus'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorHolidaysPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorHolidaysPage'] = ResolversParentTypes['ContractorHolidaysPage']> = {
  items: Resolver<Array<ResolversTypes['ContractorHoliday']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorNotificationSettingsResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorNotificationSettings'] = ResolversParentTypes['ContractorNotificationSettings']> = {
  inAppNotifications: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newJobAlerts: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushNotifications: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedHolidayAlerts: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedJobAlerts: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorProductResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorProduct'] = ResolversParentTypes['ContractorProduct']> = {
  customerAvatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerFirstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerLastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerUserUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image: Resolver<Maybe<ResolversTypes['ContractorProductImage']>, ParentType, ContextType>;
  installationDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  jobUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorProductImageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorProductImage'] = ResolversParentTypes['ContractorProductImage']> = {
  mimeType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorProductPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorProductPage'] = ResolversParentTypes['ContractorProductPage']> = {
  items: Resolver<Array<ResolversTypes['ContractorProduct']>, ParentType, ContextType>;
  meta: Resolver<ResolversTypes['ContractorProductPageMeta'], ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorProductPageMetaResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorProductPageMeta'] = ResolversParentTypes['ContractorProductPageMeta']> = {
  allTimeTotal: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractorSkillResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['ContractorSkill'] = ResolversParentTypes['ContractorSkill']> = {
  levelRequired: Resolver<ResolversTypes['SkillLevel'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trade: Resolver<ResolversTypes['ContractorTrade'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type FileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  downloadSrc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner: Resolver<Maybe<ResolversTypes['FileOwner']>, ParentType, ContextType>;
  size: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetType: Resolver<Maybe<ResolversTypes['FileTargetType']>, ParentType, ContextType>;
  targetUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOwnerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['FileOwner'] = ResolversParentTypes['FileOwner']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilePageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['FilePage'] = ResolversParentTypes['FilePage']> = {
  items: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  pagination: Resolver<Maybe<ResolversTypes['PaginationResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  amount: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  dueDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  notes: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['InvoiceStatus'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xeroLink: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xeroReference: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoicesPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['InvoicesPage'] = ResolversParentTypes['InvoicesPage']> = {
  items: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']> = {
  address: Resolver<Maybe<ResolversTypes['JobAddress']>, ParentType, ContextType>;
  contractors: Resolver<Maybe<Array<ResolversTypes['AssignedJobContractor']>>, ParentType, ContextType>;
  customer: Resolver<ResolversTypes['JobCustomer'], ParentType, ContextType>;
  dates: Resolver<Array<ResolversTypes['Date']>, ParentType, ContextType>;
  difficulty: Resolver<ResolversTypes['JobDifficulty'], ParentType, ContextType>;
  displayDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayLink: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  extras: Resolver<Maybe<Array<ResolversTypes['JobProduct']>>, ParentType, ContextType>;
  operatorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partner: Resolver<ResolversTypes['JobPartner'], ParentType, ContextType>;
  revisedSolarPanelCount: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  schemeName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  solarPanelCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  survey: Resolver<ResolversTypes['JobSurvey'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobType'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAddressResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobAddress'] = ResolversParentTypes['JobAddress']> = {
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  line1: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line2: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postcode: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAuditResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobAudit'] = ResolversParentTypes['JobAudit']> = {
  auditType: Resolver<ResolversTypes['AuditType'], ParentType, ContextType>;
  auditor: Resolver<Maybe<ResolversTypes['JobAuditor']>, ParentType, ContextType>;
  hasPassed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notes: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedAt: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAuditorResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobAuditor'] = ResolversParentTypes['JobAuditor']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobContractorResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobContractor'] = ResolversParentTypes['JobContractor']> = {
  canBeElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canBeRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractorProfileUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  datesRequired: Resolver<Array<ResolversTypes['Date']>, ParentType, ContextType>;
  displayDateRequired: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distance: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user: Resolver<ResolversTypes['JobContractorUser'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobContractorPotentialResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobContractorPotential'] = ResolversParentTypes['JobContractorPotential']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  canBeElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canBeRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractorProfileUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distance: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobContractorUserResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobContractorUser'] = ResolversParentTypes['JobContractorUser']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobCustomerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobCustomer'] = ResolversParentTypes['JobCustomer']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobHandoverResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobHandover'] = ResolversParentTypes['JobHandover']> = {
  displayDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobHandoverType'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobPartnerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobPartner'] = ResolversParentTypes['JobPartner']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobProductResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobProduct'] = ResolversParentTypes['JobProduct']> = {
  contractorProfileUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image: Resolver<Maybe<ResolversTypes['JobProductFile']>, ParentType, ContextType>;
  isBillable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInstalled: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  price: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product: Resolver<ResolversTypes['JobProductProduct'], ParentType, ContextType>;
  productUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobProductFileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobProductFile'] = ResolversParentTypes['JobProductFile']> = {
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobProductProductResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobProductProduct'] = ResolversParentTypes['JobProductProduct']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  responsibleContractorType: Resolver<Maybe<ResolversTypes['ResponsibleContractorType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobSkillResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobSkill'] = ResolversParentTypes['JobSkill']> = {
  levelRequired: Resolver<ResolversTypes['SkillLevel'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trade: Resolver<ResolversTypes['ContractorTrade'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobSurveyResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobSurvey'] = ResolversParentTypes['JobSurvey']> = {
  battery: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  files: Resolver<Maybe<Array<ResolversTypes['JobSurveyFile']>>, ParentType, ContextType>;
  inverter: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobSurveyFileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobSurveyFile'] = ResolversParentTypes['JobSurveyFile']> = {
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobsPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['JobsPage'] = ResolversParentTypes['JobsPage']> = {
  items: Resolver<Array<ResolversTypes['Job']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  attachJobContractors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAttachJobContractorsArgs, 'input'>>;
  changePassword: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'input'>>;
  commitFiles: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCommitFilesArgs, 'input'>>;
  createAvatarFile: Resolver<ResolversTypes['File'], ParentType, ContextType, RequireFields<MutationCreateAvatarFileArgs, 'input'>>;
  createFile: Resolver<ResolversTypes['File'], ParentType, ContextType, RequireFields<MutationCreateFileArgs, 'input'>>;
  createInvoice: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationCreateInvoiceArgs, 'input'>>;
  createJob: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<MutationCreateJobArgs, 'input'>>;
  createJobFormFile: Resolver<ResolversTypes['File'], ParentType, ContextType, RequireFields<MutationCreateJobFormFileArgs, 'input'>>;
  createPartner: Resolver<ResolversTypes['Partner'], ParentType, ContextType, RequireFields<MutationCreatePartnerArgs, 'input'>>;
  createRemedialJob: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateRemedialJobArgs, 'input'>>;
  createRoof: Resolver<ResolversTypes['Roof'], ParentType, ContextType, RequireFields<MutationCreateRoofArgs, 'input'>>;
  createSlot: Resolver<ResolversTypes['Slot'], ParentType, ContextType, RequireFields<MutationCreateSlotArgs, 'input'>>;
  createUser: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  createUserContractorProfile: Resolver<ResolversTypes['UserContractorProfile'], ParentType, ContextType, RequireFields<MutationCreateUserContractorProfileArgs, 'input'>>;
  createUserCustomerProfile: Resolver<ResolversTypes['UserCustomerProfile'], ParentType, ContextType, RequireFields<MutationCreateUserCustomerProfileArgs, 'input'>>;
  createUserUserProfile: Resolver<ResolversTypes['UserUserProfile'], ParentType, ContextType, RequireFields<MutationCreateUserUserProfileArgs, 'input'>>;
  deleteComment: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'uuid'>>;
  deleteFile: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFileArgs, 'input'>>;
  deleteOrganisation: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteOrganisationArgs, 'uuid'>>;
  deleteRoof: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteRoofArgs, 'uuid'>>;
  deleteSLA: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteSlaArgs, 'uuid'>>;
  deleteSkill: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteSkillArgs, 'uuid'>>;
  deleteSlot: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteSlotArgs, 'uuid'>>;
  dismissAllNotifications: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dismissNotification: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationDismissNotificationArgs, 'uuid'>>;
  getSignedFileUrl: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGetSignedFileUrlArgs, 'input'>>;
  putSignedAvatarPhotoUrl: Resolver<ResolversTypes['PutSignedFileUrl'], ParentType, ContextType, RequireFields<MutationPutSignedAvatarPhotoUrlArgs, 'input'>>;
  putSignedFileUrl: Resolver<ResolversTypes['PutSignedFileUrl'], ParentType, ContextType, RequireFields<MutationPutSignedFileUrlArgs, 'input'>>;
  putSignedJobFormFileUrl: Resolver<ResolversTypes['PutSignedFileUrl'], ParentType, ContextType, RequireFields<MutationPutSignedJobFormFileUrlArgs, 'input'>>;
  rebookJob: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<MutationRebookJobArgs, 'input'>>;
  saveFeedback: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSaveFeedbackArgs, 'input'>>;
  saveJobHandover: Resolver<ResolversTypes['AssignedJobHandover'], ParentType, ContextType, RequireFields<MutationSaveJobHandoverArgs, 'input'>>;
  updateAddress: Resolver<ResolversTypes['Address'], ParentType, ContextType, RequireFields<MutationUpdateAddressArgs, 'input'>>;
  updateInvoice: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationUpdateInvoiceArgs, 'input'>>;
  updateJob: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<MutationUpdateJobArgs, 'input'>>;
  updateJobAudit: Resolver<ResolversTypes['JobAudit'], ParentType, ContextType, RequireFields<MutationUpdateJobAuditArgs, 'input'>>;
  updateNotificationSettings: Resolver<ResolversTypes['ContractorNotificationSettings'], ParentType, ContextType, RequireFields<MutationUpdateNotificationSettingsArgs, 'input'>>;
  updatePartner: Resolver<ResolversTypes['Partner'], ParentType, ContextType, RequireFields<MutationUpdatePartnerArgs, 'input'>>;
  updateRoof: Resolver<ResolversTypes['Roof'], ParentType, ContextType, RequireFields<MutationUpdateRoofArgs, 'input'>>;
  updateScheme: Resolver<ResolversTypes['Scheme'], ParentType, ContextType, RequireFields<MutationUpdateSchemeArgs, 'input'>>;
  updateSelf: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateSelfArgs, 'input'>>;
  updateSetting: Resolver<ResolversTypes['Setting'], ParentType, ContextType, RequireFields<MutationUpdateSettingArgs, 'input'>>;
  updateSlot: Resolver<ResolversTypes['Slot'], ParentType, ContextType, RequireFields<MutationUpdateSlotArgs, 'input'>>;
  updateSurvey: Resolver<ResolversTypes['Survey'], ParentType, ContextType, RequireFields<MutationUpdateSurveyArgs, 'input'>>;
  updateUserContractorProfile: Resolver<ResolversTypes['UserContractorProfile'], ParentType, ContextType, RequireFields<MutationUpdateUserContractorProfileArgs, 'input'>>;
  updateUserCustomerProfile: Resolver<ResolversTypes['UserCustomerProfile'], ParentType, ContextType, RequireFields<MutationUpdateUserCustomerProfileArgs, 'input'>>;
  updateUserUserProfile: Resolver<ResolversTypes['UserUserProfile'], ParentType, ContextType, RequireFields<MutationUpdateUserUserProfileArgs, 'input'>>;
  upsertAuditEntry: Resolver<ResolversTypes['AuditEntry'], ParentType, ContextType, RequireFields<MutationUpsertAuditEntryArgs, 'input'>>;
  upsertComment: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<MutationUpsertCommentArgs, 'input'>>;
  upsertOrganisation: Resolver<Maybe<ResolversTypes['Organisation']>, ParentType, ContextType, RequireFields<MutationUpsertOrganisationArgs, 'input'>>;
  upsertSLA: Resolver<ResolversTypes['SLA'], ParentType, ContextType, RequireFields<MutationUpsertSlaArgs, 'input'>>;
  upsertScheme: Resolver<ResolversTypes['Scheme'], ParentType, ContextType, RequireFields<MutationUpsertSchemeArgs, 'input'>>;
  upsertSkill: Resolver<ResolversTypes['Skill'], ParentType, ContextType, RequireFields<MutationUpsertSkillArgs, 'input'>>;
  upsertTask: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationUpsertTaskArgs, 'input'>>;
  viewNotification: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationViewNotificationArgs, 'uuid'>>;
};

export type NotificationResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  actionUrl: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDismissed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isViewed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetType: Resolver<ResolversTypes['NotificationTargetType'], ParentType, ContextType>;
  targetUserUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['NotificationsPage'] = ResolversParentTypes['NotificationsPage']> = {
  items: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Organisation'] = ResolversParentTypes['Organisation']> = {
  address: Resolver<ResolversTypes['OrganisationAddress'], ParentType, ContextType>;
  canDelete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  displayAddress: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  telephone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationAddressResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['OrganisationAddress'] = ResolversParentTypes['OrganisationAddress']> = {
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  line1: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line2: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  postcode: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['OrganisationPage'] = ResolversParentTypes['OrganisationPage']> = {
  items: Resolver<Array<ResolversTypes['Organisation']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationResponseResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PaginationResponse'] = ResolversParentTypes['PaginationResponse']> = {
  currentPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']> = {
  customerCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schemeCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sla: Resolver<ResolversTypes['PartnerSla'], ParentType, ContextType>;
  slotAllocationAmount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slotAllocationType: Resolver<ResolversTypes['SlotAllocationType'], ParentType, ContextType>;
  telephone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerSkillResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PartnerSkill'] = ResolversParentTypes['PartnerSkill']> = {
  levelRequired: Resolver<ResolversTypes['SkillLevel'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trade: Resolver<ResolversTypes['ContractorTrade'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerSkillPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PartnerSkillPage'] = ResolversParentTypes['PartnerSkillPage']> = {
  items: Resolver<Array<ResolversTypes['PartnerSkill']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerSlaResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PartnerSla'] = ResolversParentTypes['PartnerSla']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnersPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PartnersPage'] = ResolversParentTypes['PartnersPage']> = {
  items: Resolver<Array<ResolversTypes['Partner']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  __resolveType: TypeResolveFn<'UserContractorProfile' | 'UserCustomerProfile' | 'UserUserProfile', ParentType, ContextType>;
};

export type PutSignedFileUrlResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['PutSignedFileUrl'] = ResolversParentTypes['PutSignedFileUrl']> = {
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  allTaskCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryAllTaskCountArgs, 'myAssignment'>>;
  allTasks: Resolver<ResolversTypes['TasksPage'], ParentType, ContextType, RequireFields<QueryAllTasksArgs, 'myAssignment'>>;
  auditPendingJobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryAuditPendingJobCountArgs, 'myAssignment'>>;
  auditPendingJobs: Resolver<ResolversTypes['JobsPage'], ParentType, ContextType, RequireFields<QueryAuditPendingJobsArgs, 'myAssignment'>>;
  blockedJobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryBlockedJobCountArgs, 'myAssignment'>>;
  blockedJobs: Resolver<ResolversTypes['JobsPage'], ParentType, ContextType, RequireFields<QueryBlockedJobsArgs, 'myAssignment'>>;
  completeResetPassword: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCompleteResetPasswordArgs, 'input'>>;
  estimateSlotAllocations: Resolver<Array<ResolversTypes['SlotAllocationMap']>, ParentType, ContextType>;
  findUniqueUser: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  indexAssignedJobs: Resolver<ResolversTypes['AssignedJobsPage'], ParentType, ContextType, Partial<QueryIndexAssignedJobsArgs>>;
  indexAuditEntries: Resolver<ResolversTypes['AuditEntryPage'], ParentType, ContextType, RequireFields<QueryIndexAuditEntriesArgs, 'filters'>>;
  indexAuditsForJob: Resolver<Array<ResolversTypes['JobAudit']>, ParentType, ContextType, RequireFields<QueryIndexAuditsForJobArgs, 'uuid'>>;
  indexComments: Resolver<ResolversTypes['CommentPage'], ParentType, ContextType, RequireFields<QueryIndexCommentsArgs, 'filter'>>;
  indexContractorHolidays: Resolver<Maybe<ResolversTypes['ContractorHolidaysPage']>, ParentType, ContextType, Partial<QueryIndexContractorHolidaysArgs>>;
  indexContractorsForJob: Resolver<Array<ResolversTypes['JobContractor']>, ParentType, ContextType, RequireFields<QueryIndexContractorsForJobArgs, 'uuid'>>;
  indexFiles: Resolver<ResolversTypes['FilePage'], ParentType, ContextType, RequireFields<QueryIndexFilesArgs, 'filters'>>;
  indexHandoversForJob: Resolver<Array<ResolversTypes['JobHandover']>, ParentType, ContextType, RequireFields<QueryIndexHandoversForJobArgs, 'uuid'>>;
  indexInstalledProductsForContractor: Resolver<ResolversTypes['ContractorProductPage'], ParentType, ContextType, RequireFields<QueryIndexInstalledProductsForContractorArgs, 'contractorProfileUuid'>>;
  indexInvoicesForCustomer: Resolver<ResolversTypes['InvoicesPage'], ParentType, ContextType, RequireFields<QueryIndexInvoicesForCustomerArgs, 'customerProfileUuid'>>;
  indexJobs: Resolver<ResolversTypes['JobsPage'], ParentType, ContextType, Partial<QueryIndexJobsArgs>>;
  indexNotifications: Resolver<ResolversTypes['NotificationsPage'], ParentType, ContextType, Partial<QueryIndexNotificationsArgs>>;
  indexOrganisations: Resolver<ResolversTypes['OrganisationPage'], ParentType, ContextType, Partial<QueryIndexOrganisationsArgs>>;
  indexPartners: Resolver<ResolversTypes['PartnersPage'], ParentType, ContextType, Partial<QueryIndexPartnersArgs>>;
  indexPotentialContractorsForJob: Resolver<Array<ResolversTypes['JobContractorPotential']>, ParentType, ContextType, RequireFields<QueryIndexPotentialContractorsForJobArgs, 'filters'>>;
  indexProductsForJob: Resolver<Array<ResolversTypes['JobProduct']>, ParentType, ContextType, RequireFields<QueryIndexProductsForJobArgs, 'uuid'>>;
  indexRoofsForJob: Resolver<Array<ResolversTypes['Roof']>, ParentType, ContextType, RequireFields<QueryIndexRoofsForJobArgs, 'uuid'>>;
  indexSLAs: Resolver<ResolversTypes['SLAPage'], ParentType, ContextType, Partial<QueryIndexSlAsArgs>>;
  indexSchemes: Resolver<ResolversTypes['SchemesPage'], ParentType, ContextType, Partial<QueryIndexSchemesArgs>>;
  indexSettings: Resolver<ResolversTypes['SettingPage'], ParentType, ContextType, RequireFields<QueryIndexSettingsArgs, 'filter'>>;
  indexSkills: Resolver<ResolversTypes['SkillsPage'], ParentType, ContextType, Partial<QueryIndexSkillsArgs>>;
  indexSkillsForContractors: Resolver<Array<ResolversTypes['ContractorSkill']>, ParentType, ContextType, RequireFields<QueryIndexSkillsForContractorsArgs, 'contractorProfileUuid'>>;
  indexSkillsForJobs: Resolver<Array<ResolversTypes['JobSkill']>, ParentType, ContextType, RequireFields<QueryIndexSkillsForJobsArgs, 'filters'>>;
  indexSkillsForPartners: Resolver<ResolversTypes['PartnerSkillPage'], ParentType, ContextType, RequireFields<QueryIndexSkillsForPartnersArgs, 'partnerUuid'>>;
  indexSlots: Resolver<ResolversTypes['SlotPage'], ParentType, ContextType, RequireFields<QueryIndexSlotsArgs, 'input'>>;
  indexTasks: Resolver<ResolversTypes['TasksPage'], ParentType, ContextType, Partial<QueryIndexTasksArgs>>;
  indexTickets: Resolver<ResolversTypes['TicketsPage'], ParentType, ContextType, Partial<QueryIndexTicketsArgs>>;
  indexUsers: Resolver<ResolversTypes['UsersPage'], ParentType, ContextType, Partial<QueryIndexUsersArgs>>;
  logout: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  readAssignedJob: Resolver<ResolversTypes['AssignedJob'], ParentType, ContextType, RequireFields<QueryReadAssignedJobArgs, 'uuid'>>;
  readContractorHoliday: Resolver<ResolversTypes['ContractorHoliday'], ParentType, ContextType, RequireFields<QueryReadContractorHolidayArgs, 'uuid'>>;
  readJob: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<QueryReadJobArgs, 'uuid'>>;
  readNotificationSettings: Resolver<ResolversTypes['ContractorNotificationSettings'], ParentType, ContextType>;
  readPartner: Resolver<ResolversTypes['Partner'], ParentType, ContextType, RequireFields<QueryReadPartnerArgs, 'uuid'>>;
  readScheme: Resolver<ResolversTypes['Scheme'], ParentType, ContextType, RequireFields<QueryReadSchemeArgs, 'uuid'>>;
  readSkill: Resolver<ResolversTypes['Skill'], ParentType, ContextType, RequireFields<QueryReadSkillArgs, 'uuid'>>;
  readTask: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<QueryReadTaskArgs, 'uuid'>>;
  readUser: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryReadUserArgs, 'input'>>;
  refresh: Resolver<ResolversTypes['Token'], ParentType, ContextType, Partial<QueryRefreshArgs>>;
  resetPassword: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryResetPasswordArgs, 'input'>>;
  searchDatesForRebook: Resolver<Array<ResolversTypes['AvailableDate']>, ParentType, ContextType, RequireFields<QuerySearchDatesForRebookArgs, 'input'>>;
  signIn: Resolver<ResolversTypes['Token'], ParentType, ContextType, RequireFields<QuerySignInArgs, 'input'>>;
  slotCount: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QuerySlotCountArgs, 'input'>>;
  todaysJobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryTodaysJobCountArgs, 'myAssignment'>>;
  todaysJobs: Resolver<ResolversTypes['JobsPage'], ParentType, ContextType, RequireFields<QueryTodaysJobsArgs, 'myAssignment'>>;
  unassignedJobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryUnassignedJobCountArgs, 'myAssignment'>>;
  unassignedJobs: Resolver<ResolversTypes['JobsPage'], ParentType, ContextType, RequireFields<QueryUnassignedJobsArgs, 'myAssignment'>>;
  unassignedTaskCount: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryUnassignedTaskCountArgs, 'myAssignment'>>;
  unassignedTasks: Resolver<ResolversTypes['TasksPage'], ParentType, ContextType, RequireFields<QueryUnassignedTasksArgs, 'myAssignment'>>;
};

export type RoofResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Roof'] = ResolversParentTypes['Roof']> = {
  arrays: Resolver<Array<ResolversTypes['RoofArray']>, ParentType, ContextType>;
  scaffolding: Resolver<Maybe<ResolversTypes['RoofScaffolding']>, ParentType, ContextType>;
  tile: Resolver<ResolversTypes['RoofTile'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoofArrayResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['RoofArray'] = ResolversParentTypes['RoofArray']> = {
  orientation: Resolver<ResolversTypes['RoofArrayOrientation'], ParentType, ContextType>;
  panels: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoofScaffoldingResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['RoofScaffolding'] = ResolversParentTypes['RoofScaffolding']> = {
  height: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBridgeRequired: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTowerRequired: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location: Resolver<ResolversTypes['ScaffoldingLocation'], ParentType, ContextType>;
  locationArea: Resolver<ResolversTypes['ScaffoldingLocationArea'], ParentType, ContextType>;
  locationOtherDescription: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  width: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlaResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SLA'] = ResolversParentTypes['SLA']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scaffoldingSetUpThreshold: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scaffoldingStrikeThreshold: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlaPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SLAPage'] = ResolversParentTypes['SLAPage']> = {
  items: Resolver<Array<ResolversTypes['SLA']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemeResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Scheme'] = ResolversParentTypes['Scheme']> = {
  electricalAuditorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  formattedEndDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formattedStartDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operatorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postAuditorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preAuditorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roofingAuditorUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schemeType: Resolver<ResolversTypes['SchemeType'], ParentType, ContextType>;
  startDate: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['SchemeStatus'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemesPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SchemesPage'] = ResolversParentTypes['SchemesPage']> = {
  items: Resolver<Array<ResolversTypes['Scheme']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Setting'] = ResolversParentTypes['Setting']> = {
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind: Resolver<ResolversTypes['SettingKind'], ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SettingPage'] = ResolversParentTypes['SettingPage']> = {
  items: Resolver<Array<ResolversTypes['Setting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Skill'] = ResolversParentTypes['Skill']> = {
  contractorCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillsPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SkillsPage'] = ResolversParentTypes['SkillsPage']> = {
  items: Resolver<Array<ResolversTypes['Skill']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Slot'] = ResolversParentTypes['Slot']> = {
  isBatterySelected: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isElectricianAvailable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInstallationSelected: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRemedialSelected: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRooferAvailable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  job: Resolver<Maybe<ResolversTypes['SlotJob']>, ParentType, ContextType>;
  schemeName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotAllocationMapResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SlotAllocationMap'] = ResolversParentTypes['SlotAllocationMap']> = {
  installationAllocated: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  installationRequired: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partnerUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotJobResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SlotJob'] = ResolversParentTypes['SlotJob']> = {
  atRisk: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractors: Resolver<Array<ResolversTypes['SlotJobContractor']>, ParentType, ContextType>;
  customer: Resolver<ResolversTypes['SlotJobCustomer'], ParentType, ContextType>;
  difficulty: Resolver<ResolversTypes['JobDifficulty'], ParentType, ContextType>;
  displayInstallationDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobType'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotJobContractorResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SlotJobContractor'] = ResolversParentTypes['SlotJobContractor']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotJobCustomerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SlotJobCustomer'] = ResolversParentTypes['SlotJobCustomer']> = {
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line1: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postcode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schemeName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['SlotPage'] = ResolversParentTypes['SlotPage']> = {
  items: Resolver<Array<ResolversTypes['Slot']>, ParentType, ContextType>;
  pagination: Resolver<Maybe<ResolversTypes['PaginationResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SurveyResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Survey'] = ResolversParentTypes['Survey']> = {
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  assignee: Resolver<Maybe<ResolversTypes['TaskUser']>, ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  creator: Resolver<Maybe<ResolversTypes['TaskUser']>, ParentType, ContextType>;
  customer: Resolver<Maybe<ResolversTypes['TaskUser']>, ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  files: Resolver<Array<ResolversTypes['TaskFile']>, ParentType, ContextType>;
  job: Resolver<Maybe<ResolversTypes['TaskJob']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['TaskStatus'], ParentType, ContextType>;
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskFileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TaskFile'] = ResolversParentTypes['TaskFile']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  src: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskJobResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TaskJob'] = ResolversParentTypes['TaskJob']> = {
  displayName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['JobType'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskUserResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TaskUser'] = ResolversParentTypes['TaskUser']> = {
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerUuid: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TasksPage'] = ResolversParentTypes['TasksPage']> = {
  items: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Ticket'] = ResolversParentTypes['Ticket']> = {
  createdAt: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  customer: Resolver<ResolversTypes['TicketCustomer'], ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastInteraction: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['TicketStatus'], ParentType, ContextType>;
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketCustomerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TicketCustomer'] = ResolversParentTypes['TicketCustomer']> = {
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['TicketsPage'] = ResolversParentTypes['TicketsPage']> = {
  items: Resolver<Array<ResolversTypes['Ticket']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = {
  accessToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  address: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  avatarSrc: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastLogin: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  schemeName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unreadNotificationCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContractorProfileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UserContractorProfile'] = ResolversParentTypes['UserContractorProfile']> = {
  availability: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completionRate: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  holidays: Resolver<Maybe<Array<ResolversTypes['ContractorHoliday']>>, ParentType, ContextType>;
  isElectrician: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRoofer: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isScaffolder: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organisation: Resolver<ResolversTypes['UserOrganisation'], ParentType, ContextType>;
  servicingRadius: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['UserProfileStatus'], ParentType, ContextType>;
  testInstrumentSerialNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCustomerPartnerResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UserCustomerPartner'] = ResolversParentTypes['UserCustomerPartner']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCustomerProfileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UserCustomerProfile'] = ResolversParentTypes['UserCustomerProfile']> = {
  jobStatus: Resolver<Maybe<ResolversTypes['JobStatus']>, ParentType, ContextType>;
  partner: Resolver<ResolversTypes['UserCustomerPartner'], ParentType, ContextType>;
  schemeUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source: Resolver<ResolversTypes['UserProfileSource'], ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganisationResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UserOrganisation'] = ResolversParentTypes['UserOrganisation']> = {
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUserProfileResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UserUserProfile'] = ResolversParentTypes['UserUserProfile']> = {
  organisation: Resolver<ResolversTypes['UserOrganisation'], ParentType, ContextType>;
  role: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['UserProfileStatus'], ParentType, ContextType>;
  userUuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersPageResolvers<ContextType = InvocationContext, ParentType extends ResolversParentTypes['UsersPage'] = ResolversParentTypes['UsersPage']> = {
  items: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = InvocationContext> = {
  Address: AddressResolvers<ContextType>;
  AssignedJob: AssignedJobResolvers<ContextType>;
  AssignedJobContractor: AssignedJobContractorResolvers<ContextType>;
  AssignedJobHandover: AssignedJobHandoverResolvers<ContextType>;
  AssignedJobsPage: AssignedJobsPageResolvers<ContextType>;
  AuditEntry: AuditEntryResolvers<ContextType>;
  AuditEntryPage: AuditEntryPageResolvers<ContextType>;
  AuditEntryUser: AuditEntryUserResolvers<ContextType>;
  AvailableDate: AvailableDateResolvers<ContextType>;
  Comment: CommentResolvers<ContextType>;
  CommentFile: CommentFileResolvers<ContextType>;
  CommentJob: CommentJobResolvers<ContextType>;
  CommentPage: CommentPageResolvers<ContextType>;
  CommentUser: CommentUserResolvers<ContextType>;
  ContractorHoliday: ContractorHolidayResolvers<ContextType>;
  ContractorHolidaysPage: ContractorHolidaysPageResolvers<ContextType>;
  ContractorNotificationSettings: ContractorNotificationSettingsResolvers<ContextType>;
  ContractorProduct: ContractorProductResolvers<ContextType>;
  ContractorProductImage: ContractorProductImageResolvers<ContextType>;
  ContractorProductPage: ContractorProductPageResolvers<ContextType>;
  ContractorProductPageMeta: ContractorProductPageMetaResolvers<ContextType>;
  ContractorSkill: ContractorSkillResolvers<ContextType>;
  Date: GraphQLScalarType;
  File: FileResolvers<ContextType>;
  FileOwner: FileOwnerResolvers<ContextType>;
  FilePage: FilePageResolvers<ContextType>;
  Invoice: InvoiceResolvers<ContextType>;
  InvoicesPage: InvoicesPageResolvers<ContextType>;
  Job: JobResolvers<ContextType>;
  JobAddress: JobAddressResolvers<ContextType>;
  JobAudit: JobAuditResolvers<ContextType>;
  JobAuditor: JobAuditorResolvers<ContextType>;
  JobContractor: JobContractorResolvers<ContextType>;
  JobContractorPotential: JobContractorPotentialResolvers<ContextType>;
  JobContractorUser: JobContractorUserResolvers<ContextType>;
  JobCustomer: JobCustomerResolvers<ContextType>;
  JobHandover: JobHandoverResolvers<ContextType>;
  JobPartner: JobPartnerResolvers<ContextType>;
  JobProduct: JobProductResolvers<ContextType>;
  JobProductFile: JobProductFileResolvers<ContextType>;
  JobProductProduct: JobProductProductResolvers<ContextType>;
  JobSkill: JobSkillResolvers<ContextType>;
  JobSurvey: JobSurveyResolvers<ContextType>;
  JobSurveyFile: JobSurveyFileResolvers<ContextType>;
  JobsPage: JobsPageResolvers<ContextType>;
  Mutation: MutationResolvers<ContextType>;
  Notification: NotificationResolvers<ContextType>;
  NotificationsPage: NotificationsPageResolvers<ContextType>;
  Organisation: OrganisationResolvers<ContextType>;
  OrganisationAddress: OrganisationAddressResolvers<ContextType>;
  OrganisationPage: OrganisationPageResolvers<ContextType>;
  PaginationResponse: PaginationResponseResolvers<ContextType>;
  Partner: PartnerResolvers<ContextType>;
  PartnerSkill: PartnerSkillResolvers<ContextType>;
  PartnerSkillPage: PartnerSkillPageResolvers<ContextType>;
  PartnerSla: PartnerSlaResolvers<ContextType>;
  PartnersPage: PartnersPageResolvers<ContextType>;
  Profile: ProfileResolvers<ContextType>;
  PutSignedFileUrl: PutSignedFileUrlResolvers<ContextType>;
  Query: QueryResolvers<ContextType>;
  Roof: RoofResolvers<ContextType>;
  RoofArray: RoofArrayResolvers<ContextType>;
  RoofScaffolding: RoofScaffoldingResolvers<ContextType>;
  SLA: SlaResolvers<ContextType>;
  SLAPage: SlaPageResolvers<ContextType>;
  Scheme: SchemeResolvers<ContextType>;
  SchemesPage: SchemesPageResolvers<ContextType>;
  Setting: SettingResolvers<ContextType>;
  SettingPage: SettingPageResolvers<ContextType>;
  Skill: SkillResolvers<ContextType>;
  SkillsPage: SkillsPageResolvers<ContextType>;
  Slot: SlotResolvers<ContextType>;
  SlotAllocationMap: SlotAllocationMapResolvers<ContextType>;
  SlotJob: SlotJobResolvers<ContextType>;
  SlotJobContractor: SlotJobContractorResolvers<ContextType>;
  SlotJobCustomer: SlotJobCustomerResolvers<ContextType>;
  SlotPage: SlotPageResolvers<ContextType>;
  Survey: SurveyResolvers<ContextType>;
  Task: TaskResolvers<ContextType>;
  TaskFile: TaskFileResolvers<ContextType>;
  TaskJob: TaskJobResolvers<ContextType>;
  TaskUser: TaskUserResolvers<ContextType>;
  TasksPage: TasksPageResolvers<ContextType>;
  Ticket: TicketResolvers<ContextType>;
  TicketCustomer: TicketCustomerResolvers<ContextType>;
  TicketsPage: TicketsPageResolvers<ContextType>;
  Token: TokenResolvers<ContextType>;
  User: UserResolvers<ContextType>;
  UserContractorProfile: UserContractorProfileResolvers<ContextType>;
  UserCustomerPartner: UserCustomerPartnerResolvers<ContextType>;
  UserCustomerProfile: UserCustomerProfileResolvers<ContextType>;
  UserOrganisation: UserOrganisationResolvers<ContextType>;
  UserUserProfile: UserUserProfileResolvers<ContextType>;
  UsersPage: UsersPageResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = InvocationContext> = {
  cacheControl: CacheControlDirectiveResolver<any, any, ContextType>;
};

export type UpdateSlotForDeletionMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type UpdateSlotForDeletionMutation = { __typename?: 'Mutation', deleteSlot: boolean };

export type SlotCountQueryVariables = Exact<{
  input: SlotCountInput;
}>;


export type SlotCountQuery = { __typename?: 'Query', slotCount: string };

export type UpsertCommentMutationVariables = Exact<{
  input: UpsertCommentInput;
}>;


export type UpsertCommentMutation = { __typename?: 'Mutation', upsertComment: { __typename?: 'Comment', uuid: string, text: string, createdAt: string, user: { __typename?: 'CommentUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null }, files: Array<{ __typename?: 'CommentFile', uuid: string, src: string, type: string, name: string }> | null, job: { __typename?: 'CommentJob', uuid: string, displayLink: string } | null } };

export type IndexCommentsQueryVariables = Exact<{
  filter: IndexCommentsFilter;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexCommentsQuery = { __typename?: 'Query', indexComments: { __typename?: 'CommentPage', items: Array<{ __typename?: 'Comment', uuid: string, text: string, createdAt: string, user: { __typename?: 'CommentUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null }, files: Array<{ __typename?: 'CommentFile', uuid: string, src: string, type: string, name: string }> | null, job: { __typename?: 'CommentJob', uuid: string, displayLink: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type DeleteCommentMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: boolean };

export type IndexFilesQueryVariables = Exact<{
  filters: IndexFilesFilter;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexFilesQuery = { __typename?: 'Query', indexFiles: { __typename?: 'FilePage', items: Array<{ __typename?: 'File', uuid: string, key: string, name: string, mimeType: string, size: number, src: string, downloadSrc: string, createdAt: string, owner: { __typename?: 'FileOwner', firstName: string, lastName: string, avatarSrc: string | null } | null }>, pagination: { __typename?: 'PaginationResponse', total: number, perPage: number, lastPage: number } | null } };

export type IndexFilesForCustomerQueryVariables = Exact<{
  filters: IndexFilesFilter;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexFilesForCustomerQuery = { __typename?: 'Query', indexFiles: { __typename?: 'FilePage', items: Array<{ __typename?: 'File', uuid: string, key: string, name: string, mimeType: string, size: number, src: string, downloadSrc: string, createdAt: string, targetType: FileTargetType | null, targetUuid: string | null, owner: { __typename?: 'FileOwner', firstName: string, lastName: string, avatarSrc: string | null } | null }>, pagination: { __typename?: 'PaginationResponse', total: number, perPage: number, lastPage: number } | null } };

export type IndexJobsForDropdownQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
}>;


export type IndexJobsForDropdownQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, displayLink: string }> } };

export type ReadJobForSearchQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadJobForSearchQuery = { __typename?: 'Query', readJob: { __typename?: 'Job', uuid: string, displayLink: string } };

export type CreateJobMutationVariables = Exact<{
  input: CreateJobInput;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob: { __typename?: 'Job', uuid: string } };

export type SearchForAvailableDatesAddJobQueryVariables = Exact<{
  input: SearchDatesForBooking;
}>;


export type SearchForAvailableDatesAddJobQuery = { __typename?: 'Query', searchDatesForRebook: Array<{ __typename?: 'AvailableDate', slotDate: string, displayDate: string }> };

export type CreatePartnerMutationVariables = Exact<{
  input: CreatePartnerInput;
}>;


export type CreatePartnerMutation = { __typename?: 'Mutation', createPartner: { __typename?: 'Partner', uuid: string } };

export type CreateSlotMutationVariables = Exact<{
  input: CreateSlotInput;
}>;


export type CreateSlotMutation = { __typename?: 'Mutation', createSlot: { __typename?: 'Slot', uuid: string, schemeName: string | null, isInstallationSelected: boolean, isRemedialSelected: boolean, isBatterySelected: boolean } };

export type UpsertTaskInAddTaskMutationVariables = Exact<{
  input: TaskUpsertInput;
}>;


export type UpsertTaskInAddTaskMutation = { __typename?: 'Mutation', upsertTask: { __typename?: 'Task', uuid: string, title: string, description: string | null, status: TaskStatus, dueDate: string | null, createdAt: string, assignee: { __typename?: 'TaskUser', uuid: string, avatarSrc: string | null, firstName: string, lastName: string } | null, creator: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string } | null, customer: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string, partnerUuid: string | null } | null, job: { __typename?: 'TaskJob', uuid: string, type: JobType, displayName: string } | null, files: Array<{ __typename?: 'TaskFile', uuid: string, name: string, src: string, type: string }> } };

export type CreateUserUserProfileMutationVariables = Exact<{
  input: CreateUserUserProfileInput;
}>;


export type CreateUserUserProfileMutation = { __typename?: 'Mutation', createUserUserProfile: { __typename?: 'UserUserProfile', userUuid: string } };

export type UpdateUserUserProfileMutationVariables = Exact<{
  input: UpdateUserUserProfileInput;
}>;


export type UpdateUserUserProfileMutation = { __typename?: 'Mutation', updateUserUserProfile: { __typename?: 'UserUserProfile', userUuid: string } };

export type CreateContractorProfileMutationVariables = Exact<{
  input: CreateUserContractorProfileInput;
}>;


export type CreateContractorProfileMutation = { __typename?: 'Mutation', createUserContractorProfile: { __typename?: 'UserContractorProfile', userUuid: string } };

export type CreateCustomerProfileMutationVariables = Exact<{
  input: CreateUserCustomerProfileInput;
}>;


export type CreateCustomerProfileMutation = { __typename?: 'Mutation', createUserCustomerProfile: { __typename?: 'UserCustomerProfile', userUuid: string } };

export type IndexContractorsForAssignmentsQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexContractorsForAssignmentsQuery = { __typename?: 'Query', indexContractorsForJob: Array<{ __typename?: 'JobContractor', uuid: string, isElectrician: boolean, isRoofer: boolean, distance: number, canBeElectrician: boolean, canBeRoofer: boolean, datesRequired: Array<string>, contractorProfileUuid: string, user: { __typename?: 'JobContractorUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } }> };

export type IndexPotentialContractorsForJobQueryVariables = Exact<{
  filters: IndexPotentialContractorsForJobFilter;
}>;


export type IndexPotentialContractorsForJobQuery = { __typename?: 'Query', indexPotentialContractorsForJob: Array<{ __typename?: 'JobContractorPotential', userUuid: string, contractorProfileUuid: string, firstName: string, lastName: string, avatarSrc: string | null, isElectrician: boolean, isRoofer: boolean, canBeElectrician: boolean, canBeRoofer: boolean, distance: number }> };

export type AttachJobContractorsMutationVariables = Exact<{
  input: AttachJobContractorsInput;
}>;


export type AttachJobContractorsMutation = { __typename?: 'Mutation', attachJobContractors: boolean };

export type GetAuditEntriesForModalQueryVariables = Exact<{
  filters: IndexAuditEntriesInput;
  pagination: PaginationInput;
}>;


export type GetAuditEntriesForModalQuery = { __typename?: 'Query', indexAuditEntries: { __typename?: 'AuditEntryPage', items: Array<{ __typename?: 'AuditEntry', uuid: string, title: string, createdAt: string, targetType: TargetType, auditEntryUser: { __typename?: 'AuditEntryUser', firstName: string, lastName: string, avatarSrc: string | null, userType: UserProfile } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpdateJobAuditorsMutationVariables = Exact<{
  input: UpdateJobInput;
}>;


export type UpdateJobAuditorsMutation = { __typename?: 'Mutation', updateJob: { __typename?: 'Job', uuid: string } };

export type UpdateSlotMutationVariables = Exact<{
  input: UpdateSlotInput;
}>;


export type UpdateSlotMutation = { __typename?: 'Mutation', updateSlot: { __typename?: 'Slot', uuid: string, isInstallationSelected: boolean, isRemedialSelected: boolean, isBatterySelected: boolean } };

export type UpdateUserContractorProfileSkillsMutationVariables = Exact<{
  input: UpdateUserContractorProfileInput;
}>;


export type UpdateUserContractorProfileSkillsMutation = { __typename?: 'Mutation', updateUserContractorProfile: { __typename?: 'UserContractorProfile', uuid: string } };

export type UpdateAddressMutationVariables = Exact<{
  input: UpdateAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'Address', uuid: string } };

export type UpdateUserContractorProfileModalMutationVariables = Exact<{
  input: UpdateUserContractorProfileInput;
}>;


export type UpdateUserContractorProfileModalMutation = { __typename?: 'Mutation', updateUserContractorProfile: { __typename?: 'UserContractorProfile', uuid: string, userUuid: string, organisation: { __typename?: 'UserOrganisation', name: string } } };

export type UpdateUserContractorProfileAvailabilityMutationVariables = Exact<{
  input: UpdateUserContractorProfileInput;
}>;


export type UpdateUserContractorProfileAvailabilityMutation = { __typename?: 'Mutation', updateUserContractorProfile: { __typename?: 'UserContractorProfile', uuid: string, availability: string } };

export type UpdateUserCustomerProfileMutationVariables = Exact<{
  input: UpdateUserCustomerProfileInput;
}>;


export type UpdateUserCustomerProfileMutation = { __typename?: 'Mutation', updateUserCustomerProfile: { __typename?: 'UserCustomerProfile', userUuid: string } };

export type UpdatePartnerMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;


export type UpdatePartnerMutation = { __typename?: 'Mutation', updatePartner: { __typename?: 'Partner', uuid: string, name: string, telephone: string | null, website: string | null, email: string, sla: { __typename?: 'PartnerSla', uuid: string, name: string } } };

export type UpdatePartnerSkillsMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;


export type UpdatePartnerSkillsMutation = { __typename?: 'Mutation', updatePartner: { __typename?: 'Partner', uuid: string } };

export type UpdatePostJobAuditMutationVariables = Exact<{
  input: UpdateJobAuditInput;
}>;


export type UpdatePostJobAuditMutation = { __typename?: 'Mutation', updateJobAudit: { __typename?: 'JobAudit', uuid: string, notes: string | null, hasPassed: boolean, auditType: AuditType, submittedAt: string | null, auditor: { __typename?: 'JobAuditor', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } | null } };

export type IndexJobProductsForPostAuditQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexJobProductsForPostAuditQuery = { __typename?: 'Query', indexProductsForJob: Array<{ __typename?: 'JobProduct', uuid: string, quantity: number, isInstalled: boolean, price: string, product: { __typename?: 'JobProductProduct', name: string, responsibleContractorType: ResponsibleContractorType | null }, image: { __typename?: 'JobProductFile', uuid: string | null, key: string, name: string, src: string, size: number, mimeType: string } | null }> };

export type UpdateJobAuditMutationVariables = Exact<{
  input: UpdateJobAuditInput;
}>;


export type UpdateJobAuditMutation = { __typename?: 'Mutation', updateJobAudit: { __typename?: 'JobAudit', uuid: string, notes: string | null, hasPassed: boolean, auditType: AuditType, submittedAt: string | null, auditor: { __typename?: 'JobAuditor', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } | null } };

export type UpdateSlotForReassignmentMutationVariables = Exact<{
  input: UpdateSlotInput;
}>;


export type UpdateSlotForReassignmentMutation = { __typename?: 'Mutation', updateSlot: { __typename?: 'Slot', uuid: string } };

export type SearchForAvailableDatesQueryVariables = Exact<{
  input: SearchDatesForBooking;
}>;


export type SearchForAvailableDatesQuery = { __typename?: 'Query', searchDatesForRebook: Array<{ __typename?: 'AvailableDate', slotDate: string, displayDate: string }> };

export type RebookJobMutationVariables = Exact<{
  input: RebookJobInput;
}>;


export type RebookJobMutation = { __typename?: 'Mutation', rebookJob: { __typename?: 'Job', uuid: string, displayDate: string } };

export type CommitFilesMutationVariables = Exact<{
  input: CommitFilesInput;
}>;


export type CommitFilesMutation = { __typename?: 'Mutation', commitFiles: boolean };

export type UpsertAuditEntryCallMutationVariables = Exact<{
  input: UpsertAuditEntryInput;
}>;


export type UpsertAuditEntryCallMutation = { __typename?: 'Mutation', upsertAuditEntry: { __typename?: 'AuditEntry', uuid: string } };

export type CreateInvoiceMutationVariables = Exact<{
  input: InvoiceCreateInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: { __typename?: 'Invoice', uuid: string, status: InvoiceStatus, xeroReference: string, xeroLink: string, notes: string | null, amount: number, dueDate: string, createdAt: string } };

export type UpdateInvoiceMutationVariables = Exact<{
  input: InvoiceUpdateInput;
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice: { __typename?: 'Invoice', uuid: string, status: InvoiceStatus, xeroReference: string, xeroLink: string, notes: string | null, amount: number, dueDate: string, createdAt: string } };

export type UpsertOrganisationMutationVariables = Exact<{
  input: UpsertOrganisationInput;
}>;


export type UpsertOrganisationMutation = { __typename?: 'Mutation', upsertOrganisation: { __typename?: 'Organisation', uuid: string } | null };

export type CreateRoofMutationVariables = Exact<{
  input: CreateRoofInput;
}>;


export type CreateRoofMutation = { __typename?: 'Mutation', createRoof: { __typename?: 'Roof', uuid: string } };

export type UpdateRoofMutationVariables = Exact<{
  input: UpdateRoofInput;
}>;


export type UpdateRoofMutation = { __typename?: 'Mutation', updateRoof: { __typename?: 'Roof', uuid: string } };

export type UpsertSchemeMutationVariables = Exact<{
  input: UpsertSchemeInput;
}>;


export type UpsertSchemeMutation = { __typename?: 'Mutation', upsertScheme: { __typename?: 'Scheme', uuid: string, name: string, partnerUuid: string, startDate: string, endDate: string, schemeType: SchemeType, formattedStartDate: string, formattedEndDate: string, status: SchemeStatus, partnerName: string } };

export type UpsertSkillMutationVariables = Exact<{
  input: UpsertSkillInput;
}>;


export type UpsertSkillMutation = { __typename?: 'Mutation', upsertSkill: { __typename?: 'Skill', uuid: string } };

export type UpsertSlaMutationVariables = Exact<{
  input: UpsertSlaInput;
}>;


export type UpsertSlaMutation = { __typename?: 'Mutation', upsertSLA: { __typename?: 'SLA', uuid: string } };

export type IndexSettingsForNotificationSettingsQueryVariables = Exact<{
  filter: IndexSettingsFilter;
}>;


export type IndexSettingsForNotificationSettingsQuery = { __typename?: 'Query', indexSettings: { __typename?: 'SettingPage', items: Array<{ __typename?: 'Setting', uuid: string, userUuid: string, key: string, value: string, kind: SettingKind, description: string }> } };

export type UpdateSettingMutationVariables = Exact<{
  input: UpdateSettingInput;
}>;


export type UpdateSettingMutation = { __typename?: 'Mutation', updateSetting: { __typename?: 'Setting', uuid: string } };

export type IndexNotificationsForNotificationsFeedQueryVariables = Exact<{
  filters: InputMaybe<IndexNotificationsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexNotificationsForNotificationsFeedQuery = { __typename?: 'Query', indexNotifications: { __typename?: 'NotificationsPage', items: Array<{ __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string, targetType: NotificationTargetType, targetUuid: string, targetUserUuid: string }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type ViewNotificationForNotificationsFeedMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ViewNotificationForNotificationsFeedMutation = { __typename?: 'Mutation', viewNotification: { __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string, targetType: NotificationTargetType, targetUuid: string, targetUserUuid: string } };

export type DismissNotificationForNotificationsFeedMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DismissNotificationForNotificationsFeedMutation = { __typename?: 'Mutation', dismissNotification: { __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string, targetType: NotificationTargetType, targetUuid: string, targetUserUuid: string } };

export type DismissAllNotificationForNotificationsFeedMutationVariables = Exact<{ [key: string]: never; }>;


export type DismissAllNotificationForNotificationsFeedMutation = { __typename?: 'Mutation', dismissAllNotifications: boolean };

export type IndexOrganisationsQueryVariables = Exact<{
  filters: IndexOrganisationsFilterInput;
}>;


export type IndexOrganisationsQuery = { __typename?: 'Query', indexOrganisations: { __typename?: 'OrganisationPage', items: Array<{ __typename?: 'Organisation', name: string, uuid: string }> } };

export type IndexPartnersQueryVariables = Exact<{
  filters: IndexPartnersFilterInput;
}>;


export type IndexPartnersQuery = { __typename?: 'Query', indexPartners: { __typename?: 'PartnersPage', items: Array<{ __typename?: 'Partner', name: string, uuid: string }> } };

export type IndexSlAsForDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexSlAsForDropdownQuery = { __typename?: 'Query', indexSLAs: { __typename?: 'SLAPage', items: Array<{ __typename?: 'SLA', name: string, uuid: string, partnerCount: number, scaffoldingSetUpThreshold: number, scaffoldingStrikeThreshold: number }> } };

export type IndexSchemesQueryVariables = Exact<{
  filters: InputMaybe<IndexSchemesFilterInput>;
}>;


export type IndexSchemesQuery = { __typename?: 'Query', indexSchemes: { __typename?: 'SchemesPage', items: Array<{ __typename?: 'Scheme', name: string, uuid: string }> } };

export type IndexSkillsQueryVariables = Exact<{
  filters: IndexSkillsFilterInput;
}>;


export type IndexSkillsQuery = { __typename?: 'Query', indexSkills: { __typename?: 'SkillsPage', items: Array<{ __typename?: 'Skill', name: string, uuid: string }> } };

export type ReadSkillForDropdownQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadSkillForDropdownQuery = { __typename?: 'Query', readSkill: { __typename?: 'Skill', name: string, uuid: string } };

export type IndexUsersForSearchQueryVariables = Exact<{
  filters: IndexUsersFilterInput;
}>;


export type IndexUsersForSearchQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', firstName: string, lastName: string, avatarSrc: string | null, uuid: string, profile: { __typename?: 'UserContractorProfile', uuid: string } | { __typename?: 'UserCustomerProfile', uuid: string } | { __typename?: 'UserUserProfile', uuid: string } }> } };

export type ActiveUserForSearchQueryVariables = Exact<{
  input: ReadUserInput;
}>;


export type ActiveUserForSearchQuery = { __typename?: 'Query', readUser: { __typename?: 'User', firstName: string, lastName: string, avatarSrc: string | null, uuid: string } };

export type IndexSchemesForCalendarFiltersQueryVariables = Exact<{
  filters: InputMaybe<IndexSchemesFilterInput>;
}>;


export type IndexSchemesForCalendarFiltersQuery = { __typename?: 'Query', indexSchemes: { __typename?: 'SchemesPage', items: Array<{ __typename?: 'Scheme', name: string, uuid: string }> } };

export type IndexContractorsForCalendarFiltersQueryVariables = Exact<{
  filters: InputMaybe<IndexUsersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexContractorsForCalendarFiltersQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, firstName: string, lastName: string, profile: { __typename?: 'UserContractorProfile', uuid: string } | { __typename?: 'UserCustomerProfile' } | { __typename?: 'UserUserProfile' } }> } };

export type IndexOrganisationsForOrganisationsViewQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexOrganisationsForOrganisationsViewQuery = { __typename?: 'Query', indexOrganisations: { __typename?: 'OrganisationPage', items: Array<{ __typename?: 'Organisation', uuid: string, name: string, email: string, displayAddress: string, telephone: string | null, website: string | null, canDelete: boolean, address: { __typename?: 'OrganisationAddress', line1: string, line2: string | null, city: string | null, postcode: string, latitude: number, longitude: number } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type DeleteOrganisationMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteOrganisationMutation = { __typename?: 'Mutation', deleteOrganisation: boolean };

export type IndexSlAsQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexSlAsQuery = { __typename?: 'Query', indexSLAs: { __typename?: 'SLAPage', items: Array<{ __typename?: 'SLA', name: string, uuid: string, partnerCount: number, scaffoldingSetUpThreshold: number, scaffoldingStrikeThreshold: number }> } };

export type DeleteSlaMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteSlaMutation = { __typename?: 'Mutation', deleteSLA: boolean };

export type IndexSkillsForConfigurationQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexSkillsForConfigurationQuery = { __typename?: 'Query', indexSkills: { __typename?: 'SkillsPage', items: Array<{ __typename?: 'Skill', uuid: string, name: string, jobCount: number, partnerCount: number, contractorCount: number }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type DeleteSkillMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteSkillMutation = { __typename?: 'Mutation', deleteSkill: boolean };

export type IndexUsersForContractorsScreenQueryVariables = Exact<{
  filters: IndexUsersFilterInput;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexUsersForContractorsScreenQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, firstName: string, lastName: string, avatarSrc: string | null, lastLogin: string | null, profile: { __typename: 'UserContractorProfile', isElectrician: boolean, isRoofer: boolean, completionRate: number, jobCount: number, organisation: { __typename?: 'UserOrganisation', name: string } } | { __typename: 'UserCustomerProfile' } | { __typename: 'UserUserProfile' } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexUsersForCustomersScreenQueryVariables = Exact<{
  filters: InputMaybe<IndexUsersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexUsersForCustomersScreenQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, schemeName: string | null, firstName: string, lastName: string, avatarSrc: string | null, lastLogin: string | null, address: { __typename?: 'Address', postcode: string } | null, profile: { __typename: 'UserContractorProfile' } | { __typename: 'UserCustomerProfile', uuid: string, partner: { __typename?: 'UserCustomerPartner', name: string } } | { __typename: 'UserUserProfile' } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexUsersForContactsScreenQueryVariables = Exact<{
  filters: IndexUsersFilterInput;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexUsersForContactsScreenQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, firstName: string, lastName: string, avatarSrc: string | null, lastLogin: string | null, profile: { __typename: 'UserContractorProfile' } | { __typename: 'UserCustomerProfile' } | { __typename: 'UserUserProfile', role: UserRole, organisation: { __typename?: 'UserOrganisation', name: string } } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexAuditPendingJobsForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexAuditPendingJobsForSectionQuery = { __typename?: 'Query', auditPendingJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, status: JobStatus, customer: { __typename?: 'JobCustomer', userUuid: string, firstName: string, lastName: string, avatarSrc: string | null }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexBlockedJobsForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexBlockedJobsForSectionQuery = { __typename?: 'Query', blockedJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, status: JobStatus, customer: { __typename?: 'JobCustomer', userUuid: string, firstName: string, lastName: string, avatarSrc: string | null }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexTodaysJobsForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexTodaysJobsForSectionQuery = { __typename?: 'Query', todaysJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, status: JobStatus, customer: { __typename?: 'JobCustomer', userUuid: string, firstName: string, lastName: string, avatarSrc: string | null }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexUnassignedJobsForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexUnassignedJobsForSectionQuery = { __typename?: 'Query', unassignedJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, status: JobStatus, customer: { __typename?: 'JobCustomer', userUuid: string, firstName: string, lastName: string, avatarSrc: string | null }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type JobsWidgetCountsQueryVariables = Exact<{
  myAssignment: Scalars['Boolean']['input'];
}>;


export type JobsWidgetCountsQuery = { __typename?: 'Query', todaysJobCount: number, blockedJobCount: number, unassignedJobCount: number, auditPendingJobCount: number };

export type IndexAllTasksForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexAllTasksForSectionQuery = { __typename?: 'Query', allTasks: { __typename?: 'TasksPage', items: Array<{ __typename?: 'Task', uuid: string, title: string, status: TaskStatus, dueDate: string | null, assignee: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpsertTaskOnDashboardMutationVariables = Exact<{
  input: TaskUpsertInput;
}>;


export type UpsertTaskOnDashboardMutation = { __typename?: 'Mutation', upsertTask: { __typename?: 'Task', uuid: string } };

export type IndexUnassignedTasksForSectionQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
  myAssignment: Scalars['Boolean']['input'];
}>;


export type IndexUnassignedTasksForSectionQuery = { __typename?: 'Query', unassignedTasks: { __typename?: 'TasksPage', items: Array<{ __typename?: 'Task', uuid: string, title: string, status: TaskStatus, assignee: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type TaskWidgetCountsQueryVariables = Exact<{
  myAssignment: Scalars['Boolean']['input'];
}>;


export type TaskWidgetCountsQuery = { __typename?: 'Query', allTaskCount: number, unassignedTaskCount: number };

export type SaveFeedbackMutationVariables = Exact<{
  input: SaveJobFeedback;
}>;


export type SaveFeedbackMutation = { __typename?: 'Mutation', saveFeedback: boolean };

export type IndexJobContractorsQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexJobContractorsQuery = { __typename?: 'Query', indexContractorsForJob: Array<{ __typename?: 'JobContractor', uuid: string, displayDateRequired: string, isElectrician: boolean, isRoofer: boolean, distance: number, user: { __typename?: 'JobContractorUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } }> };

export type IndexProductsForJobTabQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexProductsForJobTabQuery = { __typename?: 'Query', indexProductsForJob: Array<{ __typename?: 'JobProduct', uuid: string, quantity: number, isInstalled: boolean, price: string, isBillable: boolean, product: { __typename?: 'JobProductProduct', name: string, responsibleContractorType: ResponsibleContractorType | null }, image: { __typename?: 'JobProductFile', uuid: string | null, key: string, name: string, src: string, size: number, mimeType: string } | null }> };

export type IndexJobHandoversQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexJobHandoversQuery = { __typename?: 'Query', indexHandoversForJob: Array<{ __typename?: 'JobHandover', uuid: string, type: JobHandoverType, displayDate: string, src: string }> };

export type IndexRoofsForJobQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexRoofsForJobQuery = { __typename?: 'Query', indexRoofsForJob: Array<{ __typename?: 'Roof', uuid: string, tile: RoofTile, scaffolding: { __typename?: 'RoofScaffolding', uuid: string, location: ScaffoldingLocation, locationArea: ScaffoldingLocationArea, locationOtherDescription: string | null, isBridgeRequired: boolean, isTowerRequired: boolean, height: number, width: number, notes: string | null } | null, arrays: Array<{ __typename?: 'RoofArray', uuid: string, panels: number, orientation: RoofArrayOrientation }> }> };

export type DeleteRoofMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteRoofMutation = { __typename?: 'Mutation', deleteRoof: boolean };

export type IndexTasksQueryVariables = Exact<{
  filters: InputMaybe<IndexTasksFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexTasksQuery = { __typename?: 'Query', indexTasks: { __typename?: 'TasksPage', items: Array<{ __typename?: 'Task', uuid: string, title: string, description: string | null, dueDate: string | null, status: TaskStatus, creator: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string } | null, assignee: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string } | null, files: Array<{ __typename?: 'TaskFile', uuid: string, src: string, type: string, name: string }> }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpdateTaskOnJobMutationVariables = Exact<{
  input: TaskUpsertInput;
}>;


export type UpdateTaskOnJobMutation = { __typename?: 'Mutation', upsertTask: { __typename?: 'Task', uuid: string } };

export type IndexJobAuditsQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexJobAuditsQuery = { __typename?: 'Query', indexAuditsForJob: Array<{ __typename?: 'JobAudit', uuid: string, notes: string | null, hasPassed: boolean, auditType: AuditType, submittedAt: string | null, auditor: { __typename?: 'JobAuditor', uuid: string, firstName: string, lastName: string, avatarSrc: string | null } | null }> };

export type IndexSkillsForJobsQueryVariables = Exact<{
  filters: IndexSkillsForJobsFilterInput;
}>;


export type IndexSkillsForJobsQuery = { __typename?: 'Query', indexSkillsForJobs: Array<{ __typename?: 'JobSkill', uuid: string, name: string, levelRequired: SkillLevel, trade: ContractorTrade }> };

export type UpdateJobMutationVariables = Exact<{
  input: UpdateJobInput;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob: { __typename?: 'Job', status: JobStatus } };

export type IndexUsersForPartnersScreenQueryVariables = Exact<{
  filters: InputMaybe<IndexUsersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexUsersForPartnersScreenQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, schemeName: string | null, firstName: string, lastName: string, avatarSrc: string | null, lastLogin: string | null, address: { __typename?: 'Address', postcode: string } | null, profile: { __typename: 'UserContractorProfile' } | { __typename: 'UserCustomerProfile', uuid: string, partner: { __typename?: 'UserCustomerPartner', name: string } } | { __typename: 'UserUserProfile' } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexJobsForPartnerPageQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexJobsForPartnerPageQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, type: JobType, status: JobStatus, difficulty: JobDifficulty, startDate: string | null, customer: { __typename?: 'JobCustomer', firstName: string, lastName: string }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexSchemesForPartnersPageQueryVariables = Exact<{
  filters: InputMaybe<IndexSchemesFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexSchemesForPartnersPageQuery = { __typename?: 'Query', indexSchemes: { __typename?: 'SchemesPage', items: Array<{ __typename?: 'Scheme', uuid: string, name: string, partnerUuid: string, startDate: string, endDate: string, schemeType: SchemeType, formattedStartDate: string, formattedEndDate: string, status: SchemeStatus, partnerName: string }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexSkillsForPartnerQueryVariables = Exact<{
  partnerUuid: Scalars['String']['input'];
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexSkillsForPartnerQuery = { __typename?: 'Query', indexSkillsForPartners: { __typename?: 'PartnerSkillPage', items: Array<{ __typename?: 'PartnerSkill', uuid: string, name: string, levelRequired: SkillLevel, trade: ContractorTrade }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexPartnersForOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexPartnersForOverviewQuery = { __typename?: 'Query', indexPartners: { __typename?: 'PartnersPage', items: Array<{ __typename?: 'Partner', uuid: string, name: string, jobCount: number, schemeCount: number, ticketCount: number, userCount: number, customerCount: number }> } };

export type IndexPartnersForSlotsQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexPartnersForSlotsQuery = { __typename?: 'Query', indexPartners: { __typename?: 'PartnersPage', items: Array<{ __typename?: 'Partner', uuid: string, name: string, slotAllocationType: SlotAllocationType, slotAllocationAmount: number }> } };

export type UpdatePartnerSlotsMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;


export type UpdatePartnerSlotsMutation = { __typename?: 'Mutation', updatePartner: { __typename?: 'Partner', uuid: string, slotAllocationType: SlotAllocationType, slotAllocationAmount: number } };

export type EstimateSlotAllocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type EstimateSlotAllocationsQuery = { __typename?: 'Query', estimateSlotAllocations: Array<{ __typename?: 'SlotAllocationMap', partnerUuid: string, installationAllocated: number }> };

export type UpdateSchemeMutationVariables = Exact<{
  input: UpdateSchemeInput;
}>;


export type UpdateSchemeMutation = { __typename?: 'Mutation', updateScheme: { __typename?: 'Scheme', uuid: string } };

export type IndexJobsForSchemePageQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexJobsForSchemePageQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, type: JobType, status: JobStatus, difficulty: JobDifficulty, startDate: string | null, customer: { __typename?: 'JobCustomer', firstName: string, lastName: string }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexSchemesForSchemesPageQueryVariables = Exact<{
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexSchemesForSchemesPageQuery = { __typename?: 'Query', indexSchemes: { __typename?: 'SchemesPage', items: Array<{ __typename?: 'Scheme', uuid: string, name: string, partnerUuid: string, startDate: string, endDate: string, schemeType: SchemeType, formattedStartDate: string, formattedEndDate: string, status: SchemeStatus, partnerName: string }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpsertTaskInTaskMutationVariables = Exact<{
  input: TaskUpsertInput;
}>;


export type UpsertTaskInTaskMutation = { __typename?: 'Mutation', upsertTask: { __typename?: 'Task', uuid: string } };

export type UpsertTaskMutationVariables = Exact<{
  input: TaskUpsertInput;
}>;


export type UpsertTaskMutation = { __typename?: 'Mutation', upsertTask: { __typename?: 'Task', uuid: string } };

export type IndexTasksForTasksQueryVariables = Exact<{
  filters: InputMaybe<IndexTasksFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexTasksForTasksQuery = { __typename?: 'Query', indexTasks: { __typename?: 'TasksPage', items: Array<{ __typename?: 'Task', uuid: string, title: string, createdAt: string, dueDate: string | null, status: TaskStatus, assignee: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number, total: number } } };

export type IndexUsersForTasksFiltersQueryVariables = Exact<{
  filters: InputMaybe<IndexUsersFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexUsersForTasksFiltersQuery = { __typename?: 'Query', indexUsers: { __typename?: 'UsersPage', items: Array<{ __typename?: 'User', uuid: string, firstName: string, lastName: string, profile: { __typename?: 'UserContractorProfile' } | { __typename?: 'UserCustomerProfile' } | { __typename?: 'UserUserProfile', uuid: string } }> } };

export type IndexInstalledProductsForContractorQueryVariables = Exact<{
  contractorProfileUuid: Scalars['String']['input'];
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexInstalledProductsForContractorQuery = { __typename?: 'Query', indexInstalledProductsForContractor: { __typename?: 'ContractorProductPage', meta: { __typename?: 'ContractorProductPageMeta', allTimeTotal: number }, items: Array<{ __typename?: 'ContractorProduct', uuid: string, name: string, customerFirstName: string, customerLastName: string, customerUserUuid: string, customerAvatarSrc: string | null, installationDate: string, totalCost: number, jobUuid: string, image: { __typename?: 'ContractorProductImage', src: string, mimeType: string, name: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexJobsForUserContractorProfileQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexJobsForUserContractorProfileQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, type: JobType, status: JobStatus, difficulty: JobDifficulty, startDate: string | null, customer: { __typename?: 'JobCustomer', firstName: string, lastName: string }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexSkillsForContractorsQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type IndexSkillsForContractorsQuery = { __typename?: 'Query', indexSkillsForContractors: Array<{ __typename?: 'ContractorSkill', uuid: string, name: string, levelRequired: SkillLevel, trade: ContractorTrade }> };

export type UpdateUserContractorProfileStatusMutationVariables = Exact<{
  input: UpdateUserContractorProfileInput;
}>;


export type UpdateUserContractorProfileStatusMutation = { __typename?: 'Mutation', updateUserContractorProfile: { __typename?: 'UserContractorProfile', userUuid: string } };

export type IndexAuditEntriesForCallsQueryVariables = Exact<{
  filters: IndexAuditEntriesInput;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexAuditEntriesForCallsQuery = { __typename?: 'Query', indexAuditEntries: { __typename?: 'AuditEntryPage', items: Array<{ __typename?: 'AuditEntry', uuid: string, title: string, notes: string | null, createdAt: string, auditEntryUser: { __typename?: 'AuditEntryUser', firstName: string, lastName: string, avatarSrc: string | null } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexInvoicesForCustomerQueryVariables = Exact<{
  customerProfileUuid: Scalars['String']['input'];
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexInvoicesForCustomerQuery = { __typename?: 'Query', indexInvoicesForCustomer: { __typename?: 'InvoicesPage', items: Array<{ __typename?: 'Invoice', uuid: string, status: InvoiceStatus, xeroReference: string, xeroLink: string, notes: string | null, amount: number, dueDate: string, createdAt: string }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexJobsForUserCustomerProfileQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
}>;


export type IndexJobsForUserCustomerProfileQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, type: JobType, status: JobStatus, difficulty: JobDifficulty, displayDate: string, schemeName: string, startDate: string | null, customer: { __typename?: 'JobCustomer', firstName: string, lastName: string }, partner: { __typename?: 'JobPartner', name: string }, address: { __typename?: 'JobAddress', line1: string, city: string | null, postcode: string } | null, contractors: Array<{ __typename?: 'AssignedJobContractor', firstName: string, lastName: string, avatarSrc: string | null }> | null }> } };

export type IndexTicketsForUserQueryVariables = Exact<{
  filters: InputMaybe<IndexTicketsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexTicketsForUserQuery = { __typename?: 'Query', indexTickets: { __typename?: 'TicketsPage', items: Array<{ __typename?: 'Ticket', uuid: string, title: string, createdAt: string, lastInteraction: number | null, status: TicketStatus, customer: { __typename?: 'TicketCustomer', firstName: string, lastName: string } }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpdateUserCustomerProfileStatusMutationVariables = Exact<{
  input: UpdateUserCustomerProfileInput;
}>;


export type UpdateUserCustomerProfileStatusMutation = { __typename?: 'Mutation', updateUserCustomerProfile: { __typename?: 'UserCustomerProfile', userUuid: string } };

export type IndexJobsForUserQueryVariables = Exact<{
  filters: InputMaybe<IndexJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexJobsForUserQuery = { __typename?: 'Query', indexJobs: { __typename?: 'JobsPage', items: Array<{ __typename?: 'Job', uuid: string, type: JobType, status: JobStatus, difficulty: JobDifficulty, startDate: string | null, customer: { __typename?: 'JobCustomer', firstName: string, lastName: string }, address: { __typename?: 'JobAddress', postcode: string } | null }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type IndexTasksForUserQueryVariables = Exact<{
  filters: InputMaybe<IndexTasksFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type IndexTasksForUserQuery = { __typename?: 'Query', indexTasks: { __typename?: 'TasksPage', items: Array<{ __typename?: 'Task', uuid: string, title: string, dueDate: string | null, createdAt: string, status: TaskStatus }>, pagination: { __typename?: 'PaginationResponse', lastPage: number } } };

export type UpdateUserUserProfileStatusMutationVariables = Exact<{
  input: UpdateUserUserProfileInput;
}>;


export type UpdateUserUserProfileStatusMutation = { __typename?: 'Mutation', updateUserUserProfile: { __typename?: 'UserUserProfile', userUuid: string } };

export type PutSignedAvatarPhotoUrlForProfileMutationVariables = Exact<{
  input: UploadAvatarUrlInput;
}>;


export type PutSignedAvatarPhotoUrlForProfileMutation = { __typename?: 'Mutation', putSignedAvatarPhotoUrl: { __typename?: 'PutSignedFileUrl', url: string, key: string } };

export type CreateAvatarFileForProfileMutationVariables = Exact<{
  input: CreateAvatarFileInput;
}>;


export type CreateAvatarFileForProfileMutation = { __typename?: 'Mutation', createAvatarFile: { __typename?: 'File', uuid: string, src: string } };

export type ReadJobQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadJobQuery = { __typename?: 'Query', readJob: { __typename?: 'Job', uuid: string, difficulty: JobDifficulty, schemeName: string, type: JobType, status: JobStatus, displayDate: string, operatorUuid: string | null, solarPanelCount: number, revisedSolarPanelCount: number | null, duration: number, dates: Array<string>, partner: { __typename?: 'JobPartner', uuid: string, name: string }, customer: { __typename?: 'JobCustomer', uuid: string, userUuid: string, email: string, firstName: string, lastName: string, phoneNumber: string }, address: { __typename?: 'JobAddress', line1: string, line2: string | null, city: string | null, postcode: string } | null, survey: { __typename?: 'JobSurvey', uuid: string } } };

export type ReadSchemeQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadSchemeQuery = { __typename?: 'Query', readScheme: { __typename?: 'Scheme', uuid: string, name: string, partnerUuid: string, startDate: string, endDate: string, schemeType: SchemeType, formattedStartDate: string, formattedEndDate: string, status: SchemeStatus, partnerName: string, operatorUuid: string | null, preAuditorUuid: string | null, postAuditorUuid: string | null, electricalAuditorUuid: string | null, roofingAuditorUuid: string | null } };

export type ReadTaskQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadTaskQuery = { __typename?: 'Query', readTask: { __typename?: 'Task', uuid: string, title: string, description: string | null, status: TaskStatus, dueDate: string | null, createdAt: string, assignee: { __typename?: 'TaskUser', uuid: string, avatarSrc: string | null, firstName: string, lastName: string } | null, creator: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string } | null, customer: { __typename?: 'TaskUser', uuid: string, firstName: string, lastName: string, partnerUuid: string | null } | null, job: { __typename?: 'TaskJob', uuid: string, type: JobType, displayName: string } | null, files: Array<{ __typename?: 'TaskFile', uuid: string, name: string, src: string, type: string }> } };

export type CommentFragment = { __typename?: 'Comment', uuid: string, text: string, createdAt: string, user: { __typename?: 'CommentUser', uuid: string, firstName: string, lastName: string, avatarSrc: string | null }, files: Array<{ __typename?: 'CommentFile', uuid: string, src: string, type: string, name: string }> | null, job: { __typename?: 'CommentJob', uuid: string, displayLink: string } | null };

export type GetSignedFileUrlMutationVariables = Exact<{
  input: SignedUrlInput;
}>;


export type GetSignedFileUrlMutation = { __typename?: 'Mutation', getSignedFileUrl: string };

export type DeleteFileMutationVariables = Exact<{
  input: DeleteFileInput;
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: boolean };

export type PutSignedFileUrlMutationVariables = Exact<{
  input: UploadUrlInput;
}>;


export type PutSignedFileUrlMutation = { __typename?: 'Mutation', putSignedFileUrl: { __typename?: 'PutSignedFileUrl', url: string, key: string } };

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;


export type CreateFileMutation = { __typename?: 'Mutation', createFile: { __typename?: 'File', uuid: string, key: string, name: string, mimeType: string, size: number } };

export type InvoiceFragment = { __typename?: 'Invoice', uuid: string, status: InvoiceStatus, xeroReference: string, xeroLink: string, notes: string | null, amount: number, dueDate: string, createdAt: string };

export type NotificationFragment = { __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string, targetType: NotificationTargetType, targetUuid: string, targetUserUuid: string };

export type ReadPartnerQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type ReadPartnerQuery = { __typename?: 'Query', readPartner: { __typename?: 'Partner', uuid: string, name: string, telephone: string | null, website: string | null, email: string, sla: { __typename?: 'PartnerSla', uuid: string, name: string } } };

export type SlotFragment = { __typename?: 'Slot', uuid: string, isElectricianAvailable: boolean, isRooferAvailable: boolean, isInstallationSelected: boolean, isRemedialSelected: boolean, isBatterySelected: boolean, schemeName: string | null, job: { __typename?: 'SlotJob', uuid: string, status: JobStatus, type: JobType, atRisk: boolean, difficulty: JobDifficulty, displayInstallationDate: string, contractors: Array<{ __typename?: 'SlotJobContractor', firstName: string, lastName: string, avatarSrc: string | null }>, customer: { __typename?: 'SlotJobCustomer', firstName: string, lastName: string, line1: string, city: string | null, postcode: string | null, schemeName: string | null } } | null };

export type IndexSlotsQueryVariables = Exact<{
  input: IndexSlotsInput;
}>;


export type IndexSlotsQuery = { __typename?: 'Query', indexSlots: { __typename?: 'SlotPage', items: Array<{ __typename?: 'Slot', uuid: string, isElectricianAvailable: boolean, isRooferAvailable: boolean, isInstallationSelected: boolean, isRemedialSelected: boolean, isBatterySelected: boolean, schemeName: string | null, job: { __typename?: 'SlotJob', uuid: string, status: JobStatus, type: JobType, atRisk: boolean, difficulty: JobDifficulty, displayInstallationDate: string, contractors: Array<{ __typename?: 'SlotJobContractor', firstName: string, lastName: string, avatarSrc: string | null }>, customer: { __typename?: 'SlotJobCustomer', firstName: string, lastName: string, line1: string, city: string | null, postcode: string | null, schemeName: string | null } } | null }> } };

export type ReadUserQueryVariables = Exact<{
  input: ReadUserInput;
}>;


export type ReadUserQuery = { __typename?: 'Query', readUser: { __typename?: 'User', uuid: string, firstName: string, lastName: string, email: string, avatarSrc: string | null, phoneNumber: string | null, createdAt: string, schemeName: string | null, address: { __typename?: 'Address', uuid: string, line1: string, line2: string | null, city: string | null, county: string | null, postcode: string, latitude: number | null, longitude: number | null } | null, profile: { __typename: 'UserContractorProfile', uuid: string, status: UserProfileStatus, startDate: string, availability: string, isElectrician: boolean, isRoofer: boolean, completionRate: number, servicingRadius: number, organisation: { __typename?: 'UserOrganisation', uuid: string, name: string } } | { __typename: 'UserCustomerProfile', uuid: string, schemeUuid: string, jobStatus: JobStatus | null, source: UserProfileSource, partner: { __typename?: 'UserCustomerPartner', uuid: string, name: string } } | { __typename: 'UserUserProfile', uuid: string, role: UserRole, status: UserProfileStatus, organisation: { __typename?: 'UserOrganisation', uuid: string, name: string } } } };

export type SignInQueryVariables = Exact<{
  input: SignInInput;
}>;


export type SignInQuery = { __typename?: 'Query', signIn: { __typename?: 'Token', accessToken: string, refreshToken: string | null } };

export type ResetPasswordQueryVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordQuery = { __typename?: 'Query', resetPassword: boolean };

export type CompleteResetPasswordQueryVariables = Exact<{
  input: CompleteResetPasswordInput;
}>;


export type CompleteResetPasswordQuery = { __typename?: 'Query', completeResetPassword: boolean };

export type RefreshQueryVariables = Exact<{
  input: InputMaybe<RefreshTokenInput>;
}>;


export type RefreshQuery = { __typename?: 'Query', refresh: { __typename?: 'Token', accessToken: string } };

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = { __typename?: 'Query', logout: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', user: { __typename?: 'User', uuid: string, firstName: string, lastName: string, email: string, avatarSrc: string | null, unreadNotificationCount: number, profile: { __typename?: 'UserContractorProfile', uuid: string } | { __typename?: 'UserCustomerProfile', uuid: string } | { __typename?: 'UserUserProfile', uuid: string, role: UserRole } } };

export type GetSignedUrlMutationVariables = Exact<{
  input: SignedUrlInput;
}>;


export type GetSignedUrlMutation = { __typename?: 'Mutation', getSignedFileUrl: string };

export type PutSignedHandoverUrlMutationVariables = Exact<{
  input: UploadJobFormFileInput;
}>;


export type PutSignedHandoverUrlMutation = { __typename?: 'Mutation', putSignedJobFormFileUrl: { __typename?: 'PutSignedFileUrl', url: string, key: string } };

export type CreateHandoverFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;


export type CreateHandoverFileMutation = { __typename?: 'Mutation', createJobFormFile: { __typename?: 'File', uuid: string } };

export type PutSignedAvatarUrlMutationVariables = Exact<{
  input: UploadAvatarUrlInput;
}>;


export type PutSignedAvatarUrlMutation = { __typename?: 'Mutation', putSignedAvatarPhotoUrl: { __typename?: 'PutSignedFileUrl', url: string, key: string } };

export type CreateAvatarFileMutationVariables = Exact<{
  input: CreateAvatarFileInput;
}>;


export type CreateAvatarFileMutation = { __typename?: 'Mutation', createAvatarFile: { __typename?: 'File', uuid: string } };

export type HolidayListQueryVariables = Exact<{
  filters: InputMaybe<IndexContractorHolidaysFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type HolidayListQuery = { __typename?: 'Query', holidays: { __typename?: 'ContractorHolidaysPage', items: Array<{ __typename?: 'ContractorHoliday', uuid: string, startDate: string, endDate: string, status: ContractorHolidayStatus }> } | null };

export type HolidaySingleQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type HolidaySingleQuery = { __typename?: 'Query', holiday: { __typename?: 'ContractorHoliday', uuid: string, startDate: string, endDate: string, status: ContractorHolidayStatus } };

export type JobListQueryVariables = Exact<{
  filters: InputMaybe<IndexAssignedJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type JobListQuery = { __typename?: 'Query', jobs: { __typename?: 'AssignedJobsPage', items: Array<{ __typename?: 'AssignedJob', uuid: string, type: JobType, dateRequired: string | null, displayDate: string, panelQuantity: number, hasEmergencyPs: boolean, hasBirdproofing: boolean, hasPowerDiverter: boolean, customer: { __typename?: 'JobCustomer', uuid: string, firstName: string, lastName: string }, address: { __typename?: 'JobAddress', uuid: string, line1: string, city: string | null, postcode: string, longitude: number | null, latitude: number | null } | null, handovers: Array<{ __typename?: 'AssignedJobHandover', type: JobHandoverType, jobUuid: string, submittedAt: string | null }> | null, contractors: Array<{ __typename?: 'AssignedJobContractor', firstName: string, lastName: string, dateRequired: string, phoneNumber: string | null, isScaffolder: boolean, isRoofer: boolean, isElectrician: boolean, avatarSrc: string | null }> | null, extras: Array<{ __typename?: 'JobProduct', uuid: string, productUuid: string, quantity: number, product: { __typename?: 'JobProductProduct', name: string } }> | null, survey: { __typename?: 'JobSurvey', uuid: string, battery: string, inverter: string, files: Array<{ __typename?: 'JobSurveyFile', uuid: string, key: string, src: string, name: string, mimeType: string, updatedAt: string }> | null }, partner: { __typename?: 'JobPartner', name: string } }> } };

export type JobListMonthQueryVariables = Exact<{
  filters: InputMaybe<IndexAssignedJobsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type JobListMonthQuery = { __typename?: 'Query', jobs: { __typename?: 'AssignedJobsPage', items: Array<{ __typename?: 'AssignedJob', uuid: string, dateRequired: string | null, customer: { __typename?: 'JobCustomer', uuid: string }, handovers: Array<{ __typename?: 'AssignedJobHandover', type: JobHandoverType, jobUuid: string, submittedAt: string | null }> | null }> } };

export type JobSingleQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type JobSingleQuery = { __typename?: 'Query', job: { __typename?: 'AssignedJob', uuid: string, type: JobType, dateRequired: string | null, displayDate: string, panelQuantity: number, hasEmergencyPs: boolean, hasBirdproofing: boolean, hasPowerDiverter: boolean, customer: { __typename?: 'JobCustomer', uuid: string, firstName: string, lastName: string }, address: { __typename?: 'JobAddress', uuid: string, line1: string, city: string | null, postcode: string, longitude: number | null, latitude: number | null } | null, handovers: Array<{ __typename?: 'AssignedJobHandover', type: JobHandoverType, jobUuid: string, submittedAt: string | null }> | null, contractors: Array<{ __typename?: 'AssignedJobContractor', firstName: string, lastName: string, dateRequired: string, phoneNumber: string | null, isScaffolder: boolean, isRoofer: boolean, isElectrician: boolean, avatarSrc: string | null }> | null, extras: Array<{ __typename?: 'JobProduct', uuid: string, productUuid: string, quantity: number, product: { __typename?: 'JobProductProduct', name: string } }> | null, survey: { __typename?: 'JobSurvey', uuid: string, battery: string, inverter: string, files: Array<{ __typename?: 'JobSurveyFile', uuid: string, key: string, src: string, name: string, mimeType: string, updatedAt: string }> | null }, partner: { __typename?: 'JobPartner', name: string } } };

export type SaveJobHandoverMutationVariables = Exact<{
  input: SaveJobHandoverInput;
}>;


export type SaveJobHandoverMutation = { __typename?: 'Mutation', handover: { __typename?: 'AssignedJobHandover', type: JobHandoverType, jobUuid: string, submittedAt: string | null } };

export type CreateRemedialJobMutationVariables = Exact<{
  input: CreateRemedialJobInput;
}>;


export type CreateRemedialJobMutation = { __typename?: 'Mutation', createRemedialJob: boolean };

export type AlertCardFragment = { __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string };

export type AlertListQueryVariables = Exact<{
  filters: InputMaybe<IndexNotificationsFilterInput>;
  pagination: InputMaybe<PaginationInput>;
}>;


export type AlertListQuery = { __typename?: 'Query', alerts: { __typename?: 'NotificationsPage', items: Array<{ __typename?: 'Notification', uuid: string, title: string, description: string | null, actionUrl: string | null, isDismissed: boolean, isViewed: boolean, createdAt: string }> } };

export type MeContractorQueryVariables = Exact<{ [key: string]: never; }>;


export type MeContractorQuery = { __typename?: 'Query', user: { __typename?: 'User', uuid: string, firstName: string, lastName: string, email: string, avatarSrc: string | null, phoneNumber: string | null, profile: { __typename?: 'UserContractorProfile', uuid: string, isScaffolder: boolean, isRoofer: boolean, isElectrician: boolean, availability: string, testInstrumentSerialNumber: string | null, holidays: Array<{ __typename?: 'ContractorHoliday', status: ContractorHolidayStatus, startDate: string, endDate: string }> | null } | { __typename?: 'UserCustomerProfile' } | { __typename?: 'UserUserProfile' } } };

export type UpdateSelfMutationVariables = Exact<{
  input: UpdateSelfInput;
}>;


export type UpdateSelfMutation = { __typename?: 'Mutation', user: { __typename?: 'User', uuid: string, firstName: string, lastName: string, email: string, phoneNumber: string | null, avatarSrc: string | null } };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type ReadNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadNotificationSettingsQuery = { __typename?: 'Query', readNotificationSettings: { __typename?: 'ContractorNotificationSettings', newJobAlerts: boolean, updatedJobAlerts: boolean, updatedHolidayAlerts: boolean, inAppNotifications: boolean, pushNotifications: boolean } };

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: UpdateNotificationSettingsInput;
}>;


export type UpdateNotificationSettingsMutation = { __typename?: 'Mutation', updateNotificationSettings: { __typename?: 'ContractorNotificationSettings', newJobAlerts: boolean, updatedJobAlerts: boolean, updatedHolidayAlerts: boolean, inAppNotifications: boolean, pushNotifications: boolean } };

export type JobSurveyFragmentFragment = { __typename?: 'AssignedJob', survey: { __typename?: 'JobSurvey', files: Array<{ __typename?: 'JobSurveyFile', key: string, src: string, updatedAt: string }> | null } };
