import { ReactElement, useState } from 'react';
import { Button } from '../../../atoms/Button';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import AddSlotModal from '../../Modals/AddSlot';

interface Props {
  date: Date;
  onAdd: () => void; 
}

const AddSlot = ({ date, onAdd }: Props): ReactElement => {
  const [showAddSlotModal, setShowAddSlotModal] = useState(false);

  return (
    <>
      <div className="p-3 flex items-center pb-10">
        <span className="text-sm flex-grow">Slots</span>
        {/* <Button
          bStyle="light"
          className="h-9 w-9 !p-0 justify-center"
          Icon={<MinusIcon className="size-5" />}
        /> */}
        <Button
          bStyle="light"
          className="h-9 w-9 !p-0 ml-2 justify-center"
          Icon={<PlusIcon onClick={() => setShowAddSlotModal(true)} className="size-5" />}
        />
      </div>
      <AddSlotModal
        open={showAddSlotModal}
        onClose={(success) => {
          if (success) {
            onAdd()
          }
          setShowAddSlotModal(false)
        }}
        date={date}
      />
    </>
  );
};
export default AddSlot;
