import { ReactElement, useState } from 'react';
import {
  AuditEntryType,
  CommentTargetType,
  gql,
  ReadTaskQuery,
  TargetType,
  TaskStatus,
} from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import { Link, useRouter } from '@tanstack/react-router';
import {
  ArrowLeftIcon,
  Battery0Icon,
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ClipboardIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import CommentThread from '../../molecules/CommentThread';
import { tasksUtility } from '../../../utility/tasks';
import Dropdown from '../../atoms/Dropdown';
import { useMutation } from '@apollo/client';
import { notify } from '../../../utility/notify';
import Tag from '../../atoms/Tag';
import TwoLineText from '../../atoms/TwoLineText';
import { format, isAfter, subDays } from 'date-fns';
import FileArray from '../../atoms/FileArray';
import Installation from '../../../assets/icons/Installation.svg';
import Remedial from '../../../assets/icons/Remedial.svg';
import UserDropdown from '../../molecules/UserDropown';
import AddTask from '../../molecules/Modals/AddTask';
import AuditModal from '../../molecules/Modals/Audit';
import { divide } from 'mathjs';
import Robot from '../../icons/Robot';
import { styleUtility } from '../../../utility/styleUtility';

interface Props {
  task: ReadTaskQuery['readTask'];
}

const jobTypeComponentMap = {
  installation: (
    <img alt="installation" className="w-5 h-auto" src={Installation} />
  ),
  remedial: <img alt="remedial" className="w-5 h-auto" src={Remedial} />,
  battery: <Battery0Icon className="size-5 text-grey-500" />,
};

const UPSERT_TASK = gql(`
  mutation UpsertTaskInTask ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
    }
  }  
`);

const Task = ({ task: initialTask }: Props): ReactElement => {
  const router = useRouter();
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [task, setTask] = useState(initialTask);
  const [upsertTask] = useMutation(UPSERT_TASK, {
    onCompleted: () => notify.success('Task saved!'),
    onError: (err) => notify.error(`Unable to save task \n ${err.message}`),
  });

  return (
    <>
      <div className="flex h-full overflow-hidden">
        <div className="p-5 flex-grow">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-5" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Task #{task.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
          </div>
          <div className="bg-white p-5 rounded-xl space-y-5">
            <div className="flex justify-between">
              <ClipboardIcon className="text-primary size-11" />
              <Dropdown
                anchor="bottom end"
                selected={tasksUtility.taskStatusOptions.find(
                  ({ value }) => value === task.status
                )}
                buttonClassname="flex items-center space-x-2 p-2"
                options={tasksUtility.taskStatusOptions}
                onOptionSelect={(opt) => {
                  setTask({
                    ...task,
                    status: opt.value,
                  });
                  upsertTask({
                    variables: {
                      input: {
                        uuid: task.uuid,
                        status: opt.value,
                      },
                    },
                  });
                }}
                ButtonLeadIcon={
                  <Tag
                    size="lg"
                    colour={task.status}
                    text={tasksUtility.taskStatusNiceMap[task.status]}
                  />
                }
                ButtonIcon={<ChevronDownIcon className="size-5" />}
              />
            </div>
            <h1 className="text-h1-small font-bold font-nunito">
              {task.title}
            </h1>
            <div className="flex items-center flex-grow">
              <span className="block mr-3 text-text-low-priority text-body-small">
                Due date:
              </span>
              {task.dueDate ? (
                task.status === TaskStatus.done ? (
                  <CheckCircleIcon className="size-5 rounded-full text-primary" />
                ) : isAfter(new Date(), task.dueDate) ? (
                  <ExclamationCircleIcon className="size-5 rounded-full text-red-100" />
                ) : isAfter(new Date(), subDays(task.dueDate, 7)) ? (
                  <ExclamationTriangleIcon className="size-5 rounded-full text-amber-100" />
                ) : (
                  <CalendarIcon className="size-5 text-grey-400" />
                )
              ) : (
                <CalendarIcon className="size-5 text-grey-400" />
              )}
              <span className="block ml-2">
                {task.dueDate ? format(task.dueDate, 'do MMMM yyyy') : '-'}
              </span>
            </div>
            <p className="text-body">{task.description}</p>
            {task.files && <FileArray files={task.files} />}
            <hr className="h-px border-none bg-grey-700 w-full" />
            <div className="grid-cols-3 gap-5 grid">
              <TwoLineText
                label="Assigned to"
                text={
                  <div>
                    <UserDropdown
                      simple
                      disabled={task.status === TaskStatus.done}
                      userUuid={task.assignee?.uuid}
                      setUserUuid={(_, profileUuid) => {
                        const status =
                          task.status === TaskStatus.unassigned
                            ? TaskStatus.todo
                            : task.status;
                        setTask((t) => ({
                          ...t,
                          status,
                          assignee: {
                            firstName: '',
                            lastName: '',
                            avatarSrc: '',
                            uuid: profileUuid,
                          },
                        }));
                        upsertTask({
                          variables: {
                            input: {
                              uuid: task.uuid,
                              status,
                              operatorUuid: profileUuid,
                            },
                          },
                        });
                      }}
                    />
                  </div>
                }
              />
              <TwoLineText
                label="Created on"
                Icon={<CalendarIcon className="size-5 text-grey-400" />}
                text={
                  <div className="py-1.5 ml-2">
                    <span className='whitespace-nowrap'>{format(task.createdAt, 'do MMMM yyyy H:m')}</span>
                  </div>
                }
              />
              <TwoLineText
                label="Created by"
                text={
                  task.creator ? (
                    <Link
                      to="/contacts/$uuid"
                      className="flex items-center py-1.5"
                      params={{
                        uuid: task.creator.uuid,
                      }}
                    >
                      <UserIcon className="size-5 mr-2 text-grey-400" />
                      <span className="text-body underline">
                        {task.creator.firstName} {task.creator.lastName}
                      </span>
                    </Link>
                  ) : (
                    <div className="flex items-center py-1 5">
                      <Robot
                        multiplier={0.9}
                        colour={styleUtility.colours.grey[400]}
                      />
                      <span className="text-body underline ml-2">System</span>
                    </div>
                  )
                }
              />
              <TwoLineText
                label="Customer"
                text={
                  task.customer ? (
                    <Link
                      to="/contacts/$uuid"
                      className="flex items-center py-1.5"
                      params={{
                        uuid: task.customer.uuid,
                      }}
                    >
                      <UserIcon className="size-5 mr-2 text-grey-400" />
                      <span className="text-body underline">
                        {task.customer.firstName} {task.customer.lastName}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )
                }
              />
              <TwoLineText
                label="Job"
                text={
                  task.job ? (
                    <Link
                      to="/job/$uuid"
                      className="flex items-center py-1.5"
                      params={{
                        uuid: task.job.uuid,
                      }}
                    >
                      {jobTypeComponentMap[task.job.type]}
                      <span className="text-body underline ml-2 whitespace-nowrap">
                        {task.job.displayName}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )
                }
              />
            </div>
            <div className="flex justify-end w-full">
              <Button
                onClick={() => setShowEditTaskModal(true)}
                bStyle="outline"
                bText="Edit details"
              />
            </div>
          </div>
        </div>
        <div className="bg-white h-full">
          <CommentThread
            targetType={CommentTargetType.task}
            targetUuid={task.uuid}
            hideRaiseTask
          />
        </div>
      </div>
      <AddTask
        open={showEditTaskModal}
        onClose={(success, task) => {
          setShowEditTaskModal(false);
          if (success && task) {
            setTask(task);
          }
        }}
        uuid={task.uuid}
        title={task.title}
        description={task.description ?? undefined}
        files={task.files}
        dueDate={task.dueDate ? new Date(task.dueDate) : undefined}
        operatorUuid={task.assignee?.uuid}
        customerProfileUuid={task.customer?.uuid}
        jobUuid={task.job?.uuid}
        partnerUuid={task.customer?.partnerUuid ?? undefined}
      />
      <AuditModal
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
        targetType={TargetType.task}
        targetUuid={task.uuid}
      />
    </>
  );
};
export default Task;
