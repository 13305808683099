import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { gql, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { useNavigate } from '@tanstack/react-router';
import { notify } from '../../../../utility/notify';
import OrganisationDropdown from '../../OrganisationDropdown';
import { useAuth } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';

type InitalState = {
  uuid?: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationUuid: string;
  phone: string;
  role: UserRole;
};

type Props = {
  user?: InitalState;
  organisationName?: string;
} & ModalWrapperPropsExtends;

const roleOptions = [
  {
    name: 'Operator',
    value: UserRole.operator,
  },
  {
    name: 'Adminstrator',
    value: UserRole.administrator,
  },
  {
    name: 'Super Administrator',
    value: UserRole.superAdministrator,
  },
];

const CREATE_USER_USER_PROFILE = gql(`
    mutation CreateUserUserProfile($input: CreateUserUserProfileInput!) {
      createUserUserProfile(input: $input) {
        userUuid
      }
    }
  `);

const UPDATE_USER_USER_PROFILE = gql(`
    mutation UpdateUserUserProfile($input: UpdateUserUserProfileInput!) {
      updateUserUserProfile(input: $input) {
        userUuid
      }
    }
  `);

const AddUserModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AddUserModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const AddUserModalChild = ({
  onClose,
  user = {
    uuid: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    organisationUuid: '',
    role: UserRole.operator,
  },
  organisationName,
}: Omit<Props, 'open'>): ReactElement => {
  const { user: authenticatedUser } = useAuth();
  profileUtility.assertProfile(authenticatedUser, 'UserUserProfile');

  const [amendUser, { error, loading }] = useMutation(
    user.uuid ? UPDATE_USER_USER_PROFILE : CREATE_USER_USER_PROFILE
  );
  const navigate = useNavigate();

  const form = useForm({
    onSubmit: ({ value }) => {
      const attributes = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        organisationUuid: value.organisationUuid,
        phone: value.phone,
        role: value.role.value,
      };
      amendUser({
        variables: {
          input: {
            uuid: user?.uuid?.length ? user.uuid : undefined,
            ...attributes,
          },
        },
      }).then((d) => {
        onClose(true);
        if (d.data) {
          notify.success('Successfully saved user.');
          navigate({
            to: '/contacts/$uuid',
            params: {
              uuid: d.data.createUserUserProfile.userUuid,
            },
          });
        }
      });
    },
    defaultValues: {
      ...user,
      role:
        roleOptions.find(({ value }) => value === user.role) ?? roleOptions[0],
    },
  });

  return (
    <Modal
      title={`${user ? 'Edit' : 'Add'} user`}
      onClose={onClose}
      confirmText={`${user ? 'Save' : 'Add'} user`}
      confirmCallback={() => form.handleSubmit()}
      loading={loading}
    >
      <div className="p-5 max-w-150 overflow-hidden">
        {error?.message && (
          <div className="mb-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
        <form.Field
          name="firstName"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add a first name' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="First name"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="lastName"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add a last name' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Last name"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="email"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add an email' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              type="email"
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Email"
              error={state.meta.errors.join(', ')}
              className="w-140"
              Icon={<EnvelopeIcon className="size-6" />}
            />
          )}
        />
        <form.Field
          name="phone"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Phone"
              Icon={<PhoneIcon className="size-6" />}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="organisationUuid"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please select an organisation uuid' : undefined,
          }}
          children={({ state, handleChange }) => (
            <OrganisationDropdown
              organisationUuid={state.value}
              setOrganisationUuid={handleChange}
              organisationName={organisationName}
            />
          )}
        />
        {authenticatedUser!.profile.role === UserRole.superAdministrator && (
          <form.Field
            name="role"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a valid role' : undefined,
            }}
            children={({ state, handleChange }) => (
              <DropdownWithBorder
                options={roleOptions}
                onOptionSelect={handleChange}
                buttonText={state.value.name}
                selected={state.value}
                label="Role"
                buttonClassname="w-full justify-between w-80 mb-5"
              />
            )}
          />
        )}
      </div>
    </Modal>
  );
};
export default AddUserModal;
