import { ReactElement } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import TwoLineText from '../../../atoms/TwoLineText';
import { usePartners } from '../../../../utility/partnerContext';
import {
  Battery50Icon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { gql } from '@monorepo/graphql';
import { useForm } from '@tanstack/react-form';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import CheckboxCard from '../../../atoms/CheckboxCard';
import { InstallationIcon } from '../../../icons/Installation';
import { styleUtility } from '../../../../utility/styleUtility';
import SchemeDropdown from '../../SchemeDropdown';
import Alert from '../../../atoms/Alerts';

type Props = {
  date: Date;
};

const AddSlotModal = ({
  open,
  onClose,
  ...props
}: ModalWrapperPropsExtends & Props): ReactElement => (
  <ModalWrapper dialogPanelClassname='max-w-140' open={open} onClose={onClose}>
    <AddSlotChild onClose={onClose} {...props} />
  </ModalWrapper>
);

const CREATE_SLOT = gql(`
  mutation CreateSlot($input: CreateSlotInput!) {
    createSlot(input: $input) {
      uuid
      schemeName
      isInstallationSelected
      isRemedialSelected 
      isBatterySelected
    }
  }  
`);

const AddSlotChild = ({
  date,
  onClose,
}: ModalPropsExtends & Props): ReactElement => {
  const { selectedPartner } = usePartners();
  const [create, { loading, error }] = useMutation(CREATE_SLOT);

  const form = useForm<{
    schemeUuid?: string;
    selected: {
      isInstallationSelected: boolean;
      isRemedialSelected: boolean;
      isBatterySelected: boolean;
    };
  }>({
    onSubmit: (vals) => {
      create({
        variables: {
          input: {
            date,
            partnerUuid: selectedPartner!.uuid,
            schemeUuid: vals.value.schemeUuid,
            ...vals.value.selected,
          },
        },
      }).then(() => {
        notify.success('Saved slot.');
        onClose(true);
      });
    },
    defaultValues: {
      selected: {
        isInstallationSelected: true,
        isRemedialSelected: false,
        isBatterySelected: false,
      },
    },
  });

  return (
    <Modal
      confirmText="Add slot"
      confirmCallback={form.handleSubmit}
      loading={loading}
      title="Add a one-off slot"
      onClose={onClose}
    >
      <div className="p-5">
        <div className="mb-5">
          <Alert
            text="Creating a slot manually might lead to jobs being booked without contractors being available."
            alertType="warning"
          />
        </div>
        <div className="flex items-center space-x-5 mb-5">
          <TwoLineText
            label="Partner"
            text={selectedPartner?.name}
            Icon={<BuildingOffice2Icon className="text-grey-400 size-5" />}
          />
          <TwoLineText
            label="Date"
            text={format(date, 'EEEE do MMMM yyyy')}
            Icon={<CalendarDaysIcon className="text-grey-400 size-5" />}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="mb-5">
            <div className="mb-5 w-full">
              <span className="mb-2 text-input-label block font-semibold">
                Slot Type
              </span>
              <form.Field
                name="selected"
                validators={{
                  onSubmit: ({ value }) =>
                    !value.isBatterySelected &&
                    !value.isInstallationSelected &&
                    !value.isRemedialSelected
                      ? 'Please select a job type'
                      : undefined,
                }}
                children={({ state, handleChange }) => (
                  <>
                    <div className="flex space-x-2">
                      <CheckboxCard
                        Icon={
                          <InstallationIcon
                            multiplier={1.1}
                            colour={styleUtility.colours.primary['DEFAULT']}
                          />
                        }
                        title="Installation"
                        checked={state.value.isInstallationSelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isBatterySelected: false,
                            isRemedialSelected: false,
                            isInstallationSelected: checked,
                          }))
                        }
                        explainer="First time visit"
                      />
                      <CheckboxCard
                        Icon={
                          <WrenchScrewdriverIcon className="text-primary size-8" />
                        }
                        title="Remedial"
                        checked={state.value.isRemedialSelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isInstallationSelected: false,
                            isRemedialSelected: checked,
                          }))
                        }
                        explainer="Upgrade or repair"
                      />
                      <CheckboxCard
                        Icon={<Battery50Icon className="text primary size-8" />}
                        title="Battery"
                        checked={state.value.isBatterySelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isInstallationSelected: false,
                            isBatterySelected: checked,
                          }))
                        }
                        explainer="Battery only"
                      />
                    </div>
                    {state.meta.errors.length > 0 && (
                      <div className="flex py-2 px-3 border-red--100 bg-red-500 border mb-5 rounded mt-2">
                        <span>{state.meta.errors.join(', ')}</span>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <form.Field
            name="schemeUuid"
            children={({ state, handleChange }) => (
              <SchemeDropdown
                schemeUuid={state.value ?? ''}
                partnerUuid={selectedPartner!.uuid}
                setSchemeUuid={handleChange}
              />
            )}
          />
        </form>
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};

export default AddSlotModal;
