
  import gql from "graphql-tag";
  export const typeDefs = gql`schema {
  query: Query
  mutation: Mutation
}

directive @cacheControl(inheritMaxAge: Boolean, maxAge: Int, scope: CacheControlScope) on FIELD_DEFINITION | INTERFACE | OBJECT | UNION

type Address {
  city: String
  county: String
  latitude: Float
  line1: String!
  line2: String
  longitude: Float
  postcode: String!
  uuid: String!
}

type AssignedJob {
  address: JobAddress
  contractors: [AssignedJobContractor!]
  customer: JobCustomer!
  dateRequired: String
  displayDate: String!
  extras: [JobProduct!]
  handovers: [AssignedJobHandover!]
  hasBirdproofing: Boolean!
  hasEmergencyPs: Boolean!
  hasPowerDiverter: Boolean!
  hasRemedial: Boolean
  panelQuantity: Int!
  partner: JobPartner!
  status: JobStatus!
  survey: JobSurvey!
  type: JobType!
  uuid: String!
}

type AssignedJobContractor {
  avatarSrc: String
  dateRequired: String!
  email: String!
  firstName: String!
  isConfirmed: Boolean!
  isElectrician: Boolean!
  isRoofer: Boolean!
  isScaffolder: Boolean!
  lastName: String!
  phoneNumber: String
}

type AssignedJobHandover {
  jobUuid: String!
  submittedAt: Date
  type: JobHandoverType!
}

type AssignedJobsPage {
  items: [AssignedJob!]!
  pagination: PaginationResponse!
}

input AttachJobContractorsInput {
  contractors: [JobContractorInput!]!
  jobUuid: String!
}

type AuditEntry {
  auditEntryUser: AuditEntryUser
  createdAt: Date!
  notes: String
  targetType: TargetType!
  title: String!
  uuid: String!
}

type AuditEntryPage {
  items: [AuditEntry!]!
  pagination: PaginationResponse!
}

enum AuditEntryType {
  auditFailed
  auditPassed
  auditResubmitted
  contractSigned
  emailSent
  fileDeleted
  handoverSubmitted
  handoversCompleted
  jobCreated
  slotUpdated
  surveyUpdated
  userCreated
  userCustomerProfileCall
  userLogin
  userUpdated
}

type AuditEntryUser {
  avatarSrc: String
  firstName: String!
  lastName: String!
  userType: UserProfile!
}

input AuditJobSkill {
  levelRequired: SkillLevel!
  trade: ContractorTrade!
  uuid: String!
}

enum AuditType {
  postElectrical
  postGeneral
  postRoofing
  pre
}

input AuditorInput {
  postElectrical: String!
  postGeneral: String!
  postRoofing: String!
  pre: String!
}

type AvailableDate {
  displayDate: String!
  slotDate: Date!
}

enum CacheControlScope {
  PRIVATE
  PUBLIC
}

input ChangePasswordInput {
  currentPassword: String!
  newPassword: String!
}

type Comment {
  createdAt: Date!
  files: [CommentFile!]
  job: CommentJob
  targetType: CommentTargetType!
  targetUuid: String!
  text: String!
  user: CommentUser!
  uuid: String!
}

type CommentFile {
  name: String!
  src: String!
  type: String!
  uuid: String!
}

type CommentJob {
  displayLink: String!
  uuid: String!
}

type CommentPage {
  items: [Comment!]!
  pagination: PaginationResponse!
}

enum CommentTargetType {
  contractorProfile
  customerProfile
  job
  survey
  task
}

type CommentUser {
  avatarSrc: String
  firstName: String!
  lastName: String!
  uuid: String!
}

input CommitFilesInput {
  auditTargetType: TargetType
  auditTargetUuid: String
  uuids: [String!]!
}

input CompleteResetPasswordInput {
  email: String!
  password: String!
  token: String!
}

enum ContractorCompletionRate {
  aboveEighty
  belowTwenty
  twentyToEighty
}

type ContractorHoliday {
  endDate: Date!
  startDate: Date!
  status: ContractorHolidayStatus!
  uuid: String!
}

enum ContractorHolidayStatus {
  approved
  declined
  pending
}

type ContractorHolidaysPage {
  items: [ContractorHoliday!]!
  pagination: PaginationResponse!
}

type ContractorNotificationSettings {
  inAppNotifications: Boolean!
  newJobAlerts: Boolean!
  pushNotifications: Boolean!
  updatedHolidayAlerts: Boolean!
  updatedJobAlerts: Boolean!
}

type ContractorProduct {
  customerAvatarSrc: String
  customerFirstName: String!
  customerLastName: String!
  customerUserUuid: String!
  image: ContractorProductImage
  installationDate: Date!
  jobUuid: String!
  name: String!
  totalCost: Float!
  uuid: String!
}

type ContractorProductImage {
  mimeType: String!
  name: String!
  src: String!
}

type ContractorProductPage {
  items: [ContractorProduct!]!
  meta: ContractorProductPageMeta!
  pagination: PaginationResponse!
}

type ContractorProductPageMeta {
  allTimeTotal: Float!
}

type ContractorSkill {
  levelRequired: SkillLevel!
  name: String!
  trade: ContractorTrade!
  uuid: String!
}

enum ContractorTrade {
  electrician
  roofer
}

input CreateAvatarFileInput {
  key: String!
  mimeType: String!
  name: String!
  size: Int!
}

input CreateFileInput {
  customerProfileUuid: String
  key: String!
  mimeType: String!
  name: String!
  size: Int!
  targetType: FileTargetType!
  targetUuid: String!
}

input CreateJobInput {
  customerProfileUserUuid: String!
  duration: Int!
  isElectricianRequired: Boolean!
  isLabourerRequired: Boolean!
  isManualAllocation: Boolean!
  isRooferRequired: Boolean!
  numberOfRequiredDays: Int!
  partnerUuid: String!
  searchDate: Date!
  surveyUuid: String
  type: JobType!
}

input CreatePartnerInput {
  email: String!
  name: String!
  slaUuid: String!
  telephone: String!
  website: String
}

input CreateRemedialJobInput {
  description: String!
  duration: Int!
  isElectricianRequired: Boolean!
  isRooferRequired: Boolean!
  itemsLeft: String!
  itemsRequired: String!
  jobUuid: String!
  reason: String!
  specialRequests: String!
  uuid: String!
}

input CreateRoofInput {
  arrays: [RoofArrayInput!]!
  scaffolding: CreateRoofScaffoldingInput
  surveyUuid: String!
  tile: RoofTile!
}

input CreateRoofScaffoldingInput {
  height: Int!
  isBridgeRequired: Boolean!
  isTowerRequired: Boolean!
  location: ScaffoldingLocation!
  locationArea: ScaffoldingLocationArea!
  locationOtherDescription: String
  notes: String
  width: Int!
}

input CreateSlotInput {
  date: Date!
  isBatterySelected: Boolean!
  isInstallationSelected: Boolean!
  isRemedialSelected: Boolean!
  partnerUuid: String!
  schemeUuid: String
}

input CreateUserAddressInput {
  line1: String!
  postcode: String!
}

input CreateUserContractorProfileInput {
  addressLineOne: String!
  addressLineTwo: String
  availability: String!
  city: String
  county: String
  email: String!
  firstName: String!
  isElectrician: Boolean!
  isRoofer: Boolean!
  lastName: String!
  latitude: Float!
  longitude: Float!
  organisationUuid: String!
  phone: String!
  postcode: String!
  radius: Int!
  schemeUuid: String
  startDate: String!
}

input CreateUserCustomerProfileInput {
  addressLineOne: String!
  addressLineTwo: String
  city: String
  county: String
  email: String!
  firstName: String!
  lastName: String!
  latitude: Float!
  longitude: Float!
  partnerUuid: String!
  phone: String!
  postcode: String!
  schemeUuid: String!
}

input CreateUserInput {
  address: CreateUserAddressInput!
  email: String!
  firstName: String!
  lastName: String!
  password: String
}

input CreateUserUserProfileInput {
  email: String!
  firstName: String!
  lastName: String!
  organisationUuid: String!
  phone: String!
  role: UserRole!
  schemeUuid: String
  status: UserProfileStatus
  uuid: String
}

scalar Date

input DeleteFileInput {
  auditTargetType: TargetType
  auditTargetUuid: String
  uuid: String!
}

type File {
  createdAt: Date!
  downloadSrc: String!
  key: String!
  mimeType: String!
  name: String!
  owner: FileOwner
  size: Int!
  src: String!
  targetType: FileTargetType
  targetUuid: String
  uuid: String!
}

type FileOwner {
  avatarSrc: String
  firstName: String!
  lastName: String!
}

type FilePage {
  items: [File!]!
  pagination: PaginationResponse
}

enum FileTargetType {
  comment
  contract
  customer
  handover
  job
  jobProduct
  survey
  task
  ticket
}

input IndexAssignedJobsFilterInput {
  fromDate: String!
  toDate: String!
}

input IndexAuditEntriesInput {
  entryType: AuditEntryType
  targetType: TargetType
  targetUuid: String
  userUuid: String
}

input IndexCommentsFilter {
  targetType: CommentTargetType!
  targetUuid: String!
}

input IndexContractorHolidaysFilterInput {
  past: Boolean
}

input IndexFilesFilter {
  customerProfileUuid: String
  mimeType: String
  targetType: FileTargetType
  targetUuid: String
}

input IndexJobsFilterInput {
  contractorProfileUuid: String
  customerProfileUuid: String
  difficulty: JobDifficulty
  operatorUuid: String
  partnerUuid: String
  schemeUuid: String
  status: JobStatus
  term: String
}

input IndexNotificationsFilterInput {
  isDismissed: Boolean
  isViewed: Boolean
}

input IndexOrganisationsFilterInput {
  term: String
}

input IndexPartnersFilterInput {
  term: String
}

input IndexPotentialContractorsForJobFilter {
  isElectrician: Boolean!
  isRoofer: Boolean!
  jobUuid: String!
  term: String
}

input IndexSchemesFilterInput {
  partnerUuid: String
  term: String
}

input IndexSettingsFilter {
  kind: [SettingKind!]!
}

input IndexSkillsFilterInput {
  term: String
}

input IndexSkillsForJobsFilterInput {
  jobUuid: String!
}

input IndexSlotsFilter {
  contractors: [String!]
  date: Date!
  difficulty: [JobDifficulty!]
  jobType: [JobType!]
  partnerUuid: String!
  schemes: [String!]
  status: [JobStatus!]
}

input IndexSlotsInput {
  filter: IndexSlotsFilter!
  pagination: PaginationInput
}

input IndexTasksFilterInput {
  assignedOperators: [String!]
  creatorUuid: String
  customerProfileUuid: String
  jobUuid: String
  status: [TaskStatus!]
}

input IndexTicketsFilterInput {
  creatorUuid: String
  status: TicketStatus
}

input IndexUsersFilterInput {
  organisationUuid: String
  partnerUuid: String
  term: String
  userContractorProfileCompletionRateGte: Int
  userContractorProfileCompletionRateLte: Int
  userContractorProfileIsElectrician: Boolean
  userContractorProfileIsRoofer: Boolean
  userCustomerJobStatus: JobStatus
  userProfile: UserProfile
  userUserProfileRole: UserRole
}

type Invoice {
  amount: Float!
  createdAt: Date!
  dueDate: Date!
  notes: String
  status: InvoiceStatus!
  uuid: String!
  xeroLink: String!
  xeroReference: String!
}

input InvoiceCreateInput {
  amount: Float!
  customerProfileUuid: String!
  dueDate: Date!
  jobUuid: String!
  notes: String!
  status: InvoiceStatus!
  xeroLink: String!
  xeroReference: String!
}

enum InvoiceStatus {
  created
  overdue
  paid
  sent
  voided
}

input InvoiceUpdateInput {
  amount: Float!
  dueDate: Date!
  notes: String
  status: InvoiceStatus!
  uuid: String!
  xeroLink: String!
  xeroReference: String!
}

type InvoicesPage {
  items: [Invoice!]!
  pagination: PaginationResponse!
}

type Job {
  address: JobAddress
  contractors: [AssignedJobContractor!]
  customer: JobCustomer!
  dates: [Date!]!
  difficulty: JobDifficulty!
  displayDate: String!
  displayLink: String!
  duration: Int!
  extras: [JobProduct!]
  operatorUuid: String
  partner: JobPartner!
  revisedSolarPanelCount: Int
  schemeName: String!
  solarPanelCount: Int!
  startDate: Date
  status: JobStatus!
  survey: JobSurvey!
  type: JobType!
  uuid: String!
}

type JobAddress {
  city: String
  latitude: Float
  line1: String!
  line2: String
  longitude: Float
  postcode: String!
  uuid: String!
}

type JobAudit {
  auditType: AuditType!
  auditor: JobAuditor
  hasPassed: Boolean!
  notes: String
  submittedAt: Date
  uuid: String!
}

type JobAuditor {
  avatarSrc: String
  firstName: String!
  lastName: String!
  uuid: String!
}

type JobContractor {
  canBeElectrician: Boolean!
  canBeRoofer: Boolean!
  contractorProfileUuid: String!
  datesRequired: [Date!]!
  displayDateRequired: String!
  distance: Float!
  isElectrician: Boolean!
  isRoofer: Boolean!
  jobUuid: String!
  user: JobContractorUser!
  uuid: String!
}

input JobContractorInput {
  canBeElectrician: Boolean!
  canBeRoofer: Boolean!
  contractorProfileUuid: String!
  datesRequired: [Date!]!
  isElectrician: Boolean!
  isPrimary: Boolean!
  isRoofer: Boolean!
}

type JobContractorPotential {
  avatarSrc: String
  canBeElectrician: Boolean!
  canBeRoofer: Boolean!
  contractorProfileUuid: String!
  distance: Float!
  firstName: String!
  isElectrician: Boolean!
  isRoofer: Boolean!
  lastName: String!
  userUuid: String!
}

type JobContractorUser {
  avatarSrc: String
  firstName: String!
  isElectrician: Boolean!
  isRoofer: Boolean!
  lastName: String!
  uuid: String!
}

type JobCustomer {
  avatarSrc: String
  email: String!
  firstName: String!
  lastName: String!
  phoneNumber: String!
  userUuid: String!
  uuid: String!
}

enum JobDifficulty {
  difficult
  easy
  normal
}

enum JobFormFileType {
  electricalHandover
  remedial
  roofingHandover
}

type JobHandover {
  displayDate: String!
  src: String!
  type: JobHandoverType!
  uuid: String!
}

enum JobHandoverType {
  electrical
  roofing
}

type JobPartner {
  name: String!
  uuid: String!
}

type JobProduct {
  contractorProfileUuid: String
  image: JobProductFile
  isBillable: Boolean!
  isInstalled: Boolean!
  price: String!
  product: JobProductProduct!
  productUuid: String!
  quantity: Int!
  uuid: String!
}

type JobProductFile {
  key: String!
  mimeType: String!
  name: String!
  size: Int!
  src: String!
  uuid: String
}

input JobProductInput {
  imageUuid: String
  isInstalled: Boolean!
  price: String!
  uuid: String!
}

type JobProductProduct {
  name: String!
  responsibleContractorType: ResponsibleContractorType
}

enum JobProductType {
  electrical
  roofing
  survey
}

type JobSkill {
  levelRequired: SkillLevel!
  name: String!
  trade: ContractorTrade!
  uuid: String!
}

enum JobStatus {
  cancelled
  completed
  dnoG98
  dnoG99
  feedback
  incomplete
  notSigned
  postAuditElectricalAndRoofing
  postAuditGeneral
  preAudit
  ready
  rescheduled
  scaffoldingUp
}

type JobSurvey {
  battery: String!
  files: [JobSurveyFile!]
  inverter: String!
  uuid: String!
}

type JobSurveyFile {
  key: String!
  mimeType: String!
  name: String!
  size: Int!
  src: String!
  updatedAt: Date!
  uuid: String!
}

enum JobType {
  battery
  installation
  remedial
}

type JobsPage {
  items: [Job!]!
  pagination: PaginationResponse!
}

type Mutation {
  attachJobContractors(input: AttachJobContractorsInput!): Boolean!
  changePassword(input: ChangePasswordInput!): Boolean!
  commitFiles(input: CommitFilesInput!): Boolean!
  createAvatarFile(input: CreateAvatarFileInput!): File!
  createFile(input: CreateFileInput!): File!
  createInvoice(input: InvoiceCreateInput!): Invoice!
  createJob(input: CreateJobInput!): Job!
  createJobFormFile(input: CreateFileInput!): File!
  createPartner(input: CreatePartnerInput!): Partner!
  createRemedialJob(input: CreateRemedialJobInput!): Boolean!
  createRoof(input: CreateRoofInput!): Roof!
  createSlot(input: CreateSlotInput!): Slot!
  createUser(input: CreateUserInput!): User!
  createUserContractorProfile(input: CreateUserContractorProfileInput!): UserContractorProfile!
  createUserCustomerProfile(input: CreateUserCustomerProfileInput!): UserCustomerProfile!
  createUserUserProfile(input: CreateUserUserProfileInput!): UserUserProfile!
  deleteComment(uuid: String!): Boolean!
  deleteFile(input: DeleteFileInput!): Boolean!
  deleteOrganisation(uuid: String!): Boolean!
  deleteRoof(uuid: String!): Boolean!
  deleteSLA(uuid: String!): Boolean!
  deleteSkill(uuid: String!): Boolean!
  deleteSlot(uuid: String!): Boolean!
  dismissAllNotifications: Boolean!
  dismissNotification(uuid: String!): Notification!
  getSignedFileUrl(input: SignedUrlInput!): String!
  putSignedAvatarPhotoUrl(input: UploadAvatarUrlInput!): PutSignedFileUrl!
  putSignedFileUrl(input: UploadUrlInput!): PutSignedFileUrl!
  putSignedJobFormFileUrl(input: UploadJobFormFileInput!): PutSignedFileUrl!
  rebookJob(input: RebookJobInput!): Job!
  saveFeedback(input: SaveJobFeedback!): Boolean!
  saveJobHandover(input: SaveJobHandoverInput!): AssignedJobHandover!
  updateAddress(input: UpdateAddressInput!): Address!
  updateInvoice(input: InvoiceUpdateInput!): Invoice!
  updateJob(input: UpdateJobInput!): Job!
  updateJobAudit(input: UpdateJobAuditInput!): JobAudit!
  updateNotificationSettings(input: UpdateNotificationSettingsInput!): ContractorNotificationSettings!
  updatePartner(input: UpdatePartnerInput!): Partner!
  updateRoof(input: UpdateRoofInput!): Roof!
  updateScheme(input: UpdateSchemeInput!): Scheme!
  updateSelf(input: UpdateSelfInput!): User!
  updateSetting(input: UpdateSettingInput!): Setting!
  updateSlot(input: UpdateSlotInput!): Slot!
  updateSurvey(input: UpdateSurveyInput!): Survey!
  updateUserContractorProfile(input: UpdateUserContractorProfileInput!): UserContractorProfile!
  updateUserCustomerProfile(input: UpdateUserCustomerProfileInput!): UserCustomerProfile!
  updateUserUserProfile(input: UpdateUserUserProfileInput!): UserUserProfile!
  upsertAuditEntry(input: UpsertAuditEntryInput!): AuditEntry!
  upsertComment(input: UpsertCommentInput!): Comment!
  upsertOrganisation(input: UpsertOrganisationInput!): Organisation
  upsertSLA(input: UpsertSLAInput!): SLA!
  upsertScheme(input: UpsertSchemeInput!): Scheme!
  upsertSkill(input: UpsertSkillInput!): Skill!
  upsertTask(input: TaskUpsertInput!): Task!
  viewNotification(uuid: String!): Notification!
}

type Notification {
  actionUrl: String
  createdAt: Date!
  description: String
  isDismissed: Boolean!
  isViewed: Boolean!
  targetType: NotificationTargetType!
  targetUserUuid: String!
  targetUuid: String!
  title: String!
  uuid: String!
}

enum NotificationTargetType {
  holiday
  job
}

type NotificationsPage {
  items: [Notification!]!
  pagination: PaginationResponse!
}

type Organisation {
  address: OrganisationAddress!
  canDelete: Boolean!
  displayAddress: String!
  email: String!
  name: String!
  telephone: String
  uuid: String!
  website: String
}

type OrganisationAddress {
  city: String
  latitude: Float!
  line1: String!
  line2: String
  longitude: Float!
  postcode: String!
}

type OrganisationPage {
  items: [Organisation!]!
  pagination: PaginationResponse!
}

input PaginationInput {
  page: Int!
  perPage: Int!
}

type PaginationResponse {
  currentPage: Int!
  from: Int!
  lastPage: Int!
  perPage: Int!
  to: Int!
  total: Int!
}

type Partner {
  customerCount: Int!
  email: String!
  jobCount: Int!
  name: String!
  schemeCount: Int!
  sla: PartnerSla!
  slotAllocationAmount: Int!
  slotAllocationType: SlotAllocationType!
  telephone: String
  ticketCount: Int!
  userCount: Int!
  uuid: String!
  website: String
}

type PartnerSkill {
  levelRequired: SkillLevel!
  name: String!
  trade: ContractorTrade!
  uuid: String!
}

input PartnerSkillInput {
  levelRequired: SkillLevel!
  skillUuid: String!
  trade: ContractorTrade!
}

type PartnerSkillPage {
  items: [PartnerSkill!]!
  pagination: PaginationResponse!
}

type PartnerSla {
  name: String!
  uuid: String!
}

type PartnersPage {
  items: [Partner!]!
  pagination: PaginationResponse!
}

union Profile = UserContractorProfile | UserCustomerProfile | UserUserProfile

type PutSignedFileUrl {
  key: String!
  url: String!
}

type Query {
  allTaskCount(myAssignment: Boolean!): Int!
  allTasks(myAssignment: Boolean!, pagination: PaginationInput): TasksPage!
  auditPendingJobCount(myAssignment: Boolean!): Int!
  auditPendingJobs(myAssignment: Boolean!, pagination: PaginationInput): JobsPage!
  blockedJobCount(myAssignment: Boolean!): Int!
  blockedJobs(myAssignment: Boolean!, pagination: PaginationInput): JobsPage!
  completeResetPassword(input: CompleteResetPasswordInput!): Boolean!
  estimateSlotAllocations: [SlotAllocationMap!]!
  findUniqueUser: User!
  indexAssignedJobs(filters: IndexAssignedJobsFilterInput, pagination: PaginationInput): AssignedJobsPage!
  indexAuditEntries(filters: IndexAuditEntriesInput!, pagination: PaginationInput): AuditEntryPage!
  indexAuditsForJob(uuid: String!): [JobAudit!]!
  indexComments(filter: IndexCommentsFilter!, pagination: PaginationInput): CommentPage!
  indexContractorHolidays(filters: IndexContractorHolidaysFilterInput, pagination: PaginationInput): ContractorHolidaysPage
  indexContractorsForJob(uuid: String!): [JobContractor!]!
  indexFiles(filters: IndexFilesFilter!, pagination: PaginationInput): FilePage!
  indexHandoversForJob(uuid: String!): [JobHandover!]!
  indexInstalledProductsForContractor(contractorProfileUuid: String!, pagination: PaginationInput): ContractorProductPage!
  indexInvoicesForCustomer(customerProfileUuid: String!, pagination: PaginationInput): InvoicesPage!
  indexJobs(filters: IndexJobsFilterInput, pagination: PaginationInput): JobsPage!
  indexNotifications(filters: IndexNotificationsFilterInput, pagination: PaginationInput): NotificationsPage!
  indexOrganisations(filters: IndexOrganisationsFilterInput, pagination: PaginationInput): OrganisationPage!
  indexPartners(filters: IndexPartnersFilterInput, pagination: PaginationInput): PartnersPage!
  indexPotentialContractorsForJob(filters: IndexPotentialContractorsForJobFilter!): [JobContractorPotential!]!
  indexProductsForJob(contractorUuid: String, uuid: String!): [JobProduct!]!
  indexRoofsForJob(uuid: String!): [Roof!]!
  indexSLAs(pagination: PaginationInput): SLAPage!
  indexSchemes(filters: IndexSchemesFilterInput, pagination: PaginationInput): SchemesPage!
  indexSettings(filter: IndexSettingsFilter!): SettingPage!
  indexSkills(filters: IndexSkillsFilterInput, pagination: PaginationInput): SkillsPage!
  indexSkillsForContractors(contractorProfileUuid: String!): [ContractorSkill!]!
  indexSkillsForJobs(filters: IndexSkillsForJobsFilterInput!): [JobSkill!]!
  indexSkillsForPartners(pagination: PaginationInput, partnerUuid: String!): PartnerSkillPage!
  indexSlots(input: IndexSlotsInput!): SlotPage!
  indexTasks(filters: IndexTasksFilterInput, pagination: PaginationInput): TasksPage!
  indexTickets(filters: IndexTicketsFilterInput, pagination: PaginationInput): TicketsPage!
  indexUsers(filters: IndexUsersFilterInput, pagination: PaginationInput): UsersPage!
  logout: Boolean!
  readAssignedJob(uuid: String!): AssignedJob!
  readContractorHoliday(uuid: String!): ContractorHoliday!
  readJob(uuid: String!): Job!
  readNotificationSettings: ContractorNotificationSettings!
  readPartner(uuid: String!): Partner!
  readScheme(uuid: String!): Scheme!
  readSkill(uuid: String!): Skill!
  readTask(uuid: String!): Task!
  readUser(input: ReadUserInput!): User!
  refresh(input: RefreshTokenInput): Token!
  resetPassword(input: ResetPasswordInput!): Boolean!
  searchDatesForRebook(input: SearchDatesForBooking!): [AvailableDate!]!
  signIn(input: SignInInput!): Token!
  slotCount(input: SlotCountInput!): String!
  todaysJobCount(myAssignment: Boolean!): Int!
  todaysJobs(myAssignment: Boolean!, pagination: PaginationInput): JobsPage!
  unassignedJobCount(myAssignment: Boolean!): Int!
  unassignedJobs(myAssignment: Boolean!, pagination: PaginationInput): JobsPage!
  unassignedTaskCount(myAssignment: Boolean!): Int!
  unassignedTasks(myAssignment: Boolean!, pagination: PaginationInput): TasksPage!
}

input ReadUserInput {
  userProfile: UserProfile
  uuid: String!
}

input RebookJobInput {
  dateToBeginSearch: Date!
  duration: Int!
  isElectricianRequired: Boolean!
  isRooferRequired: Boolean!
  jobUuid: String!
  notes: String
  numberOfRequiredDays: Int!
  partnerUuid: String!
  reasonForChangingJob: String!
  type: JobType!
}

input RefreshTokenInput {
  refreshToken: String!
}

input ResetPasswordInput {
  email: String!
}

enum ResponsibleContractorType {
  electrician
  roofer
}

type Roof {
  arrays: [RoofArray!]!
  scaffolding: RoofScaffolding
  tile: RoofTile!
  uuid: String!
}

type RoofArray {
  orientation: RoofArrayOrientation!
  panels: Int!
  uuid: String!
}

input RoofArrayInput {
  orientation: RoofArrayOrientation!
  panels: Int!
}

enum RoofArrayOrientation {
  landscape
  portrait
}

type RoofScaffolding {
  height: Int!
  isBridgeRequired: Boolean!
  isTowerRequired: Boolean!
  location: ScaffoldingLocation!
  locationArea: ScaffoldingLocationArea!
  locationOtherDescription: String
  notes: String
  uuid: String!
  width: Int!
}

enum RoofTile {
  concrete
  concreteFlat
  flat
  flatPlain
  metal
  slate
  unspecified
}

type SLA {
  name: String!
  partnerCount: Int!
  scaffoldingSetUpThreshold: Int!
  scaffoldingStrikeThreshold: Int!
  uuid: String!
}

type SLAPage {
  items: [SLA!]!
  pagination: PaginationResponse!
}

input SaveJobFeedback {
  jobFeedbackTokenUuid: String!
  notes: String!
  rating: Int!
  token: String!
}

input SaveJobHandoverInput {
  data: String!
  jobUuid: String!
  submit: Boolean
  type: JobHandoverType!
  uuid: String!
}

enum ScaffoldingLocation {
  garage
  mainProperty
  other
  outbuilding
}

enum ScaffoldingLocationArea {
  back
  front
  leftSide
  other
  rightSide
}

type Scheme {
  electricalAuditorUuid: String
  endDate: Date!
  formattedEndDate: String!
  formattedStartDate: String!
  name: String!
  operatorUuid: String
  partnerName: String!
  partnerUuid: String!
  postAuditorUuid: String
  preAuditorUuid: String
  roofingAuditorUuid: String
  schemeType: SchemeType!
  startDate: Date!
  status: SchemeStatus!
  uuid: String!
}

enum SchemeStatus {
  current
  ended
  upcoming
}

enum SchemeType {
  fullService
  installation
}

type SchemesPage {
  items: [Scheme!]!
  pagination: PaginationResponse!
}

input SearchDatesForBooking {
  dateToBeginSearch: Date!
  duration: Int!
  isElectricianRequired: Boolean!
  isRooferRequired: Boolean!
  jobUuid: String
  numberOfRequiredDays: Int!
  partnerUuid: String!
  type: JobType!
}

enum ServiceType {
  full
  installOnly
}

type Setting {
  description: String!
  key: String!
  kind: SettingKind!
  userUuid: String!
  uuid: String!
  value: String!
}

enum SettingKind {
  notificationBehaviour
  notificationSubject
}

type SettingPage {
  items: [Setting!]!
}

input SignInInput {
  email: String!
  isMobile: Boolean
  password: String!
}

input SignedUrlInput {
  key: String!
}

type Skill {
  contractorCount: Int!
  jobCount: Int!
  name: String!
  partnerCount: Int!
  uuid: String!
}

enum SkillLevel {
  fullyQualified
  inTraining
  partQualified
}

type SkillsPage {
  items: [Skill!]!
  pagination: PaginationResponse!
}

type Slot {
  isBatterySelected: Boolean!
  isElectricianAvailable: Boolean!
  isInstallationSelected: Boolean!
  isRemedialSelected: Boolean!
  isRooferAvailable: Boolean!
  job: SlotJob
  schemeName: String
  uuid: String!
}

type SlotAllocationMap {
  installationAllocated: Int!
  installationRequired: Int!
  partnerUuid: String!
}

enum SlotAllocationType {
  fixed
  percentage
}

input SlotCountInput {
  date: Date!
  partnerUuid: String!
}

type SlotJob {
  atRisk: Boolean!
  contractors: [SlotJobContractor!]!
  customer: SlotJobCustomer!
  difficulty: JobDifficulty!
  displayInstallationDate: String!
  status: JobStatus!
  type: JobType!
  uuid: String!
}

type SlotJobContractor {
  avatarSrc: String
  firstName: String!
  lastName: String!
}

type SlotJobCustomer {
  city: String
  firstName: String!
  lastName: String!
  line1: String!
  postcode: String
  schemeName: String
}

type SlotPage {
  items: [Slot!]!
  pagination: PaginationResponse
}

type Survey {
  uuid: String!
}

enum TargetType {
  job
  partner
  task
  user
}

type Task {
  assignee: TaskUser
  createdAt: Date!
  creator: TaskUser
  customer: TaskUser
  description: String
  dueDate: Date
  files: [TaskFile!]!
  job: TaskJob
  status: TaskStatus!
  title: String!
  uuid: String!
}

type TaskFile {
  name: String!
  src: String!
  type: String!
  uuid: String!
}

type TaskJob {
  displayName: String!
  type: JobType!
  uuid: String!
}

enum TaskStatus {
  done
  inProgress
  todo
  unassigned
}

input TaskUpsertInput {
  customerProfileUuid: String
  description: String
  dueDate: Date
  fileUuids: [String!]
  jobUuid: String
  operatorUuid: String
  status: TaskStatus
  title: String
  uuid: String!
}

type TaskUser {
  avatarSrc: String
  firstName: String!
  lastName: String!
  partnerUuid: String
  uuid: String!
}

type TasksPage {
  items: [Task!]!
  pagination: PaginationResponse!
}

type Ticket {
  createdAt: Date!
  customer: TicketCustomer!
  description: String
  lastInteraction: Int
  status: TicketStatus!
  title: String!
  uuid: String!
}

type TicketCustomer {
  firstName: String!
  lastName: String!
}

enum TicketStatus {
  assigned
  resolved
  unableToResolve
  unassigned
}

type TicketsPage {
  items: [Ticket!]!
  pagination: PaginationResponse!
}

type Token {
  accessToken: String!
  refreshToken: String
}

input UpdateAddressInput {
  city: String
  county: String
  latitude: Float
  line1: String
  line2: String
  longitude: Float
  postcode: String
  uuid: String!
}

input UpdateJobAuditInput {
  difficulty: JobDifficulty
  extras: [JobProductInput!]
  hasPassed: Boolean
  notes: String
  numberOfSolarPanels: Int
  skills: [AuditJobSkill!]
  uuid: String!
}

input UpdateJobInput {
  auditorUuids: AuditorInput
  operatorUuid: String
  status: JobStatus
  uuid: String!
}

input UpdateNotificationSettingsInput {
  inAppNotifications: Boolean
  newJobAlerts: Boolean
  pushNotifications: Boolean
  updatedHolidayAlerts: Boolean
  updatedJobAlerts: Boolean
}

input UpdatePartnerInput {
  email: String
  name: String
  skills: [PartnerSkillInput!]
  slaUuid: String
  slotAllocationAmount: Int
  slotAllocationType: SlotAllocationType
  telephone: String
  uuid: String!
  website: String
}

input UpdateRoofInput {
  arrays: [RoofArrayInput!]
  scaffolding: UpdateRoofScaffoldingInput
  tile: RoofTile
  uuid: String!
}

input UpdateRoofScaffoldingInput {
  height: Int
  isBridgeRequired: Boolean
  isTowerRequired: Boolean
  location: ScaffoldingLocation
  locationArea: ScaffoldingLocationArea
  locationOtherDescription: String
  notes: String
  width: Int
}

input UpdateSchemeInput {
  electricalAuditorUuid: String
  operatorUuid: String
  postAuditorUuid: String
  preAuditorUuid: String
  roofingAuditorUuid: String
  uuid: String!
}

input UpdateSelfInput {
  avatarUuid: String
  email: String
  firstName: String
  lastName: String
  phoneNumber: String
}

input UpdateSettingInput {
  uuid: String!
  value: String!
}

input UpdateSlotInput {
  isBatterySelected: Boolean
  isInstallationSelected: Boolean
  isRemedialSelected: Boolean
  partnerUuid: String
  schemeUuid: String
  uuid: String!
}

input UpdateSurveyInput {
  fileUuids: [String!]
  uuid: String!
}

input UpdateUserContractorProfileInput {
  availability: String
  email: String
  firstName: String
  isElectrician: Boolean
  isRoofer: Boolean
  lastName: String
  organisationUuid: String
  phoneNumber: String
  radius: Int
  skills: [UserContractorSkillInput!]
  status: UserProfileStatus
  uuid: String!
}

input UpdateUserCustomerProfileInput {
  email: String
  firstName: String
  lastName: String
  partnerUuid: String
  phone: String
  schemeUuid: String
  userUuid: String!
  uuid: String!
}

input UpdateUserUserProfileInput {
  avatarUuid: String
  email: String
  firstName: String
  lastName: String
  organisationUuid: String
  phone: String
  role: UserRole
  schemeUuid: String
  status: UserProfileStatus
  uuid: String!
}

input UploadAvatarUrlInput {
  contentDisposition: String!
}

input UploadJobFormFileInput {
  contentDisposition: String!
  imageId: String!
  jobUuid: String!
  type: JobFormFileType!
}

input UploadUrlInput {
  contentDisposition: String!
  customerProfileUuid: String!
  targetType: FileTargetType!
  targetUuid: String!
}

input UpsertAuditEntryInput {
  entryType: AuditEntryType!
  notes: String
  targetType: TargetType!
  targetUuid: String!
  title: String!
  uuid: String
}

input UpsertCommentInput {
  fileUuids: [String!]!
  jobUuid: String
  targetType: CommentTargetType!
  targetUuid: String!
  text: String!
  uuid: String!
}

input UpsertOrganisationAddressInput {
  city: String
  county: String
  latitude: Float
  line1: String!
  line2: String
  longitude: Float
  postcode: String!
}

input UpsertOrganisationInput {
  address: UpsertOrganisationAddressInput!
  email: String!
  name: String!
  telephone: String!
  uuid: String
  website: String!
}

input UpsertSLAInput {
  name: String!
  scaffoldingDownDays: Int!
  scaffoldingUpDays: Int!
  uuid: String
}

input UpsertSchemeInput {
  electricalAuditorUuid: String!
  endDate: Date!
  name: String!
  operatorUuid: String!
  partnerUuid: String!
  postAuditorUuid: String!
  preAuditorUuid: String!
  roofingAuditorUuid: String!
  schemeType: SchemeType!
  startDate: Date!
  uuid: String
}

input UpsertSkillInput {
  name: String!
  uuid: String
}

type User {
  address: Address
  avatarSrc: String
  createdAt: String!
  email: String!
  firstName: String!
  lastLogin: String
  lastName: String!
  phoneNumber: String
  profile: Profile!
  schemeName: String
  unreadNotificationCount: Int!
  uuid: String!
}

type UserContractorProfile {
  availability: String!
  completionRate: Float!
  holidays: [ContractorHoliday!]
  isElectrician: Boolean!
  isRoofer: Boolean!
  isScaffolder: Boolean!
  jobCount: Int!
  organisation: UserOrganisation!
  servicingRadius: Int!
  startDate: String!
  status: UserProfileStatus!
  testInstrumentSerialNumber: String
  userUuid: String!
  uuid: String!
}

input UserContractorSkillInput {
  level: SkillLevel!
  skillUuid: String!
  trade: ContractorTrade!
}

type UserCustomerPartner {
  name: String!
  uuid: String!
}

type UserCustomerProfile {
  jobStatus: JobStatus
  partner: UserCustomerPartner!
  schemeUuid: String!
  source: UserProfileSource!
  userUuid: String!
  uuid: String!
}

type UserOrganisation {
  name: String!
  uuid: String!
}

enum UserProfile {
  contractor
  customer
  user
}

enum UserProfileSource {
  api
  csv
  direct
}

enum UserProfileStatus {
  active
  inactive
}

enum UserRole {
  administrator
  operator
  superAdministrator
}

type UserUserProfile {
  organisation: UserOrganisation!
  role: UserRole!
  status: UserProfileStatus!
  userUuid: String!
  uuid: String!
}

type UsersPage {
  items: [User!]!
  pagination: PaginationResponse!
}`;
