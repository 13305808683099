import { useEffect, useMemo, useState } from 'react';
import { useForm } from '@tanstack/react-form';

import { ModalWrapperPropsExtends, Modal, ModalWrapper } from '../Base';

import SchemeDropdown from '../../SchemeDropdown';
import { InstallationIcon } from '../../../icons/Installation';

import { styleUtility } from '../../../../utility/styleUtility';
import {
  Battery50Icon,
  BoltIcon,
  UserIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import RadioCardSelector from '../../../atoms/RadioCardSelector';
import { RooferIcon } from '../../../icons/Roofer';
import Checkbox from '../../../atoms/Checkbox';
import { AvailableDate, gql, JobType, UserProfile } from '@monorepo/graphql';
import UserDropdown from '../../UserDropown';
import Alert from '../../../atoms/Alerts';
import { useMutation, useQuery } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { usePartners } from '../../../../utility/partnerContext';
import { useNavigate } from '@tanstack/react-router';
import NumberInput from '../../../atoms/NumberInput';
import DateSelector from '../../../atoms/DateSelector';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { format } from 'date-fns';
import { slotsUtility } from '../../../../utility/slots';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';

type Props = {
  date: Date;
  isInstallationAvailable: boolean;
  isRemedialAvailable: boolean;
  isBatteryAvailable: boolean;
  isRooferAvailable?: boolean;
  isElectricianAvailable?: boolean;
} & ModalWrapperPropsExtends;

const CREATE_JOB = gql(`
  mutation CreateJob($input: CreateJobInput!) {
    createJob (input: $input) {
      uuid
    }
  }
`);

const SearchAvailableDatesQuery = gql(`
  query SearchForAvailableDatesAddJob ($input: SearchDatesForBooking!) {
    searchDatesForRebook (input: $input) {
      slotDate
      displayDate
    }
  }  
`);

export const AddJobModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="w-full max-w-150"
    open={open}
    onClose={onClose}
  >
    <AddJobModalChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const AddJobModalChild = ({
  onClose,
  date,
  isInstallationAvailable,
  isRemedialAvailable,
  isBatteryAvailable,
  isElectricianAvailable,
  isRooferAvailable,
}: Omit<Props, 'open'>) => {
  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);

  const [createJob, { loading, error }] = useMutation(CREATE_JOB);
  const { selectedPartner } = usePartners();

  const navigate = useNavigate();

  const [targetDate, setTargetDate] = useState(date);

  const form = useForm<{
    userUuid: string;
    jobType: JobType;
    jobDuration: number;
    numberOfRequiredDays: number;
    contractors: {
      needsRoofer: boolean;
      needsElectrician: boolean;
      needsLabourer: boolean;
    };
    searchDate: Date | undefined;
  }>({
    onSubmit: ({ value }) => {
      if (!value.searchDate) return;
      createJob({
        variables: {
          input: {
            customerProfileUserUuid: value.userUuid,
            numberOfRequiredDays: value.numberOfRequiredDays,
            duration: value.jobDuration,
            searchDate: value.searchDate,
            isElectricianRequired: value.contractors.needsElectrician,
            isLabourerRequired: value.contractors.needsLabourer,
            isRooferRequired: value.contractors.needsRoofer,
            partnerUuid: selectedPartner!.uuid,
            isManualAllocation, 
            type: value.jobType,
          },
        },
        refetchQueries: [slotsUtility.queries.GET_SLOTS],
      }).then((rsp) => {
        if (rsp.data?.createJob) {
          navigate({
            to: '/job/$uuid',
            params: {
              uuid: rsp.data.createJob.uuid,
            },
          });
          notify.success('Successfully created job.');
        }
      });
    },
    defaultValues: {
      numberOfRequiredDays: 1,
      jobDuration: 8,
      searchDate: undefined,
      jobType: isInstallationAvailable
        ? JobType.installation
        : isRemedialAvailable
        ? JobType.remedial
        : JobType.battery,
      userUuid: '',
      contractors: {
        needsRoofer: isInstallationAvailable
          ? true
          : isRemedialAvailable
          ? true
          : false,
        needsElectrician: true,
        needsLabourer: false,
      },
    },
  });

  const {
    data,
    refetch,
    error: searchError,
    loading: searchLoading,
  } = useQuery(SearchAvailableDatesQuery, {
    variables: {
      input: {
        numberOfRequiredDays: form.getFieldValue('numberOfRequiredDays'),
        dateToBeginSearch: targetDate,
        type: form.getFieldValue('jobType'),
        isElectricianRequired:
          form.getFieldValue('contractors').needsElectrician,
        isRooferRequired: form.getFieldValue('contractors').needsRoofer,
        duration: form.getFieldValue('jobDuration'),
        partnerUuid: selectedPartner!.uuid,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        setAvailableDates(data.searchDatesForRebook);
      }
      form.setFieldValue('searchDate', undefined);
    },
  });

  const [isManualAllocation, setIsManualAllocation] = useState(false);

  const jobTypeOptions = useMemo(
    () => [
      {
        value: JobType.installation,
        Icon: (
          <InstallationIcon
            multiplier={1.1}
            colour={`${
              isInstallationAvailable
                ? styleUtility.colours.primary['DEFAULT']
                : styleUtility.colours['text-disabled']
            }`}
          />
        ),
        title: 'Installation',
        explainer: 'First time visit',
        disabled: !isInstallationAvailable,
      },
      {
        value: JobType.remedial,
        Icon: (
          <WrenchScrewdriverIcon
            className={`size-8 ${
              isRemedialAvailable ? 'text-primary' : 'text-text-disabled/50'
            }`}
          />
        ),
        title: 'Remedial',
        explainer: 'Upgrade or repair',
        disabled: !isRemedialAvailable,
      },
      {
        value: JobType.battery,
        Icon: (
          <Battery50Icon
            className={`size-8 ${
              isBatteryAvailable ? 'text-primary' : 'text-text-disabled/50'
            }`}
          />
        ),
        title: 'Battery',
        explainer: 'Battery only',
        disabled: !isBatteryAvailable,
      },
    ],
    [isBatteryAvailable, isRemedialAvailable, isInstallationAvailable]
  );

  const jobTypeField = form.useField({
    name: 'jobType',
  });

  const jobDurationField = form.useField({
    name: 'jobDuration',
  });

  return (
    <Modal
      title="Add a job manually"
      onClose={onClose}
      confirmCallback={() => form.handleSubmit()}
      confirmText="Add job"
      loading={loading}
    >
      <div className="p-5">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {isManualAllocation && (
            <div className="mb-5">
              <Alert
                alertType="warning"
                text="This job is a manual allocation, you will be required to assign a contractor."
              />
            </div>
          )}
          <form.Field
            name="userUuid"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a customer' : undefined,
            }}
            children={({ state, handleChange }) => (
              <UserDropdown
                label="Customer"
                userUuid={state.value}
                setUserUuid={handleChange}
                error={state.meta.errors.join(', ')}
                userProfile={UserProfile.customer}
                partnerUuid={selectedPartner!.uuid}
              />
            )}
          />

          <div className="mb-5">
            <form.Field
              name="jobType"
              validators={{
                onSubmit: ({ value }) =>
                  !value ? 'Please select a job type' : undefined,
              }}
              children={({ state, handleChange }) => (
                <RadioCardSelector
                  label="Job Type"
                  setSelectedOption={(opt) => handleChange(opt.value)}
                  selectedOption={jobTypeOptions.find(
                    ({ value }) => value === state.value
                  )}
                  options={jobTypeOptions}
                  error={state.meta.errors.join(', ')}
                />
              )}
            />
          </div>

          <div className="mb-5 w-full">
            <span className="mb-2 text-input-label block font-semibold">
              Contractors required
            </span>

            <div className="space-y-2">
              <form.Field
                name="contractors"
                validators={{
                  onSubmit: ({ value }) =>
                    !value.needsElectrician && !value.needsRoofer
                      ? 'Please select at least one contractor'
                      : undefined,
                }}
                children={({ state, handleChange }) => (
                  <>
                    <Checkbox
                      Icon={
                        <RooferIcon
                          colour={`${
                            isRooferAvailable
                              ? styleUtility.colours.primary['DEFAULT']
                              : `${styleUtility.colours['text-disabled']}`
                          }`}
                          className="mr-2"
                        />
                      }
                      hasError={state.meta.errors.length > 0}
                      title="Roofer"
                      disabled={!isRooferAvailable}
                      checked={state.value.needsRoofer}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          needsRoofer: checked,
                        }))
                      }
                    />
                    <Checkbox
                      Icon={
                        <BoltIcon
                          className={`${
                            isElectricianAvailable
                              ? 'text-primary'
                              : 'text-text-disabled/50'
                          } size-6 mr-2`}
                        />
                      }
                      title="Electrician"
                      disabled={!isElectricianAvailable}
                      checked={state.value.needsElectrician}
                      hasError={state.meta.errors.length > 0}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          needsElectrician: checked,
                        }))
                      }
                    />
                    <Checkbox
                      Icon={
                        <UserIcon
                          className={`${
                            isElectricianAvailable
                              ? 'text-primary'
                              : 'text-text-disabled/50'
                          } size-6 mr-2`}
                        />
                      }
                      title="Labourer"
                      checked={state.value.needsLabourer}
                      hasError={state.meta.errors.length > 0}
                      setChecked={(checked) =>
                        handleChange((f) => ({
                          ...f,
                          needsLabourer: checked,
                        }))
                      }
                    />
                    {!!state.meta.errors.length && (
                      <div className="mt-2">
                        <Alert
                          alertType="error"
                          text={state.meta.errors.join(', ')}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          {jobTypeField.state.value !== JobType.installation ? (
            <>
              <div className="mb-5">
                <span className="text-input-label font-semibold block mb-2">
                  Override
                </span>
                <SimpleCheckbox
                  label="Override automatic contractor allocation"
                  checked={isManualAllocation}
                  setChecked={(checked) => {
                    if (jobDurationField.state.value < 8) {
                      notify.error(
                        'Unable to use the system to allocate when the job is less than 8 hours.'
                      );
                    } else {
                      setIsManualAllocation(checked);
                    }
                  }}
                />
              </div>
              <form.Field
                name="jobDuration"
                children={({ state, handleChange }) => (
                  <div className="mb-5">
                    <NumberInput
                      max={8}
                      min={2}
                      increment={2}
                      count={state.value}
                      setCount={(number) => {
                        setIsManualAllocation(number < 8);
                        handleChange(number);
                      }}
                      title="Number of hours required"
                    />
                  </div>
                )}
              />
            </>
          ) : (
            <form.Field
              name="numberOfRequiredDays"
              children={({ state, handleChange }) => (
                <div className="mb-5">
                  <NumberInput
                    max={5}
                    count={state.value}
                    setCount={(ch) => {
                      handleChange(ch);
                      refetch({
                        input: {
                          numberOfRequiredDays: ch,
                          dateToBeginSearch: targetDate,
                          type: form.getFieldValue('jobType'),
                          isElectricianRequired:
                            form.getFieldValue('contractors').needsElectrician,
                          isRooferRequired:
                            form.getFieldValue('contractors').needsRoofer,
                          duration: form.getFieldValue('jobDuration'),
                          partnerUuid: selectedPartner!.uuid,
                        },
                      });
                    }}
                    title="Number of days required"
                  />
                </div>
              )}
            />
          )}
          {!isManualAllocation && (
            <>
              <div className="mb-5 w-full">
                <span className="mb-2 text-input-label block font-semibold">
                  Target Date
                </span>
                <DateSelector
                  selectedDate={targetDate}
                  setSelectedDate={(date) => setTargetDate(date)}
                  showLabel
                />
                {searchError ? (
                  <div className="mt-2">
                    <Alert alertType="error" text={searchError.message} />
                  </div>
                ) : (
                  data?.searchDatesForRebook.length === 0 && (
                    <div className="mt-2">
                      <Alert
                        alertType="warning"
                        text="No dates found with this target date, try choosing a different date."
                      />
                    </div>
                  )
                )}
              </div>

              <form.Field
                name="searchDate"
                validators={{
                  onSubmit: ({ value }) =>
                    !value ? 'Please select a date' : undefined,
                }}
                children={({ state, handleChange }) => (
                  <DropdownWithBorder
                    error={state.meta.errors.join(', ')}
                    isFetchingSelected={searchLoading}
                    buttonClassname="w-full justify-between mb-5"
                    label="Selected date"
                    buttonText={
                      state.value
                        ? format(state.value, 'd MMMM yyyy')
                        : '-- Select --'
                    }
                    disabled={availableDates.length === 0}
                    options={availableDates.map(
                      ({ slotDate, displayDate }) => ({
                        name: displayDate,
                        value: slotDate,
                      })
                    )}
                    respectButtonWidth
                    onOptionSelect={(option) =>
                      handleChange(new Date(option.value))
                    }
                  />
                )}
              />
            </>
          )}
          {error && <Alert alertType="error" text={error.message} />}
        </form>
      </div>
    </Modal>
  );
};
